import React, {useState , useEffect} from 'react'
import { useLocation, Link } from 'react-router-dom'
import {useRecoilValue} from "recoil";
import {Image, Menu} from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { menus } from '../../routers/menus';
import {collapsedStore} from "../../store";
import {Logo} from '../../Config';
const { SubMenu } = Menu;


const urlToList = (url) => {
    const urlList = url.split('/').filter(i => i);
    return urlList.map((urlItem, index) => `/${urlList.slice(0, index + 1).join('/')}`)
}

/*
  左侧导航组件
*/
const SideMenu= (props) => {
    let location = useLocation();
    const collapsed = useRecoilValue(collapsedStore);
    const menuOpenedArray =  urlToList(location.pathname).slice(0,-1);
    const [menuPath, setMenuPath] =  useState(location.pathname);


  // handleFilter = permission => {
  //   const roleType = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).role.type;
  //   // 过滤没有权限的页面
  //   if (!permission || permission === roleType) {
  //     return true;
  //   }
  //   return false;
  // };

  // 递归渲染菜单
  const renderMenu = data => {
    return data.map(item => {
      if (item.children) {
        return (
          // handleFilter(item.permission) && (
          <SubMenu
            key={item.path}
            title={
              <span>
                {item.icon}
                <span>{item.title}</span>
              </span>
            }
          >
            {renderMenu(item.children)}
          </SubMenu>
          // )
        );
      }
      return (
        // this.handleFilter(item.permission) && (
        <Menu.Item key={item.path}>
          <Link to={item.path}>
            {item.icon}
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
        // )
      );
    });
  };
    return (
      <div>
          <Scrollbars  style={{ width: '100%', height: '100vh'}}>
        <Link to='/'>
            <Image
                className='m-4'
                preview={false}
                width={collapsed ? 50 :130}
                src={collapsed ? Logo.icon  : Logo.black}
            />
        </Link>

        <Menu
          // 初始展开的 SubMenu 菜单项 key 数组
          defaultOpenKeys={menuOpenedArray}
          // 初始选中的菜单项 key 数组
          defaultSelectedKeys={menuPath}
          // 当前选中的菜单项 key 数组
          selectedKeys={[menuPath]}
          mode="inline"
          // 主题颜色
          theme="dark"
          onClick={(item)=>{
             setMenuPath(item.keyPath[0])
          }}

        >
          {renderMenu(menus)}
        </Menu>
          </Scrollbars>
      </div>

    )
}

export default SideMenu
