/**
 * Created by devin on 2021/4/21.
 */

import React, {useEffect, useState, useCallback} from 'react'

import {useRecoilValueLoadable} from 'recoil';

import {
    Badge,
    Button,
    Col,
    DatePicker,
    Divider,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
    Popover, Breadcrumb, Avatar
} from 'antd';
import {
    MobileOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    StarOutlined,
    TagOutlined,
    UserDeleteOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {Icon} from '../../Config';
import {userTags, userGrade} from "../../store";
import {useHttps} from "../../hooks";

const {Text } = Typography;

/*
  用户管理
*/
const User = (props) => {
    const {put, get} = useHttps();
    const tags = useRecoilValueLoadable(userTags);
    const grade = useRecoilValueLoadable(userGrade);
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    const [selectGrade, setSelectGrade] = useState(0)
    const [selectTag, setSelectTag] = useState(0)
    const [selectDate, setSelectDate] = useState(null)
    const [selectType, setSelectType] = useState(0)
    const [isGradeVisible, setIsGradeVisible] = useState(false);
    const [isTagsVisible, setIsTagsVisible] = useState(false);
    const [updateGrade, setUpdateGrade] = useState(0)
    const [updateTags, setUpdateTags] = useState([])

    const navigate = useNavigate();
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/user' , params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);


    /**
     * 更新列表操作
     * @param params
     * @param ids
     */
    const onUpdate = (params, ids) => {
        setLoading(true);
        params.id = ids ;
        put('admin/user/batch', params)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    message.error(e.message);
                    setLoading(false);
                }
            });
    };


    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }
        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        if (selectType) {
            params.status = selectType;
        }
        if (search.length !== 0) {
            params.search = search;
        }
        if (selectTag) {
            params.tag_id = selectTag;
        }
        if (selectGrade) {
            params.grade_id = selectGrade;
        }
        window.location.hash = `#${pagination.current}`;
        setLoading(true);
        getData(params);
    }


    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    };

    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setSelectGrade(0)
        setSelectTag(0)
        setSelectType(0)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }

    /**
     * 更新用户字段
     */

    const onOk = (type, ids) => {
        if(type === 1 && updateGrade){
            setIsGradeVisible(false)
            onUpdate({grade_id : updateGrade }, ids)
        }
        if(type === 2 && updateTags.length !== 0){
            setIsTagsVisible(false)
            onUpdate({tags : updateTags}, ids)
        }
    }


    /**
     * 操作提示信息
     */
    const showConfirm = (value) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要{value === 2 ? '加入黑名单' : '取消黑名单'} 吗？</div>,
            onOk() {
                onUpdate({status : value}, [...selectedRowKeys])
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 自定义表格
     * @type {Object}
     */

    const columns = [
        {
            title: '用户名',
            dataIndex: 'nickname',
            render: (nickname, item) => {
                return (
                    <Link to={ '/user/account/' + item.id}>
                        <Space>
                            <div className='user-list-avatar'>
                                {item.auth && item.auth.status === 2 && <img
                                    className='icon'
                                    src={Icon.vip}
                                    alt={nickname}/>
                                }
                                <Avatar  size="large" src={item.avatar} />
                            </div>
                            <Text>{nickname}</Text>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: '用户等级',
            dataIndex: 'grade',
            render: (grade) => {
                return (
                 <Avatar shape="square" size="small" src={grade.thumb} />
                )
            }
        },
        {
            title: '用户标签',
            dataIndex: 'tags',
            render: (tags) => {
                return (
                    <div>
                        {tags.slice(-4).map((tag) => {
                            return (
                                <Tag color="cyan" className='mb-1' key={tag.id}>{tag.name}</Tag>
                            );
                        })}
                        {tags.length === 0 &&   <Tag>无标签</Tag>}
                    </div>

                )
            }
        },

        {
            title: '状态',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (tags) => {
                return (
                    <Badge
                        count={tags === 2 ? '拉黑' : '正常'}
                        style={{backgroundColor: tags === 2 ? '#6c757d' : '#28a745'}}
                    />
                )
            }
        },
        {
            title: '注册日期',
            dataIndex: 'created_at',
        },
        {
            title: '积分',
            dataIndex: 'points',
            sorter: (a, b) => a.points - b.points,
            render: (points) => {
                return (
                    <div> {points} </div>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                    <Space>
                        <Button onClick={() => navigate('/user/account/' + item.id)} className='text-muted' type="dashed" size='small' icon={<EditOutlined/>}/>
                        <Popover content={<div>
                            <p>姓名：{item.name ? item.name : item.nickname}</p>
                            <p>手机号: {item.mobile}</p>
                        </div>} title="联系方式" trigger="click">
                        <Button  className='text-muted' type="dashed" size='small' icon={<MobileOutlined />}/>
                        </Popover>
                        <Popconfirm title={item.status === 1 ? "确定拉黑?" : '确定取消？'} onConfirm={() => {
                            const status = item.status === 1 ? 2 :1 ;
                            onUpdate({status: status}, [item.id])
                        }}>
                           <Button className='text-muted' type="dashed" size='small' icon={<UserDeleteOutlined/>}/>
                        </Popconfirm>

                    </Space>
                )
            }
        },
    ];
    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>全部会员</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white px-4 pt-4'>
                <Row>
                    <Col span={4}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            导出
                        </Button></Col>

                    <Col span={20}>
                        <div className='d-flex justify-content-end'>

                            <Space>
                                <DatePicker.RangePicker value={selectDate}  onChange={setSelectDate}/>
                                <Select defaultValue={0} value={selectType} onChange={setSelectType}>
                                    <Select.Option value={0}>全部用户</Select.Option>
                                    <Select.Option value={1}>正常用户</Select.Option>
                                    <Select.Option value={2}>黑名单</Select.Option>
                                </Select>
                                <Select defaultValue={0} value={selectGrade} onChange={setSelectGrade}
                                        disabled={grade.state !== 'hasValue'}>
                                    <Select.Option value={0}>全部等级</Select.Option>
                                    {grade.state === 'hasValue' && grade.contents.map((tag) => {
                                        return (
                                            <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                        );
                                    })}
                                </Select>

                                <Select defaultValue={0} value={selectTag} onChange={setSelectTag}
                                        disabled={tags.state !== 'hasValue'}>
                                    <Select.Option value={0}>全部标签</Select.Option>
                                    {tags.state === 'hasValue' && tags.contents.map((tag) => {
                                        return (
                                            <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                        );
                                    })}
                                </Select>

                                <Input.Search
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={(e) => {
                                        onPaginationChange({current: 1, pageSize: perPage})
                                    }}
                                    placeholder="输入手机号码搜索"
                                    allowClear
                                    enterButton="筛选"
                                />

                                <Button onClick={onReset}>
                                    重置
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
                <Divider/>
                <div className='mb-3'>
                    <Space>
                    <Button className='me-2' icon={<StarOutlined/>}
                            disabled={selectedRowKeys.length === 0}
                            onClick ={() => setIsGradeVisible(true)}
                            size='small'>修改等级</Button>
                    <Button className='me-2' icon={<TagOutlined/>}
                            disabled={selectedRowKeys.length === 0}
                            onClick ={() => setIsTagsVisible(true)}
                            size='small'>添加标签</Button>

                    <Button className='me-2' onClick={() => showConfirm(2)} icon={<DeleteOutlined/>} disabled={selectedRowKeys.length === 0}
                            size='small'>拉入黑名单</Button>
                    <Button className='me-2'  onClick={() => showConfirm(1)}  icon={<DeleteOutlined/>} disabled={selectedRowKeys.length === 0}
                            size='small'>取消黑名单</Button>
                    <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 位用户` : ''}
                        </span>
                    </Space>

                </div>
                <Table
                    size="middle"
                    loading={loading}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange,
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        size:'default',
                        total: total,
                        current: current,
                        showTotal: total => `总计 ${total} 位用户`,
                        pageSize: perPage,
                    }}
                    onChange={onPaginationChange}
                    rowKey={(record) => {
                        return record.id
                    }}
                />
            </div>

            <Modal title="修改等级" visible={isGradeVisible} onCancel={()=>setIsGradeVisible(false)} onOk={() => onOk(1, [...selectedRowKeys])} >
                <div>
                    <Select placeholder='选择等级' className='w-100'
                            disabled={grade.state.length === 0}
                            onChange={setUpdateGrade}
                    >
                        {grade.state === 'hasValue' && grade.contents.map((tag) => {
                            return (
                                <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                            );
                        })}
                    </Select>
                </div>
            </Modal>
            <Modal title="添加标签" visible={isTagsVisible} onCancel={()=>setIsTagsVisible(false)} onOk={() => onOk(2,[...selectedRowKeys])}>
                <div>
                    <Select
                        placeholder='选择标签'
                        className='w-100'
                        mode="tags"
                        onChange={setUpdateTags}
                    >
                        {tags.state === 'hasValue' && tags.contents.map((tag) => {
                        return (
                            <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                        );
                    })}
                    </Select>
                </div>
            </Modal>
        </div>
    )
}

export default User
