import React, {Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {RecoilRoot} from 'recoil';
import Admin from './pages/Layout'
import Login from './pages/Login'
import Article from './pages/WebView/Article'
import News from './pages/WebView/News'
import Page from './pages/WebView/Page'
import Print from './pages/Print/Print'
import AuthRouter from './Auth';
import './App.css';
import {ConfigProvider} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import 'moment/locale/zh-cn';

const App = () => {
    return (
        <ConfigProvider locale={zhCN}>
            <RecoilRoot>
                <Router>
                    <Routes>
                        <Route path='/login' element={<Login />}/>
                        <Route  element={<Page />}  path={'/webview/pages/:id'}/>
                        <Route  element={<Print />}  path={'/print'}/>
                        <Route  element={<News />}  path={'/webview/news/:id'}/>
                        <Route  element={<Article />}  path={'/webview/article/:id'}/>
                        <Route path="/*" element={
                            <Suspense fallback={<div>Loading</div>}>
                                <AuthRouter ><Admin/></AuthRouter>
                            </Suspense>
                        }/>
                    </Routes>
                </Router>
            </RecoilRoot>
        </ConfigProvider>
    );
}

export default App;
