

export function withdrawPrice(price , rate) {

    const total = parseFloat(price); // 总金额
    if( total === 0) {
        return price;
    }
    const brokerage = parseFloat(rate); // 扣除手续费百分之5
    const newTotal = (brokerage / 100) * total;
    const totalPrice = total - newTotal

    return  Number(totalPrice.toString().match(/^\d+(?:\.\d{0,2})?/));

}


export function subText(str, length) {

    if (!str || str.length === 0) {
        return '';
    }
    if (str.length > length) {
        return str.substr(0, length) + "...";
    } else {
        return str;
    }
}

export function priceCheck(price , empty = true) {
    // const priceReg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
    const priceReg = /^\d+\.?\d{0,2}$/;

    if (!priceReg.test(price)) {
        return false
    }

    if(empty){
        return parseInt(price) !== 0
    }

    return true;
}


export function numFormat(num) {
    if (num >= 10000) {
        num = Math.round(num / 1000) / 10 + 'w'
    } else if (num >= 1000) {
        num = Math.round(num / 100) / 10 + 'k'
    }
    return num
}

/**
 *  验证中文姓名
 * @param { string } value
 */
export const isChineseName = value => /^(\u4e00-\u9fa5·]{2,16})$/g.test(value);


/**
 *  验证手机号中国(严谨), 根据工信部2019年最新公布的手机号段
 * @param { string } value
 */
export const isMPStrict = value => /^(?:(?:\+|00)86)?1(?:(3[\d])|(4[5-7|9])|(5[0-3|5-9])|(6[5-7])|(7[0-8])|(8[\d])|(9[1|8|9]))\d{8}$/g.test(value);

/**
 *  验证数字和字母组成
 * @param { string } value
 */
export const isNumAndStr = value => /^[A-Za-z0-9]+$/g.test(value);

/**
 * 验证用户名，4到16位（字母，数字）
 * @param { string } value
 */
export const isCorrectFormatUsername = value => /^[A-Za-z0-9]{4,16}$/g.test(value);

/**
 * 验证密码强度，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
 * @param { string } value
 */
export const isCorrectFormatPassword = value => /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/g.test(value);
