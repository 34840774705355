import React, {useRef, useState, useImperativeHandle} from 'react'
import {Button, Form, Image, Input, Space} from 'antd'
import {CloseCircleFilled, CloseOutlined, DeleteOutlined, FileImageOutlined, PlusOutlined} from "@ant-design/icons";
import {Attachment} from "../index";
import SkuTable from './SkuTable'

const CreateSku = props => {
    const {from, disabled} = props;
    const [specList, setSpecList] = useState([])
    const [skuList, setSkuList] = useState([])
    const [thumb, setThumb] = useState({});
    const selectRef = useRef();

    /**
     * 添加规格
     */
    const addSpec = () => {
        const id = getLastSpecId();
        setSpecList(specList.concat([{id: id, spec_id:id, value: ''}]))

    }

    /**
     * 删除规格
     * @param id
     */
    const removeSpec = (id) => {
        //清空表单数据
        const name = `specNames_${id}`
        const reset = {}
        reset[name] = ''
        from.setFieldsValue(reset)
        //查找规格项清空数据表单
        const index = getSpecIndex(id)
        const CurrentSubSpecList = specList[index].attr_list || []
        CurrentSubSpecList.forEach(c => {
            const reset = {}
            const subName= `subSpecNames_${c.attr_id}_${id}`
            reset[subName] = ''
            from.setFieldsValue(reset)
        })

        //删除规格项
        setSpecList(specList.filter(item => item.id !== id))
    }

    /**
     * 更新规格
     * @param value
     * @param id
     */
    const updateSpec = (value, id) => {
        const index = getSpecIndex(id)
        specList[index].value = value
        setSpecList([...specList])
    }

    /**
     * 生成规格项ID
     * @param index
     * @returns {string}
     */
    const getLastSubSpecId = (index) => {
        let id = 1;

        const CurrntSubSpecList = specList[index].attr_list || []
        //如果存在获取规格最后一个ID
        if (CurrntSubSpecList.length !== 0) {
            id = Math.max(...CurrntSubSpecList.map(x => x.attr_id)) + 1
        }
        //转换规格ID为三位数
        return (Array(1).join(0) + id).slice(-2);
    }

    /**
     * 生成规格ID
     * @param index
     * @returns {number}
     */
    const getLastSpecId = () => {
        let id = 1;
        //如果存在获取规格最后一个ID
        if (specList.length !== 0) {
            id = Math.max(...specList.map(x => x.id)) + 1
        }
        return id;
    }

    /**
     * 添加规格属性
     * @param e
     * @param id
     */

    const addSubSpe = (e, id) => {
        e.preventDefault()
        //input表单值
        const value = ''
        const index = getSpecIndex(id)
        const pValue = specList[index].value
        const specId = specList[index].spec_id
        const attrId = getLastSubSpecId(index)
        const CurrentSubSpecList = specList[index].attr_list || []
        //服务端请求的ID ，前期随机生成

        CurrentSubSpecList.push({
            id:specId + '-' + attrId,
            spec_value:pValue ,
            value,
            thumb: null,
            price: '',
            stock: '',
            code: '',
            spec_id:specId,
            attr_id: attrId,
            remark: '',
        })
        specList[index].attr_list = CurrentSubSpecList
        setSpecList([...specList])

        const name = `specNames_${id}`
        from.validateFields([name])
    }

    /**
     * 添加规格项图片
     * @param url
     */
    const addSubSpecThumb = (url) => {
        const index = getSpecIndex(thumb.id)
        specList[index].attr_list[thumb.index].thumb = url
        setSpecList([...specList])
    }

    /**
     * 删除规格项图片
     * @param id
     * @param subIndex
     */
    const removeSubSpecThumb = (id, subIndex) => {
        const index = getSpecIndex(id)
        specList[index].attr_list[subIndex].thumb = null
        setSpecList([...specList])

    }


    /**
     * 删除规格项
     * @param id
     * @param subIndex
     * @param subSpecId
     */
    const removeSubSpec = (id, subIndex, subSpecId) => {
        const index = getSpecIndex(id)
        specList[index].attr_list.splice(subIndex, 1)
        setSpecList([...specList])
        //清空表单数据
        const reset = {}
        const subName= `subSpecNames_${subSpecId}_${id}`
        reset[subName] = ''
        from.setFieldsValue(reset)
        //重新验证数据
        const name = `specNames_${id}`
        from.validateFields([name])
    }

    /**
     * 更新规格名
     * @param id
     * @param subIndex
     * @param value
     */
    const updateSubSpec = (id, subIndex, value) => {
        const index = getSpecIndex(id)
        ///判断有没有重名
        const subSpecItem = specList[index].attr_list;
        subSpecItem[subIndex].value = value
        setSpecList([...specList])
    }

    /**
     * 获取规格索引
     * @param id
     * @returns {number}
     */
    const getSpecIndex = (id) => {
        return specList.findIndex(item => item.id === id)
    }


    /**
     * 验证input价格输入
     * @param rule
     * @param value
     * @param callback
     */

    const checkSpec = async (rule, value , callback) => {
        const isValue =  value.replace(/(^\s*)|(\s*$)/g, '');
        if(isValue.length === 0 ){
            await Promise.reject('请输入规格名称！')
        }
        //判断规格项是否存在
        const index = rule.field.indexOf('_');
        const specId = rule.field.substr(index + 1, rule.field.length);
        const specIndex = getSpecIndex(parseInt(specId))
        const specItem = specList[specIndex].attr_list;
        if (!specItem || specItem.length === 0) {
            await Promise.reject('规格项必须添加！')
        }
        await Promise.resolve()
    }

    useImperativeHandle(props.refs, () => ({


        getSkuValues: () => {
            skuList.forEach(item => {
                item.price = from.getFieldValue(`${item.sku_id}-price`)
                item.stock = from.getFieldValue(`${item.sku_id}-stock`)
                item.code =  from.getFieldValue(`${item.sku_id}-code`)
                item.remark = from.getFieldValue(`${item.sku_id}-remark`)
            })
            return {sku : skuList , spec:specList};
        },



        setSkuValues: (params) => {
            setSpecList(params.spec)
            params.spec.forEach(item => {
                const reset = {}
                reset[`specNames_${item.spec_id}`] = item.value
                from.setFieldsValue(reset)
                item.attr_list.forEach(subItem => {
                    const subReset = {}
                    subReset[`subSpecNames_${subItem.attr_id}_${subItem.spec_id}`] = subItem.value
                    from.setFieldsValue(subReset)
                })

            })
            params.sku.forEach(item => {
                const reset = {}
                reset[`${item.sku_id}-price`] = item.price
                reset[`${item.sku_id}-stock`] = item.stock
                reset[`${item.sku_id}-code`] = item.code
                reset[`${item.sku_id}-remark`] = item.remark
                from.setFieldsValue(reset)
            })
            //循环添加from值
        },
    }));


    return (
        <div>
            <Form.Item label="商品规格" extra='每一种规格代表不同型号，例如颜色为一种规格，尺寸为一种规格，如果设置多规格，用户必须选择一个规格项，才能购买，最多添加五项。'>
                {specList.length !== 0 &&
                    specList.map((item, index) => (
                        <div key={item.id} className='bg-light px-3 pt-3 mb-3'>
                            <div className='mb-3 d-flex justify-content-between'>
                                <Space align={'start'}>
                                    <Form.Item initialValue='' name={`specNames_${item.id}`} className='mb-0' rules={[{
                                        required: true,
                                        validator: checkSpec,
                                    }]}>
                                        <Input
                                            disabled={disabled}
                                            addonBefore="规格名称"
                                            maxLength={20}
                                            style={{width: 500}}
                                            placeholder='添加规格,比如（颜色）'
                                            onChange={(e) => updateSpec(e.target.value, item.id)}
                                        />
                                    </Form.Item>
                                    <Button disabled={disabled} type="dashed" icon={<PlusOutlined/>}
                                                           onClick={(e) => addSubSpe(e, item.id)}> 添加规格项 </Button>
                                </Space>
                                <Button disabled={disabled}  type="dashed" icon={<DeleteOutlined/>} danger
                                        onClick={() => removeSpec(item.id)}>
                                    删除
                                </Button>
                            </div>

                            <div>
                                <div className='d-flex align-items-start flex-wrap'>
                                    {item.attr_list &&
                                        item.attr_list.map((subListItem, subIndex) => (
                                            <div key={subIndex} className='me-3 mb-3'>
                                                <Input.Group compact>
                                                    <Form.Item initialValue='' className='mb-0'
                                                               name={`subSpecNames_${subListItem.attr_id}_${item.id}`}
                                                               rules={[{
                                                                   required: true,
                                                                   message: '规格项名称必须输入！'
                                                               }, {whitespace: true, message: '规格项名称不能是空格！'}]}>
                                                        <Input
                                                            disabled={disabled}
                                                            autoFocus={true}
                                                            maxLength={30}
                                                            placeholder="规格项，比如（红色）"
                                                            style={{width: 200}}
                                                            onChange={(e) => updateSubSpec(item.id, subIndex, e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    {subListItem.thumb ? <div className='position-relative sku-attr'
                                                                              style={{width: 32, height: 32}}>
                                                        {!disabled &&   <CloseCircleFilled
                                                                onClick={() => removeSubSpecThumb(item.id, subIndex)}
                                                                className='position-absolute rounded-circle bg-white'
                                                                style={{
                                                                    fontSize: 15,
                                                                    top: -5,
                                                                    right: -5,
                                                                    zIndex: 1000
                                                                }}/>}
                                                            <Image
                                                                className='d-inline'
                                                                width={32}
                                                                src={subListItem.thumb + "?x-oss-process=image/resize,w_150,h_150,m_fill,limit_0"}
                                                                preview={{
                                                                    src: subListItem.thumb,
                                                                }}
                                                            />
                                                        </div> :
                                                        <Button disabled={disabled}  onClick={() => {
                                                            setThumb({id: item.id, index: subIndex})
                                                            selectRef.current.onOpen(1)
                                                        }} icon={<FileImageOutlined/>}/>
                                                    }
                                                    <Button disabled={disabled} onClick={() => removeSubSpec(item.id, subIndex, subListItem.attr_id)}
                                                            icon={<CloseOutlined/>}/>

                                                </Input.Group>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    ))}
                {specList.length < 5 && <Button disabled={disabled} type="primary" icon={<PlusOutlined/>} onClick={addSpec}>
                    添加规格
                </Button>}
            </Form.Item>
            <SkuTable tableData={specList} from={from} skuList={setSkuList} disabled={disabled}/>
            <Attachment  refs={selectRef} onChange={(item) => addSubSpecThumb(item[0].url)}/>
        </div>
    )
}

export default CreateSku
