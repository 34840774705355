// 路由鉴权
import React  from 'react'
import { Navigate } from "react-router-dom";
import {userToken} from "../store";
import {useRecoilValue} from "recoil";
//
const AuthRouter = ({ children }) => {
    const token = useRecoilValue(userToken);
    return  token ? children : <Navigate to={'/login'} />;
};



export default AuthRouter

