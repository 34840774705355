/**
 * Created by devin on 2021/4/23.
 */

import React, {useEffect, useRef, useState} from 'react'

import {useNavigate, useParams} from 'react-router-dom'
import {
    Button,
    Divider,
    Form,
    Space,
    Input,
    message,
    Radio,
    Spin,
    Select,
    Typography,
    Cascader,
    Empty,
    InputNumber, Breadcrumb, DatePicker, TimePicker, Switch, Modal
} from "antd";
import {
    PlusOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import cityData from '../../utils/City'
import {ThumbSelect} from '../../components'
import {useHttps} from "../../hooks";
import moment from "moment";

const {Text} = Typography

const Edit = (props) => {
    const {id} = useParams();
    const {put, get} = useHttps();
    const [type, setType] = useState(1)
    const [isFile, setIsFile] = useState(false)
    const [thumb, setThumb] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [isPoints, setIsPoints,] = useState(false)
    const [errorText, setErrorText] = useState('');
    const formRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        get('admin/activity/' + id, null, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    res.data.city_data = [res.data.province, res.data.city, res.data.area];
                    if (res.data.start_date) {
                        res.data.apply_date = [moment(res.data.start_date), moment(res.data.end_date)];
                    }
                    if (res.data.activity_start_date) {
                        res.data.activity_date = [moment(res.data.activity_start_date), moment(res.data.activity_end_date)];
                        res.data.activity_time = [moment(moment(res.data.activity_start_date).format('HH:mm:ss'), 'HH:mm:ss'), moment(moment(res.data.activity_end_date).format('HH:mm:ss'), 'HH:mm:ss')];
                    }
                    formRef.current.setFieldsValue(res.data)
                    setThumb(res.data.thumb)
                    setIsFile(res.data.is_file);
                    setIsPoints(res.data.is_reward)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setErrorText(e.message);
                    setError(true);
                }

            });

    }, [get, id]);


    const onFinish = (params) => {
        params.province = params.city_data[0];
        params.city = params.city_data[1];
        params.area = params.city_data[2];
        setLoading(true)
        put('admin/activity/' + id, params)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('内容更新成功！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 错误显示
     */
    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除这项活动吗？</div>,
            onOk() {
                // onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>全部活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>编辑</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary'
                                                                             style={{
                                                                                 width: 5,
                                                                                 height: 14
                                                                             }}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item className='text-dark'>活动</Breadcrumb.Item>
                            <Breadcrumb.Separator/>
                            <Breadcrumb.Item onClick={() => navigate(-1)}
                                             className='text-dark'>全部活动</Breadcrumb.Item>
                            <Breadcrumb.Separator/>
                            <Breadcrumb.Item className='text-primary'>编辑</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <Space>
                            <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                                返回列表
                            </Button>
                            <Button onClick={() => showConfirm()} type="primary" danger>
                                删除
                            </Button>
                        </Space>
                        <Divider/>
                        <div>
                            <Form

                                ref={formRef}
                                labelCol={{span: 4}}
                                wrapperCol={{span: 16}}
                                name="basic"
                                initialValues={{
                                    name: '',
                                    status: 0,
                                    tel:  '',
                                    type:  1,
                                    address: '',
                                    transfer_rate: 1
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="活动主题"
                                    name="title"
                                    rules={[{required: true, message: '请输入活动全称！'}]}
                                >
                                    <Input disabled placeholder='请填写活动的主题！'/>
                                </Form.Item>
                                <Form.Item
                                    label="报名电话"
                                    name="tel"
                                    wrapperCol={{span: 4}}
                                    rules={[{
                                        required: true,
                                        message: '请输入联系电话！',
                                        validator: async (rule, value) => {
                                            const regPos = /^\d+(\.\d+)?$/;
                                            if (!regPos.test(value)) {
                                                return Promise.reject()
                                            }
                                        }
                                    }]}
                                >
                                    <Input disabled placeholder='格式为手机或或座机041188888888'/>
                                </Form.Item>


                                <Form.Item
                                    label="宣传海报"
                                    name='thumb'
                                    extra="上传尺寸建议220x220"
                                    className="mt-3"
                                    wrapperCol={{span: 4}}
                                >

                                    <ThumbSelect disabled src={thumb} />
                                </Form.Item>
                                <Form.Item
                                    label="活动类型"
                                    required
                                >
                                    <Form.Item
                                        name="type"
                                        className='d-inline-block me-3'
                                        rules={[{required: true, message: '请选择分类！'}]}
                                    >
                                        <Select disabled placeholder="选择活动类型" style={{width: 160}} value={type}
                                                onChange={setType}>
                                            <Select.Option value={0}>公益活动</Select.Option>
                                            <Select.Option value={1}>线上活动</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>报名人数：</span>
                                    <Form.Item
                                        className='d-inline-block'
                                        name="total"
                                        wrapperCol={{span: 6}}
                                    >
                                        <InputNumber disabled min={1} max={1000}/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label="报名日期"
                                    name="apply_date"
                                    rules={[{type: 'array', required: true, message: '请输入报名时间！'}]}
                                >
                                    <DatePicker.RangePicker
                                        disabled
                                        disabledDate={(current) => current < moment().subtract(1, "days") || current > moment().add(1, 'months')}
                                        format={'YYYY-MM-DD'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="选择城市"
                                    name="city_data"
                                    rules={[{required: true, message: '请输入城市！'}]}
                                >
                                    <Cascader
                                        disabled
                                        style={{width: 380}}
                                        options={cityData}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="详细地址"
                                    name="address"
                                    wrapperCol={{span: 6}}
                                    rules={[{required: true, message: '请输入标题！'}]}
                                >
                                    <Input disabled placeholder="请输入精准到门牌号"/>
                                </Form.Item>
                                <Form.Item required label="位置坐标">
                                    <Form.Item className='mb-1'
                                               wrapperCol={{span: 8}}
                                               name="location"
                                               rules={[{required: true, message: '请输入坐标！'}]}
                                    >
                                        <Input disabled/>

                                    </Form.Item>
                                    <Text type="secondary"> 请进入<a href='https://lbs.amap.com/tools/picker'
                                                                  rel="noopener noreferrer" target="_blank">高德地图</a>进行坐标查询</Text>
                                </Form.Item>
                                <Form.Item label="活动日期" className='mb-0' required>
                                    <Form.Item
                                        name="activity_date"
                                        className='d-inline-block me-3'
                                        rules={[{type: 'array', required: true, message: '请输入活动时间！'}]}
                                    >
                                        <DatePicker.RangePicker
                                            disabled
                                            disabledDate={(current) => current < moment().subtract(1, "days") || current > moment().add(1, 'months')}
                                            format={'YYYY-MM-DD'}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className='d-inline-block mb-0'
                                        name="activity_time"
                                        rules={[{type: 'array', required: true, message: '请输入活动时间！'}]}
                                    >
                                        <TimePicker.RangePicker disabled/>
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item label="是否设置奖励" className='mb-0'>


                                    <Form.Item
                                        className='d-inline-block me-5'
                                        name="is_reward"
                                        valuePropName="checked"
                                    >
                                        <Switch disabled onChange={setIsPoints}/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>设置积分：</span>
                                    <Form.Item
                                        className='d-inline-block mb-0'
                                        name="reward"
                                        rules={[{required: isPoints, message: '请输入活动积分！'}]}
                                    >
                                        <Input  placeholder='活动奖励积分' min={1} type="number" maxLength={5}
                                               disabled prefix="￥" suffix="积分"/>
                                    </Form.Item>


                                </Form.Item>

                                <Form.Item
                                    label="是否开启投票"
                                    name="is_vote"
                                    valuePropName="checked"
                                >
                                    <Switch disabled />
                                </Form.Item>
                                <Form.Item label="是否开启资料投递" className='mb-0'>
                                    <Form.Item
                                        className='d-inline-block me-5'
                                        name="is_file"
                                        valuePropName="checked"
                                    >
                                        <Switch disabled onChange={setIsFile}/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>投递说明：</span>
                                    <Form.Item
                                        className='d-inline-block mb-0 w-50'
                                        name="reward"
                                        rules={[{required: isFile, message: '请输入投递说明！'}]}
                                    >
                                        <Input  placeholder='输入投递说明' disabled maxLength={100}/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label="报名规则"

                                >
                                    <Form.List name="description">
                                        {(fields, {add, remove}) => (
                                            <>
                                                {fields.map(({key, name, ...restField}) => (
                                                    <div className='d-flex align-items-center align-content-center'
                                                         key={key}>
                                                        <Form.Item
                                                            className='me-3'
                                                            {...restField}
                                                            name={[name, 'key']}
                                                            rules={[{required: true, message: '规则项'}]}
                                                        >
                                                            <Input disabled maxLength={8} placeholder="规则项"/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            className='w-100 me-3'
                                                            {...restField}
                                                            name={[name, 'value']}
                                                            rules={[{required: true, message: '规则描述内容'}]}
                                                        >
                                                            <Input disabled maxLength={50} placeholder="输入规则描述内容说明"/>
                                                        </Form.Item>
                                                        {/*<div className='mb-4'>*/}
                                                        {/*    <MinusCircleOutlined onClick={() => remove(name)}/>*/}
                                                        {/*</div>*/}

                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button disabled onClick={() => add()} icon={<PlusOutlined/>}>
                                                        添加描述
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>


                                <Form.Item name='content' label="活动简介">
                                    <Input.TextArea disabled maxLength={300} showCount/>
                                </Form.Item>

                                <Form.Item
                                    name="status"
                                    label="审核资料"
                                >
                                    <Radio.Group value={0}>
                                        <Radio value={0}>待审核</Radio>
                                        <Radio value={1}>拒绝</Radio>
                                        <Radio value={2}>通过</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item wrapperCol={{offset: 4}}>
                                    <Button className='me-3' type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                    <Button className='me-2' onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>

        </div>
    )
}
export default Edit;
