/**
 * Notes: 用户订单
 * User: 李强
 * DateTime: 2021/12/10 14:28
 * Ide: PhpStorm
 */

import React, {useCallback, useEffect, useState} from 'react'

import {
    Avatar,
    Badge,  message, Space, Table, Typography
} from 'antd';
import {withRouter} from 'react-router-dom'
import {useHttps} from "../../hooks";
const {Text} = Typography;
/*
  用户管理
*/
const MchWithdraw = (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)



    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/shop/order/withdraw/log' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15, mch_id:props.id});
    }, [getData, props.id]);



    const columns = [
        {
            title: '提现金额',
            dataIndex: 'withdraw_price',
            render: (price) => {
                return (
                    <div>
                        <Text>¥{price}</Text>
                    </div>

                )
            }
        },
        {
            title: '提现银行',
            dataIndex: 'bank',
            render: (bank) => {
                return (
                    <div>
                        {bank ? bank : '暂未录入'}
                    </div>
                )
            }
        },
        {
            title: '操作员',
            dataIndex: 'user',
            render: (user) => {
                return (
                    <Space>
                        <Avatar size={24}  src={user.avatar} alt={user.nickname} />
                        <Text>{user.nickname}</Text>
                    </Space>
                )
            }
        },
        {
            title: '提现日期',
            dataIndex: 'created_at',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status , item) => {
                //state
                return (
                    <div>
                        {status === 2 && <Badge count={'已到账'} style={{backgroundColor: '#52c41a'}}/> }
                        {status === 1 &&   <Badge  count={'转账中'} style={{backgroundColor: '#fa8c16'}}/> }
                        {status === 0 &&   <Badge  count={'待处理'} /> }
                    </div>
                )
            }
        },
    ];

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            mch_id:props.id
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    return (
        <div className='bg-white px-4'>
            <Table
                size='middle'
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    size:'default',
                    total: total,
                    current: current,
                    showTotal: total => `总计 ${total} 次提现`,
                    pageSize: perPage,
                }}
                onChange={onPaginationChange}
                rowKey={(record) => {
                    return record.id
                }}
            />
        </div>
    )
}

export default MchWithdraw
