/**
 * Created by devin on 2021/4/23.
 */

import React, {useRef, useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Image} from "antd";
import {CloseCircleFilled, PlusOutlined} from "@ant-design/icons";
import 'react-quill/dist/quill.snow.css';
import {Attachment} from "../../components";


const ThumbSelect = (props) => {
    const {onChange , src ,  disabled, size} = props
    const [thumb, setThumb] = useState(null)
    const selectRef = useRef();

    useEffect(() => {
       setThumb(src);
    }, [src]);


    /**
     * 附加图片处理
     * @param item
     */
    const imageInsert = (item) => {
            setThumb(item[0].url);
            onChange(item[0].url);
    }


    return (
        <div>
            {thumb ? <div className='border border-light p-1 position-relative' style={{width: size, height: size}}>
                { !disabled &&  <CloseCircleFilled onClick={() => {
                            setThumb(null);
                            onChange(null)
                        }}
                                       className='position-absolute rounded-circle bg-white'
                                       style={{ fontSize: 20, top: -5,right: -5, zIndex: 1000}}
                        />}
                    <Image
                        className='d-inline'
                        width={size - 10 }
                        src={thumb + "?x-oss-process=image/resize,w_150,h_150,m_fill,limit_0"}
                        preview={{
                            src: thumb,
                        }}
                    />
                </div> :
                <div onClick={() => !disabled ? selectRef.current.onOpen(1) : null }
                     className='bg-light d-flex justify-content-center align-content-center align-items-center position-relative cursor'
                     style={{width: size, height: size}}>
                    <PlusOutlined style={{fontSize: 20 , color: !disabled ? '#212529' : '#dee2e6'}}/>
                </div>
            }
              <Attachment refs={selectRef} onChange={imageInsert}/>
        </div>
    )

}

ThumbSelect.propTypes = {
    disabled: PropTypes.bool,
    src:PropTypes.string,
    onChange:PropTypes.func,
    size:PropTypes.number,
};


ThumbSelect.defaultProps = {
    size: 110,
    disabled:false
};

export default ThumbSelect;
