
import Home from '../pages/Home';
import Error404 from '../pages/Error/Error404';
import User from '../pages/User';
import UserTags from '../pages/User/Tags';
import UserGrade from '../pages/User/Grade';
import UserAccount from '../pages/User/Account';
import UserAuth from '../pages/User/Auth';
import UserAuthEdit from '../pages/User/AuthEdit';
import Mch from '../pages/Mch';
import CreateMch from '../pages/Mch/Create';
import MchAccount from '../pages/Mch/Account';
import EditMch from '../pages/Mch/Edit';
import MchTags from '../pages/Mch/Tags';
import MchCategory from '../pages/Mch/Category';
import MchUser from '../pages/Mch/MchUser';
import Goods from  '../pages/Goods'
import GoodsTags from '../pages/Goods/Tags';
import GoodsCategory from '../pages/Goods/Category';
import GoodsEdit from '../pages/Goods/Edit';
import GoodsDetails from '../pages/Goods/Details';
import Admin from '../pages/Admin';
import AdminEdit from '../pages/Admin/Edit';
import AdminCreate from '../pages/Admin/Create';
import Article from '../pages/Article';
import CreateArticle from '../pages/Article/Create';
import EditArticle from '../pages/Article/Edit';
import ArticleCategory from '../pages/Article/Category';
import Jobs from '../pages/Jobs';
import EditJobs from '../pages/Jobs/Edit';
import Page from '../pages/Page';
import CreatePage from '../pages/Page/Create';
import EditPage from '../pages/Page/Edit';
import Attachment from "../pages/Attachment";
import OrderUser from "../pages/Order/OrderUser";
import OrderUserDetails from "../pages/Order/OrderUserDetails";
import OrderMch from "../pages/Order/OrderMch";
import OrderMchDetails from "../pages/Order/OrderMchDetails";
import OrderRefund from "../pages/Order/OrderRefund";
import OrderGoodsDetails from "../pages/Order/OrderGoodsDetails";
import OrderComment from "../pages/Order/OrderComment";
import OrderCommentDetails from "../pages/Order/OrderCommentDetails";
import OrderWithdraw from "../pages/Order/OrderWithdraw";
import OrderWithdrawDetails from "../pages/Order/OrderWithdrawDetails";
import OrderWithdrawLog from "../pages/Order/OrderWithdrawLog";
import OrderWithdrawLogDetails from "../pages/Order/OrderWithdrawLogDetails";
import Blog from "../pages/Blog";
import EditBlog from "../pages/Blog/Edit";
import BlogCategory from "../pages/Blog/Category";
import BlogComment from "../pages/Blog/Comment";
import News from "../pages/News";
import NewsEdit from "../pages/News/Edit";
import Activity from "../pages/Activity";
import ActivityEdit from "../pages/Activity/Edit";
import ActivityDetails from "../pages/Activity/Details";

export const routes = [
  { path: "/home", component: Home },
  { path: "/", component: Home },
  { path: "/article/all", component: Article},//列表
  { path: "/article/create",component: CreateArticle },//添加
  { path: "/article/edit/:id", component: EditArticle},//编辑
  { path: "/article/category", component: ArticleCategory},//编辑
  { path: "/news/all", component: News},//列表
  { path: "/news/edit/:id", component: NewsEdit},//编辑
  { path: "/page", component:Page},//列表
  { path: "/page/create",component: CreatePage },//添加
  { path: "/page/:id", component: EditPage},//编辑
  { path: "/blog/all",  component:Blog},//列表
  { path: "/blog/edit/:id", component:EditBlog},//列表
  { path: "/blog/category",  component:BlogCategory},//列表
  { path: "/blog/comment",  component:BlogComment},//列表
  { path: "/jobs",  component: Jobs},//列表
  { path: "/jobs/edit/:id", component: EditJobs},//编辑
  { path: "/activity", component: Activity},//列表
  { path: "/activity/edit/:id", component: ActivityEdit},//编辑
  { path: "/activity/details/:id", component: ActivityDetails},//编辑
  { path: "/mch/all",  component:Mch},//列表
  { path: "/mch/create", component: CreateMch },//添加
  { path: "/mch/account/:id", component: MchAccount  },//添加
  { path: "/mch/edit/:id",  component: EditMch },//编辑
  { path: "/mch/tags", component: MchTags },
  { path: "/mch/category", component: MchCategory },
  { path: "/mch/user/:id", component: MchUser },
  { path: "/goods/all", component:Goods},
  { path: "/goods/tags", component: GoodsTags },
  { path: "/goods/category", component: GoodsCategory },
  { path: "/goods/edit/:id",component: GoodsEdit },
  { path: "/goods/details/:id", component: GoodsDetails },
  { path: "/attachment", component: Attachment},
  { path: "/order/user",  component: OrderUser},
  { path: "/order/user/:id", component: OrderUserDetails},
  { path: "/order/mch", component: OrderMch},
  { path: "/order/mch/:id", component: OrderMchDetails},
  { path: "/order/refund",  component: OrderRefund},
  { path: "/order/goods/:id", component: OrderGoodsDetails},
  { path: "/order/comment",  component: OrderComment},
  { path: "/order/comment/:id", component: OrderCommentDetails},
  { path: "/order/withdraw", component: OrderWithdraw},
  { path: "/order/withdraw/:id", component: OrderWithdrawDetails},
  { path: "/order/log",  component: OrderWithdrawLog},
  { path: "/order/log/:id", component: OrderWithdrawLogDetails},
  { path: "/404", component: Error404 },
  { path: "/user/all",component: User },
  { path: "/user/tags", component: UserTags },
  { path: "/user/grade", component: UserGrade },
  { path: "/user/account/:id",  component: UserAccount },
  { path: "/user/auth",component: UserAuth },
  { path: "/user/auth/edit/:id", component: UserAuthEdit },
  { path: "/staff",component: Admin },
  { path: "/staff/edit/:id", component: AdminEdit },
  { path: "/staff/create", component: AdminCreate },
]
