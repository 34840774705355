/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
    Button,
    Divider,
    Row,
    Col,
    Space,
    Empty,
    Skeleton,
    Typography,
    Breadcrumb,
    Tag,
    Avatar,
    Badge,
   Rate, List, Comment
} from 'antd';
import {useNavigate, useParams} from 'react-router-dom'
import {useHttps} from "../../hooks";

import {Thumb} from "../../components";
import moment from "moment";

const {Title} = Typography;


const Details = (props) => {
    const {get} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [comment, setComment] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [commentLoading, setCommentLoading] = useState(true)
    const printRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        get('/admin/shop/goods/' + id, null, true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);


    /**
     * 数据请求
     * @param {Object} params
     */
    const getCommentData = useCallback((params) => {
        get('admin/shop/order/comment', params, true)
            .then((res) => {
                if (res.mounted) {
                    setCommentLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setComment(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setCommentLoading(false);
                }
            });
    }, [get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getCommentData({
            page: pageNumber,
            per_page: 15,
            goods_id: id
        })
    }, [getCommentData, id]);


    const typeText = (type) => {
        let content = '实体商品';
        switch (type) {
            case 2:
                content = '虚拟商品';
                break;
            case 3:
                content = '外卖订餐';
                break;
            case 4:
                content = '酒店客房';
                break;
            case 5:
                content = '售票卡券';
                break;
            default:
                content = '实体商品';
        }
        return content;
    };


    /**
     * 表格操作触发
     * @param pagination
     * @param pageSize
     */
    const onPaginationChange = (pagination, pageSize) => {
        setCommentLoading(true);
        const params = {
            page: pagination,
            per_page: pageSize,
            goods_id: id
        }
        window.location.hash = `#${pagination.current}`;
        getCommentData(params);
    }

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>商品</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>商户商品</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>商品详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>商品</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>全部商品</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>商品详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4' style={{height: 520}}>
                    <Skeleton className='mt-5'/>
                    <Skeleton className='mt-5'/>
                    <Skeleton/>
                </div>
            </div>
        )
    }


    return (
        <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>商品</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>全部商品</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>商品详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div ref={printRef}>
                    <div className='p-3 bg-white '>
                        <Row gutter={16} justify="space-between">
                            <Col>
                                <Space size={20}>
                                    <Thumb preview={data.thumb} width={200} height={160}
                                           src={data.thumb + "?x-oss-process=image/resize,w_320,h_220,m_fill,limit_0"}/>
                                    <div>
                                        <div className='d-flex '>
                                            <Title className='mb-3 me-3' level={5}>{data.title}</Title>
                                            {data.status  === 2 ?
                                                <Badge
                                                    count={data.state ? '销售中' : '已下架'}
                                                    style={{backgroundColor: data.state ? '#52c41a' : '#6c757d'}}
                                                /> :
                                                <Badge
                                                    count={data.status=== 1 ? '已拒绝' : '待审核'}
                                                    style={{backgroundColor: data.status === 1 ? '#f5222d' : '#fa8c16'}}
                                                />
                                            }
                                        </div>
                                        <div className='mb-2'>
                                            <Space>
                                                <Tag className='mb-1'>{typeText(data.type)}</Tag>
                                                {data.is_verify && <Tag className='mb-1'>线下核销</Tag>}
                                                {data.is_time && <Tag className='mb-1'>限时购买</Tag>}
                                                {data.is_points && <Tag className='mb-1'>积分兑换</Tag>}
                                                {data.is_level && <Tag color="cyan" className='mb-1'>会员商品</Tag>}
                                                {data.recommend && <Tag color="lime" className='mb-1'>分类推荐</Tag>}
                                            </Space>
                                        </div>
                                        <div className='mb-2'> 商户：{data.mch.name}</div>
                                        <div>
                                            <Space align="center" size={15}>
                                                <div className='border border-gray-300 border-dashed rounded p-2'
                                                     style={{width: 140, height: 65}}>
                                                    <Title className='mb-1' level={5}>¥{data.market_price}</Title>
                                                    <span className='d-block text-muted font-size-0'>销售价格</span>
                                                </div>
                                                <div className='border border-gray-300 border-dashed rounded p-2'
                                                     style={{width: 140, height: 65}}>
                                                    <Title className='mb-1' level={5}>{data.sales}</Title>
                                                    <span className='d-block text-muted font-size-0'>销售数量</span>
                                                </div>
                                                <div className='border border-gray-300 border-dashed rounded p-2'
                                                     style={{width: 140, height: 65}}>
                                                    <div style={{marginBottom: 6}}>
                                                        <Rate style={{fontSize: 14}} disabled
                                                              defaultValue={data.score}/>
                                                    </div>
                                                    <span className='d-block text-muted font-size-0'>商品评分</span>
                                                </div>
                                            </Space>
                                        </div>
                                    </div>

                                </Space>

                            </Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => navigate( '/goods/edit/' + data.id)}>
                                        编辑商品
                                    </Button>
                                    <Button className='me-2' type="primary" onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </div>
                    <Divider>商品评论</Divider>
                    <div className='bg-white p-4 mt-3'>

                            <List
                                loading={commentLoading}
                                itemLayout="horizontal"
                                dataSource={comment}
                                pagination={{
                                    defaultCurrent: 1,
                                    total: total,
                                    current: current,
                                    showTotal: total => `总计 ${total} 条评论`,
                                    pageSize: perPage,
                                    onChange: onPaginationChange
                                }}
                                renderItem={item => (
                                    <div key={item.id}>
                                        <Comment
                                            author={item.user.nickname}
                                            avatar={item.user.avatar}
                                            content={
                                                <div>
                                                    <div>{item.content}</div>
                                                    {item.thumb_url && <div className='mt-3 d-flex flex-wrap'><Space>
                                                        {item.thumb_url.map((tag, index) => {
                                                            return (
                                                                <Thumb
                                                                    key={index}
                                                                    width={80}
                                                                    height={80}
                                                                    src={tag.url + "?x-oss-process=image/resize,w_200,h_200,m_fill,limit_0"}
                                                                    preview={tag.url}
                                                                />
                                                            );
                                                        })}
                                                    </Space></div>
                                                    }
                                                    <div className='mt-2'> <Rate style={{fontSize: 14}} disabled defaultValue={data.score}/></div>
                                                </div>
                                            }
                                            datetime={moment(item.created_at).format('YYYY-MM-DD HH:mm')}
                                        >

                                            {item.reply_content && item.reply_content.length !== 0 &&   <Comment
                                                author={item.mch.name}
                                                avatar={<Avatar src={item.mch.logo} alt={item.mch.name} />}
                                                content={
                                                    <div>
                                                        <div>{item.reply_content}</div>
                                                        {item.reply_thumb_url && <div className='mt-3 d-flex flex-wrap'><Space>
                                                            {item.reply_thumb_url.map((tag, index) => {
                                                                return (
                                                                    <Thumb
                                                                        key={index}
                                                                        width={80}
                                                                        height={80}
                                                                        src={tag.url + "?x-oss-process=image/resize,w_200,h_200,m_fill,limit_0"}
                                                                        preview={tag.url}
                                                                    />
                                                                );
                                                            })}
                                                        </Space></div>
                                                        }
                                                    </div>
                                                }
                                            />
                                            }

                                        </Comment>
                                        <Divider />
                                    </div>
                                )}
                            />
                    </div>
                </div>
        </div>
    )
}

export default Details
