/**
 * Created by devin on 2021/4/23.
 */

import React, {useState, useCallback, useImperativeHandle} from 'react'
import PropTypes from 'prop-types';
import {Avatar,  Input, Modal, Radio} from "antd";
import Text from "antd/es/typography/Text";
import {useHttps} from "../../hooks";
import {isMPStrict} from "../../utils/commonfn";


const UserSelect = (props) => {
    const {onChange,  refs, role} = props
    const {get} = useHttps();
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState(null)
    const [value, setValue] = useState('admin');
    const [error, setError] = useState('');


    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        setLoading(true);
        get('/admin/user/search', params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setData(res.data)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setError(e.message)
                }
            });
    },[get])




    useImperativeHandle(refs, () => ({
        // changeVal 就是暴露给父组件的方法
        onOpen: (t = 1, m = false) => {
            setIsModalVisible(true);
            setData(null);
        },
    }));



    const onOk= () => {
        onChange({role:value, data});
        setIsModalVisible(false)
    }

    const onCancel = () => {
        setIsModalVisible(false)
    }

    const onSearch = (value) => {
        if(value.length === 0) {
            setError('')
            setData(null)
            return;
        }
        const isValue = isMPStrict(value)

        if(!isValue){
            setError('手机号码不正确请重新输入！')
            return;
        }
        setData(null)
        setError('')
        setLoading(true)
        getData({ mobile:value});
    }


    return (
        <Modal  okButtonProps={{ disabled: data == null }} width={580} title="添加运营者" visible={isModalVisible} centered onOk={onOk} onCancel={onCancel}>
            <div className='px-5'>
                {role &&  <div className='mb-3'>
                    <Text type="secondary">您可以设置运营者身份和权限，对应不同的工作！</Text>
                    <Radio.Group  style={{marginTop:10}} value={value} onChange={(e) => setValue(e.target.value)}>
                        <Radio value={'root'}>创建者</Radio>
                        <Radio value={'admin'}>管理员</Radio>
                        <Radio value={'verify'}>核销员</Radio>
                        <Radio value={'service'}>客服人员</Radio>
                    </Radio.Group>
                </div>
                }
                <div className='mb-3'>
                    <Text type="secondary">请输入需绑定的运营者手机号</Text>
                    <Input.Search  style={{marginTop:10}} onSearch={onSearch}  allowClear placeholder='手机号码' loading={loading}/>
                    <div className='mt-4 text-center' style={{height:110}}>
                        {data &&
                            <div>
                            <Avatar size={80} src={data.avatar} />
                            <div className='mt-2'>{data.nickname}({data.mobile})</div>
                        </div>}


                       <div className='mt-2'>{error.length !== 0 && <Text type="danger" className='fs-7'>{error}</Text> }</div>
                    </div>
                </div>
            </div>
        </Modal>
    )

}

UserSelect.propTypes = {
    role: PropTypes.bool,
    onChange:PropTypes.func,
};


UserSelect.defaultProps = {
    role: true,
};

export default UserSelect;
