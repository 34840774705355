import React from 'react'
import {Badge, Tooltip, Input, Space} from "antd";
import {collapsedStore} from "../../store";
import TopAvatar from "./TopAvatar";
import {
    BellOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    QuestionCircleOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import {useRecoilState} from "recoil";
import { useNavigate } from 'react-router-dom';


/*
  头部组件
*/
const TopHeader = (props) => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedStore);
    const navigate = useNavigate();
    const logout = () => {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        navigate('/login', {replace: true});

    }


    return (
        <div className='d-flex justify-content-between align-items-center'>
            <div className='ms-4'>
                <Space size='large'>
                    <div>
                        {collapsed ? <MenuUnfoldOutlined onClick={() => {
                            setCollapsed(false)
                        }}/> : <MenuFoldOutlined onClick={() => {
                            setCollapsed(true)
                        }}/>}
                    </div>
                    <div className='d-flex'>
                        <Input.Search
                            style={{width: 320}}
                            placeholder="搜索您想要的内容"
                            allowClear
                        />
                    </div>
                </Space>
            </div>

            <Space size='large'>
                <div className='cursor'>
                    <Badge dot>
                        <BellOutlined/>
                    </Badge>
                </div>
                <div className='cursor'>
                    <Tooltip title="使用文档">
                        <QuestionCircleOutlined/>
                    </Tooltip>
                </div>
                <TopAvatar/>
                <div onClick={logout} className='cursor'><Tooltip title="退出账户"> <LogoutOutlined/> </Tooltip></div>
            </Space>

        </div>
    )
}
export default TopHeader
