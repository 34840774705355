/**
 * Created by devin on 2021/4/21.
 */

import React  from 'react'
import {Avatar,Menu,Dropdown} from "antd";
import {userInfo} from "../../store";
import {SettingOutlined, UserOutlined } from '@ant-design/icons';
import {useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";

const TopAvatar = (props) => {
    const user = useRecoilValue(userInfo);
    const navigate = useNavigate();
    const menu = (
        <Menu selectedKeys={[]} >
            <Menu.Item  key="login"  onClick={()=> navigate('/home')}>
               您好：{user.name}
            </Menu.Item>
            <Menu.Divider />
                <Menu.Item key="center" onClick={()=> navigate('/staff/edit/' + user.id)}>
                    <UserOutlined  />  个人中心
                </Menu.Item>
                <Menu.Item key="settings">
                    <SettingOutlined  /> 平台设置
                </Menu.Item>
        </Menu>
    );
    return (
        <div className='d-flex align-items-center'>
            <Dropdown overlay={menu}  arrow>
                <div>
            <Avatar size="small" src={user.avatar} />
            <span className='ms-2 font-size-0'>{user.name}</span>
                </div>
            </Dropdown>

        </div>
    )
}
export default  TopAvatar;
