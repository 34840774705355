/**
 * Notes: 附件选择页面
 * User: 李强
 * DateTime: 2021/10/5 9:37 上午
 * Ide: PhpStorm
 */

import React, {useState, useImperativeHandle, useCallback, useRef} from 'react'
import {Button, Col, Modal, Row, List, Spin, Pagination, DatePicker, message, Upload, Progress, Typography} from "antd";
import {
    CheckOutlined,
    PlusOutlined,
    PlayCircleFilled, DiffFilled
} from "@ant-design/icons";

import { ossSing, userToken} from "../../store";
import moment from 'moment';
import {useRecoilValue, useRecoilValueLoadable} from "recoil";
import {useHttps} from "../../hooks";
import { Scrollbars } from 'react-custom-scrollbars';
const {Text} = Typography
const AttachmentModal = (props) => {

    const oss = useRecoilValueLoadable(ossSing);
    const token = useRecoilValue(userToken);
    const {get} = useHttps();
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState([])
    const [selectData, setSelectData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(50)
    const [selectDate, setSelectDate] = useState('')
    let [percent, setPercent] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [type , setType] = useState(1)
    const [multiple , setMultiple] = useState(false)
    const [fileList, setFileList] = useState([])
    const {onChange , refs} = props;
    const uploadCount = useRef(0);


    useImperativeHandle(refs, () => ({
        // changeVal 就是暴露给父组件的方法
        onOpen: (t = 1, m = false) => {
            setType(t)
            setMultiple(m)
            setIsModalVisible(true);
            setCurrent(1)
            setData([]);
            getData({ page: 1, per_page: 50 , type:t});
        },
    }));



    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        setLoading(true);
        get('admin/attachment', params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])


    /**
     * 上传回调
     * @returns {*}
     * @param info
     */



    const uploadChange = info => {
        let dataSource = [...info.fileList];
        if (info.file.status === 'uploading' ) {
            setFileList(dataSource)
            const total = dataSource.reduce((total, item) => total + item.percent , 0)
            const  percentTotal = total / dataSource.length
            setPercent(parseInt(percentTotal.toString()))
            setUploading(true)
            setLoading(true)
        }
        if (info.file.status === 'done') {
            uploadCount.current -= 1 ;
            if(uploadCount.current === 0){
                setLoading(false)
                setUploading(false)
                dataSource.splice(0,dataSource.length)
                setFileList(dataSource)
            }
            //如果是第一页则添加
            if (info.file.response.code !== 0 && current === 1 && info.file.response.data) {
                let dataSource = [...data];
                dataSource.slice(0, -1);
                dataSource.unshift(info.file.response.data) ;
                setData(dataSource)
            }

        }
    };

    /**
     * 生成唯一图片名
     * @param file
     * @returns {*}
     */

    const genNonDuplicateID = () => {
        let str = Math.random().toString(36).substr(3);
        str += Date.now().toString(16).substr(4);
        return str;
    };


    /**
     * 上传前回调
     * @param file
     * @returns {*}
     */
    const beforeUpload = async file => {

        if (oss.state !== 'hasValue') {
            message.error('网络错误，请稍后重试!');
            return Upload.LIST_IGNORE;
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M && file.type !== 'video/mp4') {
            message.error('上传文件不能大于 2MB!');
            return Upload.LIST_IGNORE;
        }
        uploadCount.current += 1
        setPercent(0)
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = genNonDuplicateID() + suffix;
        file.url = oss.contents.dir + filename;
        return file;
    };


    /**
     * OSS上传数据
     * @param file
     * @returns {*}
     */
    const getExtraData = file => {
        return {
            key: file.url,
            OSSAccessKeyId: oss.contents.accessid,
            policy: oss.contents.policy,
            callback: oss.contents.callback,
            'x:token': token,
            'x:name': file.name.substring(0, file.name.lastIndexOf(".")),
            Signature: oss.contents.signature,
        };
    };


    /**
     * 关闭模态框 返回数据
     */

    const handleOk = () => {
        onChange(selectData, type);
        setSelectData([])
        setIsModalVisible(false);
    };

    /**
     * 关闭模态框
     */
    const handleCancel = () => {
        setSelectData([])
        setIsModalVisible(false);
    };


    /**
     * 翻页操作触发
     * @param {int} page 翻页信息
     * @param {int} pageSize 内容过滤
     */

    const onPaginationChange = (page, pageSize) => {

        const params = {
            page: page,
            per_page: pageSize,
            type: type
        }
        if (selectDate) {
            const startDate = selectDate.startOf("month").format('YYYY-MM-DD');
            const endDate = selectDate.endOf("month").format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        getData(params);
    }




    /**
     * 获取已选择的数据
     * @param item
     */
    const onSelectChange = (item) => {
        //判断类型
        if (type === 1 && item.type !== 1) {
            message.error('请选择图片!');
            return;
        }

        if (type === 2 &&  item.type !== 2) {
            message.error('请选择视频!');
            return;
        }

        if (type === 3 &&  item.type !== 3) {
            message.error('请选择文件!');
            return;
        }
        const select = selectData.findIndex((value) => value.id === item.id);
        let dataSource = [...selectData];
        if (select > -1) {
            dataSource.splice(select, 1);
        } else {
            //是否为多选
            if (!multiple) {
                dataSource = [];
            }
            //生成唯一索引
            const  key = {key: Date.now().toString()}
            const newItem = {...item , ...key}
            dataSource.push(newItem);
        }
        setSelectData(dataSource)
    }


    /**
     * 判断当前是否选中
     * @param item
     * @returns {boolean}
     */
    const isSelect = (item) => {
        const select = selectData.findIndex((value) => value.id === item.id);
        return select > -1
    }


    const disabledDate = (current) => {
        return current < moment(new Date('2021/01/01')) || current > moment().endOf('day')
    }

    return (
        <Modal width={980} title="选择附件" centered={true} visible={isModalVisible} onOk={handleOk}
               onCancel={handleCancel}>

            <Spin size="large" indicator={uploading ? <Progress type="circle" percent={percent} width={120} /> : {}}
                  spinning={loading}>
                <div>
                    <div className='bg-white'>
                        <Row>
                            <Col span={18}>
                                <Upload
                                    multiple
                                    fileList={fileList}
                                    accept={type === 1 ? ".jpg,.jpeg,.png,.bmp,.gif" : type === 2 ?".mp4," : ".pdf"}
                                    action={oss.state === 'hasValue' ? oss.contents.host : ''}
                                    showUploadList={false}
                                    onChange={uploadChange}
                                    data={getExtraData}
                                    beforeUpload={beforeUpload}
                                >
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined/>}
                                    >
                                        {type === 1 && '上传图片'}
                                        {type === 2 && '上传视频'}
                                        {type === 3 && '上传文件'}
                                    </Button>
                                </Upload>
                                <span className='text-muted ms-2'>
                            已选择{selectData.length}项
                        </span>
                            </Col>

                            <Col span={6} className='d-flex justify-content-end'>
                                <DatePicker disabledDate={disabledDate} onChange={setSelectDate} picker="month"/>
                                <Button onClick={() => onPaginationChange(1, perPage)} type="primary"
                                        className='me-3 ms-1'>日期筛选</Button>
                            </Col>
                        </Row>
                        <Scrollbars style={{ width: '100%', height:  document.body.offsetHeight / 2.2 , marginTop:15}}>
                        <div style={{overflowX: 'hidden'}}>
                            <List
                                grid={{gutter: 16, column: 7}}
                                dataSource={data}
                                rowKey={(record) => {
                                    return record.id
                                }}
                                renderItem={item => (
                                    <List.Item>
                                        <div onClick={() => onSelectChange(item)} className='attachment-modal'>
                                            {item.type === 1 &&
                                            <img  alt="something" style={{width: '100%', height:90 ,opacity: isSelect(item) ? .5 : 1}}
                                                 src={item.url + "?x-oss-process=image/resize,w_300,h_230,m_fill,limit_0"}/>
                                            }

                                            {item.type === 2 &&
                                            <div className="d-flex justify-content-center align-items-center" style={{backgroundColor:'#282828'}}>
                                                <div className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
                                                    <PlayCircleFilled className="text-white font-size-1 opacity-7"/>
                                                </div>
                                                <img  src={item.url + '?x-oss-process=video/snapshot,t_1000,m_fast,f_jpg,h_90, ar_auto'} alt="something" />
                                            </div>}

                                            {item.type === 3 &&
                                            <div className="d-flex justify-content-center align-items-center h-100">
                                                <div className="text-center w-100 px-3">
                                                    <DiffFilled className="text-success font-size-3 mb-2"/>
                                                    <Text className="d-block font-size-0" ellipsis >{item.name}</Text>
                                                </div>
                                            </div>}

                                            {isSelect(item) && <div
                                                className="d-flex justify-content-center align-items-center select-image-item">
                                                <div className="rounded-circle d-flex justify-content-center align-items-center image-item"  style={{backgroundColor: '#07c160'}}>
                                                    <CheckOutlined style={{color: '#ffffff'}}/>
                                                </div>
                                            </div>}

                                        </div>

                                    </List.Item>
                                )}
                            />

                        </div>
                        </Scrollbars>
                        <div className='d-flex justify-content-end pt-3'>
                            <Pagination defaultCurrent={1} total={total} current={current} pageSize={perPage}
                                        onChange={onPaginationChange}/>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    )
}
export default AttachmentModal;
