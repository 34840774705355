import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';


import {
    useSortable,
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';


import {Image} from "antd";

import {CloseCircleFilled} from "@ant-design/icons";



/**
 * 排序样式
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SortableItem = (props) => {

    const {onClick, url, index, id} = props;
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: id});
    const style = {
        width: 115,
        height: 115,
        paddingLeft: 7,
        paddingTop: 7,
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <div className='border border-light bg-white me-2 mb-2  position-relative' ref={setNodeRef}
             style={style} {...attributes} {...listeners}>
            <CloseCircleFilled onClick={() => {
                onClick(index)
            }}
                               className='position-absolute rounded-circle bg-white'
                               style={{
                                   top: -5,
                                   right: -5,
                                   fontSize: 18,
                                   zIndex: 10
                               }}/>
            <Image
                width={100}
                src={url + "?x-oss-process=image/resize,w_300,h_300,m_fill,limit_0"}
                preview={{
                    src: url
                }}/>
        </div>
    );
}

/**
 * 排序组件
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SortableImage = (props) => {


    const {list, onChange , disabled} = props;
    const [items, setItems] = useState(list);
    useEffect(() => {
            setItems(list)
    }, [list]);


    /**
     * 拖拽阻止点击冒泡
     */
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 3,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    /**
     * 删除项目
     * @param i
     */
    const onRemove = (i) => {
            const dataSource = [...items];
            dataSource.splice(i, 1);
            setItems(dataSource)
            onChange(dataSource)
    }

    /**
     * 排序后的数据
     * @param event
     */
    const handleDragEnd = (event) => {
        const {active, over} = event;
        if (!over) return null;
        if (active.id !== over.id) {
                setItems((items) => {
                    const oldIndex = items.findIndex(({key}) => key === active.id);
                    const newIndex = items.findIndex(({key}) => key === over.id);
                    const newItems = arrayMove(items, oldIndex, newIndex);
                    onChange(newItems)
                    return newItems;
                });
        }
    }

    if(disabled){
        return (
            <div className='d-flex align-items-center'>
                {list.map((item, index) => (
                    <div key={index} className='border border-light bg-white me-2 mb-2 position-relative' style={{
                        width: 115,
                        height: 115,
                        paddingLeft: 7,
                        paddingTop: 7,
                    }} >
                        <Image
                            width={100}
                            src={item.url + "?x-oss-process=image/resize,w_300,h_300,m_fill,limit_0"}
                            preview={{
                                src: item.url
                            }}/>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={items.map(({key}) => key)}
                    strategy={rectSortingStrategy}
                >
                    <div className='d-flex align-items-center'>
                        {items.map((item, index) => (
                            <SortableItem onClick={onRemove} key={index} index={index} id={item.key} url={item.url} />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
        </div>
    );
}


SortableImage.propTypes = {
    list: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

SortableImage.defaultProps = {
    list: [],
    disabled:false,
};


export default SortableImage;
