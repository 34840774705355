/**
 * Notes: 用户标签
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useState, useRef, useEffect, useCallback} from 'react'
import {Button, message, Divider, Form, Input, Col, Row, Table, Popconfirm, Breadcrumb, Typography} from "antd";
import { DeleteOutlined} from "@ant-design/icons";
import {useRecoilState} from "recoil";
import {goodsTagsState} from "../../store";
import {useHttps} from "../../hooks";
const {Title} = Typography;

const Tags = (props) => {
    const {put, get, destroy, post} = useHttps();
    const [tags, setTags] =  useRecoilState(goodsTagsState);
    const [loading, setLoading] = useState(true)
    const [sendLoading, setSendLoading] = useState(false)
    const [data, setData] = useState([]);
    const [editItem, setEditItem,] = useState(0)
    const [editVisible, setEditVisible] = useState(false)
    const formRef = useRef();
    const [current, setCurrent] = useState(1)

    /**
     * 分类数据
     * @param {Object} params
     */
    const getData = useCallback( () => {
        get('admin/shop/goods/tags', null , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    setTags(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                }
            });
    },[get, setTags])



    useEffect(() => {
        getData()
    }, [getData]);


    /**
     * 更新分类
     */

    const onUpdate = (value, item, field) => {
        setEditVisible(false)
        if (item[field] == null &&  value.length === 0) {
            return
        }

        if(item[field] === value){
            return;
        }
        const dataSource = [...tags];
        const select = dataSource.findIndex((v) => v.id === item.id);
        dataSource[select][field] = value;
        setTags(dataSource)
        setLoading(true)
        item[field] = value;
        put('admin/shop/goods/tags/' + item.id, item)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('标签已更新！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    };

    /**
     * 删除分类
     * @param id
     */


    const onDelete = (id) => {
        setLoading(true)
        destroy('admin/shop/goods/tags/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('删除添加成功！');
                    const dataSource = [...tags];
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setTags(dataSource)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 添加分类
     * @param params
     */


    const onFinish = (params) => {
        setSendLoading(true)
        post('admin/shop/goods/tags', params)
            .then((res) => {
                if (res.mounted) {
                    setSendLoading(false)
                    message.success('标签添加成功！');
                    const dataSource = [...tags];
                    dataSource.unshift(res.data);
                    setTags(dataSource)
                    formRef.current.setFieldsValue({name: ''})
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSendLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 表格设置
     * @param item
     */

    const columns = [

        {
            title: '标签ID',
            dataIndex: 'id',
            render: (text) => {
                return (
                    <span> ID:{text}</span>
                )

            },
        },
        {
            title: '标签名称',
            dataIndex: 'name',
            width: 200,
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {editVisible && editItem === item.id ?
                            <Input
                                maxLength={8}
                                autoFocus={editVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'name')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'name')}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setEditItem(item.id)
                                setEditVisible(true)
                            }}>{text}</div>}
                    </div>
                )

            },
        },
        {
            title: '内容数',
            dataIndex: 'total',
            render: (text) => {
                return (
                    <span>{text}</span>
                )

            },
        },
        {
            title: '操作',
            key: 'action',
            render: (key, item) => {
                return (
                    <div>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete(item.id)}>
                            <Button className='text-muted' type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        },

    ];


    const onSearch = (e) => {
        setCurrent(1)
        if(e.length === 0){
            setData([])
            return
        }
        const search = tags.filter((el) => el.name.toLowerCase().indexOf(e.toLowerCase())  > -1)
        setData(search)
        setCurrent(1)
    }


    const onReset = () => {

        window.location.hash = '#1';
        formRef.current.setFieldsValue({ description: '', name: ''})

    }

    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >商品</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>商品标签</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='p-2'>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Table
                            loading={loading}
                            rowKey={(record) => {
                                return record.id
                            }}
                            columns={columns}
                            dataSource={data.length === 0 ? tags : data}
                            pagination={{
                                total: tags.length,
                                defaultCurrent: 1,
                                current:current,
                                showSizeChanger: true,
                                showTotal: total => `总计 ${total} 个标签`,
                                onChange: (page) => setCurrent(page)

                            }}/>
                    </Col>

                    <Col span={7}>
                        <div className='bg-white p-4 mb-3'>
                            <Title level={5}>搜索标签</Title>
                            <div className='mt-4 w-100'>
                                <Input.Search placeholder="输入标签名称" allowClear onSearch={onSearch} enterButton="搜索"  />
                            </div>
                        </div>
                        <div className='bg-white p-4'>
                            <Title level={5}>添加新标签</Title>
                            <div className='mt-4'>
                                <Form
                                    labelCol={{span: 6}}
                                    ref={formRef}
                                    name="basic"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        label="名称"
                                        name="name"
                                        rules={[{required: true, message: '请输入分类名称！'}]}
                                    >
                                        <Input placeholder="请输入标签名称"/>
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 4}}>
                                        <Button loading={sendLoading} className='me-3' type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                        <Button className='me-2' onClick={onReset}>
                                            重置
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default Tags;
