/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
    Button,
    Divider,
    Spin,
    Row,
    Col,
    Table,
    Descriptions,
    Space,
    Empty,
    Skeleton,
    Typography,
    message,
    Breadcrumb,
    Tag,
    Avatar,
    Badge,
    Select,
    Modal
} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {
    ArrowDownOutlined, ArrowUpOutlined, ExclamationCircleOutlined,
} from "@ant-design/icons";
import {Thumb} from "../../components";
import moment from "moment";

const {Text, Title} = Typography;


const Details = (props) => {
    const {put, get} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const [status, setStatus] = useState(3)
    const [apply, setApply] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState({content: '', file: []});
    const [applyLoading, setApplyLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const printRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        get('admin/activity/' + id, null, true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);


    /**
     * 数据请求
     * @param {Object} params
     */
    const getApplyData = useCallback((params) => {
        get('admin/activity/apply', params, true)
            .then((res) => {
                if (res.mounted) {
                    setApplyLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setApply(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    }, [get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getApplyData({
            page: pageNumber,
            per_page: 15,
            activity_id: id
        })
    }, [getApplyData, id]);

    /**
     * 更新用户资料
     */
    const onUpdate = (status) => {
        setSpin(true)
        put('admin/activity/' + id, {status: status})
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('备注提交成功！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 操作提示信息
     */
    const showConfirm = (type) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要{type === 1 ? '拒绝' : '通过'}选中项目吗？</div>,
            onOk() {
                onUpdate({status: type}, selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    const columns = [
        {
            title: '用户',
            dataIndex: 'user',
            render: (user, item) => {
                return (
                    <Link to={'/user/account/' + user.id}>
                        <Space>
                            <Avatar src={user.avatar}/>
                            <div>
                                <Text>{user.nickname}</Text>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: '认证资料',
            dataIndex: 'user',
            render: (user, item) => {
                return (
                    <Link to={'/user/account/' + user.id}>
                        <Space>
                            <Text>{user.name}</Text>
                            <div className='text-muted mt-1 fs-7'>({user.mobile})</div>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: data && data.is_vote ? '投票数' : '参加人数',
            dataIndex: data && data.is_vote ? 'vote_count' : 'total',
            render: (count) => {
                return (
                    <Text>{total} {data.is_vote ? '票' : '人'}   </Text>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <div>
                        {status === 0 && <Badge count={'待审核'} style={{backgroundColor: '#fa8c16'}}/>}
                        {status === 1 && <Badge count={'已拒绝'} style={{backgroundColor: '#dc3545'}}/>}
                        {status === 2 && <Badge count={'已发布'} style={{backgroundColor: '#52c41a'}}/>}
                    </div>
                )
            }
        },
        {
            title: '报名日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                    <Space>

                        <Button disabled className='text-muted' type="dashed" size='small'>拒绝</Button>
                        <Button disabled className='text-muted' type="dashed" size='small'>通过</Button>

                        {data.is_file && <Button onClick={() => {
                            setModalData(item)
                            setIsModalVisible(true)
                        }} className='text-muted' type="dashed" size='small'>资料</Button>}
                    </Space>
                )
            }
        },
    ];

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {

        setApplyLoading(true);

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            activity_id: id
        }
        if (status !== 3) {
            params.status = status;
        }


        window.location.hash = `#${pagination.current}`;
        getApplyData(params);
    }

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>商户活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>活动详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>商户活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>活动详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4' style={{height: 520}}>
                    <Skeleton className='mt-5'/>
                    <Skeleton className='mt-5'/>
                    <Skeleton/>
                </div>
            </div>
        )
    }


    return (
        <div className='mx-3'>
            <Spin size="large" spinning={spin}>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>商户活动</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>活动详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div ref={printRef}>
                    <div className='p-3 bg-white '>
                        <Row gutter={16} justify="space-between">
                            <Col>
                                <Space size={20}>
                                    <Thumb preview={data.thumb} width={160} height={110}
                                           src={data.thumb + "?x-oss-process=image/resize,w_320,h_220,m_fill,limit_0"}/>
                                    <div>
                                        <div className='d-flex '>
                                            <Title className='mb-3' level={5}>{data.title}</Title>
                                            {data.status === 0 && <Badge className='ms-3' count={'待审核'}
                                                                         style={{backgroundColor: '#fa8c16'}}/>}
                                            {data.status === 1 && <Badge className='ms-3' count={'已拒绝'}
                                                                         style={{backgroundColor: '#dc3545'}}/>}
                                            {data.status === 2 && <Badge className='ms-3' count={'已发布'}
                                                                         style={{backgroundColor: '#52c41a'}}/>}
                                        </div>
                                        <div className='mb-2'>
                                            <Space>
                                                <Avatar size={20} src={data.mch.logo}/>
                                                <Text>{data.mch.name}</Text>
                                            </Space>
                                        </div>
                                        <Space>
                                            {data.is_vote && <Tag color="magenta">已开启投票</Tag>}
                                            {data.type === 0 && <Tag color="green">慈善活动</Tag>}
                                            {data.type === 1 && <Tag color="green">线上活动</Tag>}
                                            <span className='text-muted'>报名电话：{data.tel}</span>
                                            <span
                                                className='text-muted'>发布日期：{moment(data.created_at).format('YYYY-MM-DD')}</span>
                                        </Space>
                                    </div>

                                </Space>

                            </Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => navigate('/activity/edit/' + data.id)}>
                                        编辑活动
                                    </Button>
                                    <Button className='me-2' type="primary" onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider>活动详情</Divider>
                        <div>
                            <Descriptions column={3}>
                                <Descriptions.Item label="活动日期">
                                    <span
                                        className='text-muted'> {moment(data.activity_start_date).format('YYYY-MM-DD')} 至 {moment(data.activity_end_date).format('YYYY-MM-DD')}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="活动时间">
                                    <span
                                        className='text-muted'>  {moment(data.activity_start_date).format('HH:mm:ss')} 至 {moment(data.activity_end_date).format('HH:mm:ss')}</span>
                                </Descriptions.Item>

                                <Descriptions.Item label="活动地点"><span
                                    className='text-muted'>{data.address}</span></Descriptions.Item>
                                <Descriptions.Item label="报名人数"><span
                                    className='text-muted'>{data.total}</span></Descriptions.Item>
                                <Descriptions.Item label="报名日期">
                                    <span
                                        className='text-muted'> {moment(data.start_date).format('YYYY-MM-DD')} 至 {moment(data.end_date).format('YYYY-MM-DD')}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="活动奖励"><span
                                    className='text-muted'>{data.is_reward ? '活动结束将获得' + data.reward + '积分' : '暂无任何奖励！'}</span></Descriptions.Item>
                                <Descriptions.Item label="活动介绍" span={3}><span
                                    className='text-muted'>{data.content}</span></Descriptions.Item>
                                {data.description && data.description.length !== 0 && <Descriptions.Item label="报名规则">
                                    <div className='text-muted'>
                                        {data.description.map((tag, i) => {
                                            return (
                                                i + 1 + '. ' + tag.key + tag.value + '   '
                                            );
                                        })}
                                    </div>
                                </Descriptions.Item>}
                            </Descriptions>
                        </div>
                    </div>

                    <div className='bg-white p-3 mt-3'>
                        <Row justify="space-between" align="bottom">
                            <Col>
                                <Space align="center">
                                    <Title className='mb-0' level={5}>已报名({data.apply_count})人</Title>
                                    <Button icon={<ArrowDownOutlined/>}
                                            disabled={selectedRowKeys.length === 0}
                                            onClick={() => showConfirm(2)}
                                            size='small'>通过</Button>
                                    <Button icon={<ArrowUpOutlined/>}
                                            disabled={selectedRowKeys.length === 0}
                                            onClick={() => showConfirm(1)}
                                            size='small'>拒绝</Button>
                                    <span className='text-muted'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 个用户` : ''}
                        </span>
                                </Space>
                            </Col>
                            <Col className='d-flex align-items-start'>
                                <Space>
                                    <Space>
                                        <Select style={{width: 140}} defaultValue={3} value={status}
                                                onChange={setStatus}>
                                            <Select.Option value={3}>全部状态</Select.Option>
                                            <Select.Option value={0}>待审核</Select.Option>
                                            <Select.Option value={1}>已拒绝</Select.Option>
                                            <Select.Option value={2}>已通过</Select.Option>
                                        </Select>
                                        <Button onClick={() => onPaginationChange({current: 1, pageSize: perPage})}>
                                            筛选
                                        </Button>
                                    </Space>
                                    <Button>
                                        导出名单
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <div className='mt-3'>


                            <Table rowKey={(record) => {
                                return record.id
                            }}
                                   dataSource={apply}
                                   columns={columns}
                                   size="middle"
                                   loading={applyLoading}
                                   rowSelection={{
                                       selectedRowKeys,
                                       onChange: setSelectedRowKeys,
                                   }}
                                   pagination={{
                                       size: 'default',
                                       total: total,
                                       current: current,
                                       showTotal: total => `总计 ${total} 报名`,
                                       pageSize: perPage,
                                   }}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
            <Modal title="资料详情" visible={isModalVisible} centered footer={null} width={800}
                   onCancel={() => setIsModalVisible(false)}>
                <p>{modalData.content && modalData.content.length !== 0 ? modalData.content : '未填写任何内容！'}</p>
                {modalData.file.length !== 0 && <div className='d-flex flex-wrap'>
                    {modalData.file.map((tag, index) => {
                        return (
                            <div className='me-2 mb-2' key={index}>
                                <Thumb preview={tag.url} width={110} height={110}
                                       src={tag.url + "?x-oss-process=image/resize,w_320,h_320,m_fill,limit_0"}/>
                            </div>
                        );
                    })
                    }
                </div>
                }
            </Modal>
        </div>
    )
}

export default Details
