/**
 * Created by devin on 2021/4/23.
 */

import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Image} from "antd";
import 'react-quill/dist/quill.snow.css';
import {Icon} from "../../Config";


const Thumb = (props) => {
    const {src, disabled, width, height , rounded ,  onClick, preview} = props
    const [thumb, setThumb] = useState(null)
;
    useEffect(() => {
        setThumb(src);
    }, [src]);



    return (

            <Image
                onClick={!disabled ? onClick : null}
                className={`d-inline cursor ${rounded}`}
                width={width}
                height={height}
                src={thumb}
                preview={preview ? {src: preview} : {visible:false, maskClassName:'d-none'}}
                fallback={Icon.emptyIcon}
            />
    )
}

Thumb.propTypes = {
    disabled: PropTypes.bool,
    src: PropTypes.string,
    width:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    rounded:PropTypes.string,
    onClick: PropTypes.func,
    preview:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
};


Thumb.defaultProps = {
    size: 110,
    disabled: false,
    preview:null,
    width: '100%',
    height: 'auto',
    rounded:'rounded',
    src:''
};

export default Thumb;
