/**
 * Notes: 用户订单
 * User: 李强
 * DateTime: 2021/12/10 14:28
 * Ide: PhpStorm
 */

import React, {useCallback, useEffect, useState} from 'react'

import {
     Badge,  message, Space, Table, Typography
} from 'antd';
import {Link, withRouter} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Thumb} from "../../components";
const {Paragraph} = Typography;
/*
  用户管理
*/
const MchNews= (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)



    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/news' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15, mch_id:props.id});
    }, [getData, props.id]);



    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            render: (tags, item) => {
                return (
                    <Link to={{pathname: '/news/edit/' + item.id, query: {data: item}}}>
                        <Space>
                            <Thumb
                                width={60}
                                height={60}
                                src={item.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                            />
                            <Paragraph className='me-5 mb-0' ellipsis={{rows: 2}} level={5}>{tags}大皇宫回家啊点喝酒的撒回家啊大哥哈电视的撒规划即将回归</Paragraph>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <div>
                        {status === 2 && <Badge count={'已发布'} style={{backgroundColor: '#52c41a'}}/> }
                        {status === 0 &&   <Badge  count={'待审核'} style={{backgroundColor: '#fa8c16'}}/> }
                        {status === 1 &&   <Badge  count={'已拒绝'}  style={{backgroundColor: '#dc3545'}}/> }
                    </div>
                )
            }
        },
        {
            title: '发布日期',
            dataIndex: 'created_at',
        },
    ];

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            mch_id:props.id
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    return (
        <div className='bg-white px-4'>
            <Table
                size='middle'
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    size:'default',
                    total: total,
                    current: current,
                    showTotal: total => `总计 ${total} 条新闻`,
                    pageSize: perPage,
                }}
                onChange={onPaginationChange}
                rowKey={(record) => {
                    return record.id
                }}
            />
        </div>
    )
}

export default MchNews
