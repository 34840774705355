/**
 * Notes: video.js 播放器
 * User: 李强
 * DateTime: 2021/10/7 11:26 上午
 * Ide: PhpStorm
 */
import React, {useImperativeHandle, useState, useRef} from "react";

import {Modal} from "antd";
import {CloseOutlined} from "@ant-design/icons";

 const Player = (props) => {
    const videoRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [videoUrl, setVideoUrl] = useState('')

    useImperativeHandle(props.refs, () => ({
        // changeVal 就是暴露给父组件的方法
        onOpen: (src) => {
            setVideoUrl(src)
            setIsModalVisible(true);
            if (videoRef.current){
                videoRef.current.play();
            }
        },
    }));

     const handleClose = () => {
         if (videoRef.current){
             videoRef.current.pause();
         }
     }

     const handleCancel = () => {
             setIsModalVisible(false);
     };

    return (
        <Modal width={980} centered maskClosable={true} bodyStyle={{backgroundColor:'#000000'}} closable={false} visible={isModalVisible} footer={null}  afterClose={handleClose}>
            <div onClick={handleCancel}
                 className="position-absolute" style={{right:0 ,top:-50}}>
                <CloseOutlined className="fs-4 cursor" style={{color:'#ffffff'}}/>
            </div>
        <div className="data-vjs-player">
            <video ref={videoRef} src={videoUrl} controls autoPlay width={940} height={480}  />
        </div>
        </Modal>
    );
}

export default Player;
