/**
 * Notes: 个人资料
 * User: 李强
 * DateTime: 2021/12/10 10:29
 * Ide: PhpStorm
 */

import React, {useEffect, useState} from 'react'

import {
    Button,
    Col,
    Divider,
    Empty,
    Modal,
    Row,
    Skeleton,
    Space,
    Tabs,
    Tag,
    Typography,
    Tooltip,
    List, Image, Breadcrumb
} from 'antd';
import {useNavigate, useParams} from 'react-router-dom'
import { Icon} from '../../Config';
import MchOrder from './MchOrder'
import MchGoods from './MchGoods'
import MchNews from './MchNews'
import MchActivity from './MchActivity'
import MchJobs from './MchJobs'
import MchPoints from './MchPoints'
import MchWithdraw from './MchWithdraw'

import {useHttps} from "../../hooks";
import {numFormat} from "../../utils/commonfn";

const {Text, Title} = Typography;
/*
  用户管理
*/
const Account = (props) => {
    const {id} = useParams();
    const {get} = useHttps();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState('1')
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        get('admin/mch/' + id, null, true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);




    const typeText = () => {
        let content = {};
        switch (data.type) {
            case 1:
                content.color = 'magenta';
                content.text = '企业公司';
                break;
            case 2:
                content.color = 'lime';
                content.text = '政府机关';
                break;
            case 3:
                content.color = 'purple';
                content.text = '事业单位';
                break;
            default:
                content.color = 'geekblue';
                content.text = '个体商户';
        }
        return content;
    };


    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'><Divider type='vertical' className='bg-primary'
                                               style={{width: 5, height: 14}}/> 当前位置：<span
                    className='text-primary'>个人资料</span></div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >商户</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部商户</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>商户详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4 pt-4' style={{height: 290}}>
                    <Skeleton avatar/>
                </div>
                <div className='bg-white px-4  mt-3' style={{height: 320}}>
                    <Skeleton/>
                    <Skeleton/>
                </div>
            </div>
        )
    }


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >商户</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部商户</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item  className='text-primary'>商户详情</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white px-4 pt-4'>

                    <Row justify="space-between">
                        <Col>
                            <Space>
                                <img
                                    className='me-2 rounded'
                                    style={{width: 160, height: 160}}
                                    src={!data.logo || data.logo.length === 0 ? Icon.emptyIcon : data.logo}
                                    alt={data.name}/>
                                <div>
                                    <Space align="center" size={20}>
                                        <Title className='mb-0' level={5}>{data.name}</Title>
                                        <img  onClick={()=> setIsModalVisible(true)} style={{height: 22}} src={data.status === 2 ? Icon.auth : Icon.authNot}
                                             alt={data.name}/>
                                        <div>
                                            <Tag color={typeText().color}>{typeText().text}</Tag>
                                        </div>
                                        <div>
                                            {data.category.map((tag) => {
                                                return (
                                                    <Tag color="blue" key={tag.id}>{tag.name}</Tag>
                                                );
                                            })}
                                        </div>
                                        <div>
                                            {data.tags.map((tag) => {
                                                return (
                                                    <Tag key={tag.id}>{tag.name}</Tag>
                                                );
                                            })}
                                        </div>
                                    </Space>
                                    <div className='mt-2'>
                                        <Space align="center" size={30}>
                                            <Text className='mb-0  text-muted'>地址:{data.address}</Text>
                                            <Text className='mb-0  text-muted'>联系电话:{data.tel}</Text>
                                            <Text className='mb-0  text-muted'>注册日期:{data.created_at}</Text>
                                        </Space>
                                    </div>
                                    <div className='mt-4'>
                                        <Space align="center" size={15}>
                                            <div className='border border-gray-300 border-dashed rounded p-2'
                                                 style={{width: 125, height: 65}}>
                                                <Title className='mb-1' level={5}>{data.statistic.goods}</Title>
                                                <span className='d-block text-muted font-size-0'>商品总数</span>
                                            </div>
                                            <div className='border border-gray-300 border-dashed rounded p-2'
                                                 style={{width: 125, height: 65}}>
                                                <Title className='mb-1' level={5}>{data.statistic.order}</Title>
                                                <span className='d-block text-muted font-size-0'>订单总数</span>
                                            </div>
                                            <div className='border border-gray-300 border-dashed rounded p-2'
                                                 style={{width: 125, height: 65}}>
                                                <Tooltip title={data.statistic.withdraw}> <Title className='mb-1' level={5}><span className='font-size-0'>¥</span>{numFormat( data.statistic.withdraw)}</Title></Tooltip>
                                                <span className='d-block text-muted font-size-0'>收入总额</span>
                                            </div>
                                            <div className='border border-gray-300 border-dashed rounded p-2'
                                                 style={{width: 125, height: 65}}>
                                                <Title className='mb-1' level={5}>{data.points}</Title>
                                                <span className='d-block text-muted font-size-0'>可用积分</span>
                                            </div>
                                        </Space>
                                    </div>
                                </div>
                            </Space>
                        </Col>
                        <Col>
                            <Space align="center">
                                <Button  onClick={() => navigate('/mch/user/'+ id)}>
                                    运营者
                                </Button>
                                <Button  onClick={() => navigate('/mch/edit/' + id)}>
                                    编辑商户
                                </Button>
                                <Button className='me-2' type="primary"  onClick={() => navigate(-1)}>
                                    返回列表
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                <div className='mt-5 account-tabs'>
                    <Tabs defaultActiveKey="1" size='large' onChange={setContent}>
                        <Tabs.TabPane tab="全部订单" key="1"/>
                        <Tabs.TabPane tab="商品列表" key="2"/>
                        <Tabs.TabPane tab="新闻内容" key="3"/>
                        <Tabs.TabPane tab="活动记录" key="4"/>
                        <Tabs.TabPane tab="招聘信息" key="5"/>
                        <Tabs.TabPane tab="积分日志" key="6"/>
                        <Tabs.TabPane tab="提现记录" key="7"/>
                    </Tabs>

                </div>
            </div>

            <div >
                {content === '1' &&   <MchOrder id={id}/> }
                {content === '2' &&   <MchGoods id={id}/> }
                {content === '3' &&   <MchNews id={id}/> }
                {content === '4' &&   <MchActivity id={id}/> }
                {content === '5' &&   <MchJobs id={id}/> }
                {content === '6' &&   <MchPoints id={id}/> }
                {content === '7' &&   <MchWithdraw id={id}/> }


            </div>








            <Modal title="认证资料" visible={isModalVisible} onCancel={()=>setIsModalVisible(false)} footer={null}>
                <div>
                    <Space align="center">
                    <Text>商户名称: </Text>
                    <Text>{data.name} </Text>
                    </Space>

                </div>
                <div className='my-4'>
                    <Space align="center">
                    <Text>证件号码:</Text>
                     <Text>{!data.card_id || data.card_id.length === 0 ? '未录入' : data.card_id}</Text>
                    </Space>
                </div>
                <div className='my-4'>
                    <Space align="center">
                        <Text>服务项目:</Text>
                        <Text>{data.service}</Text>
                    </Space>
                </div>
                <div>
                    <Text >证件附件:</Text>
                    <List
                        className='mt-3'
                        grid={{ gutter: 16, column: 5}}
                        dataSource={data.card_thumb ? data.card_thumb : []}
                        renderItem={item => (
                            <List.Item>
                                <Image
                                    className='d-inline'
                                    width={80}
                                    src={item.url + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                                    preview={{
                                        src: item.url,
                                    }}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
        </div>

    )
}

export default Account

