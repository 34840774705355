/**
 * Created by devin on 2021/4/23.
 */

import React, {useState, useEffect , useRef} from 'react'
import {withRouter} from 'react-router-dom'
import {
    Button,
    message,
    Divider,
    Descriptions,
    Modal,
    Spin,
    Table,
    Empty,
    PageHeader, Avatar,
    Breadcrumb,  Skeleton, Row, Col, Space
} from "antd";
import { ExclamationCircleOutlined} from "@ant-design/icons";
import 'react-quill/dist/quill.snow.css';
import {UserSelect} from "../../components";
import {useHttps} from "../../hooks";



const MchUser = (props) => {

    const id = props.match.params.id;
    const {put,post, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [data, setData] = useState(null);
    const [spin, setSpin] = useState(false);
    const userRef = useRef();
    const rootRef = useRef();




    useEffect(() => {
        get('admin/mch/user/' + id, null, true)
            .then((res) => {
                if (res.mounted) {
                      setData(res.data)
                     setLoading(false);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setErrorText(e.message);
                    setError(true);
                }

            });
    }, [get, id]);




    /**
     * 删除人员
     */
    const onUpdate = (params) => {
        setSpin(true)
        put('admin/mch/user',params)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data)
                    setSpin(false)
                    message.success('人员已更新成功！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 删除人员
     */
    const onDelete = (id) => {
        setSpin(true)
        destroy('admin/mch/user/' + id)
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    let dataSource = [...data.mch_user];
                    let dataObj = {};
                    dataObj.mch_user = dataSource.filter(item => item.id !== parseInt(id) );
                    setData({...data , ...dataObj})
                    message.success('人员已删除成功！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 操作提示信息
     */
    const showConfirm = (id) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中人员吗？</div>,
            onOk() {
                onDelete(id)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }


    /**
     * 添加人员
     */
    const onFinish = (params) => {
        setSpin(true)
        post('admin/mch/user' , params)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data)
                    setSpin(false)

                    message.success('人员已添加成功！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }



    const columns = [
        {
            title: '运营者',
            dataIndex: 'user',
            render: user =>  <Space><Avatar size={24} src={user.avatar} /><span>{user.nickname}</span></Space>
        },
        {
            title: '电话',
            dataIndex: 'user',
            render: user => user.mobile,
        },
        {
            title: '授权',
            dataIndex: 'role',
            render: (role) => {
                return(
                    <div>
                        {role === 'admin' && '管理员'}
                        {role === 'root' && '创建者'}
                        {role === 'service' && '客服人员'}
                        {role === 'verify' && '核销人员'}

                    </div>
                    )

            },
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: status => '已绑定',
        },
        {
            title: '操作',
            render: (text, item) => (
                <Button disabled onClick={()=> showConfirm(item.id)} size='small' type="text">删除</Button>
            ),
        },
    ];

    /**
     * 错误显示
     */

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>商户</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}
                                         className='text-dark'>商户员工</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>设置</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => props.history.goBack()} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>商户</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}
                                         className='text-dark'>商户员工</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>设置</Breadcrumb.Item>
                    </Breadcrumb>

                </div>
                <div className='p-4 d-flex justify-content-center' style={{height: 600}}>
                    <div className='bg-white w-75 p-4'>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>商户</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => props.history.goBack()}
                                         className='text-dark'>商户员工</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>设置</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Spin size="large" spinning={spin}>
                    <div className='d-flex justify-content-center'>
                        <div className='bg-white w-75 px-4 pt-4'>
                            <PageHeader
                                onBack={() => props.history.goBack()}
                                title="运营者信息"
                                extra={[
                                    <Button key="1" onClick={() => rootRef.current.onOpen()}>
                                        修改运营者
                                    </Button>,
                                ]}
                            />


                            <div className='m-4'>
                                <div className='mb-5'>
                                <Descriptions colon={false} column={1}>
                                    <Descriptions.Item labelStyle={{width:100}} label="商户">{data.name}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{width:100}} label="创建者">
                                        <div>
                                            {data.user?.nickname || '未设置'}
                                            <span className='text-muted d-block'>管理员可设置风险操作提醒等保护帐号安全</span>
                                        </div>
                                    </Descriptions.Item>
                                    <Descriptions.Item labelStyle={{width:100}} label="手机号">  {data.user?.mobile || '未设置'}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{width:100}} label="创建时间">{data.created_at || '未设置'}</Descriptions.Item>
                                </Descriptions>
                                </div>

                            <Divider orientation="left" plain>
                                运营者管理
                            </Divider>
                            <div className='mt-4'>
                                <Row justify="space-between">
                                    <Col>  <div  className='text-muted d-block'>运营者账号只有管理员与创建者才能进行后台登录及相关功能操作</div></Col>
                                    <Col>
                                        <span className='text-muted me-4'>还可以添加{50 - data.mch_user.length}名用户</span>
                                        <Button disabled type="primary" size="small" onClick={() => userRef.current.onOpen()}> 添加运营者
                                        </Button>
                                    </Col>

                                </Row>
                                <div className='mt-4'>
                                    <Table  rowKey={(record) => {
                                        return record.id
                                    }} size='small' columns={columns} dataSource={data.mch_user} />
                                </div>
                            </div>
                            </div>



                        </div>


                    </div>
                </Spin>
            </div>
           <UserSelect refs={userRef} onChange={(e)=>{
               //查找有没有次用户
               const listUser = data.mch_user.some(item => item.user.id ===  e.data.id);
                if(!listUser){
                   onFinish({user_id:e.data.id , mch_id: data.id , role:e.role})
                }else{
                    message.error('已在运营者列表！');
                }
           }} />
            <UserSelect role={false} refs={rootRef} onChange={(e)=>{
                const rootId = data.user?.id || 0;
                if(e.data.id !== rootId){
                    onUpdate({user_id:e.data.id , mch_id: data.id , role:'admin'})
                }
            }} />
        </div>
    )
}
export default MchUser;
