/**
 * Created by devin on 2021/4/21.
 */

import React, {useState, useEffect, useCallback} from 'react'


import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Select,
    Table,
    Badge,
    Popconfirm,
    Modal,
    message,
    Tag,
    Space, Breadcrumb, Avatar
} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {subText} from "../../utils/commonfn";



const News = (props) => {
    const {put, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [status, setStatus] = useState(3)
    const [search, setSearch] = useState('')
    const [editItem, setEditItem,] = useState(0)
    const [editVisible, setEditVisible] = useState(false)
    const navigate = useNavigate();
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/jobs',params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])




    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);



    /**
     * 自定义表格
     * @type {Object}
     */

    const columns = [
        {
            title: '排序',
            dataIndex: 'sort',
            width:60,
            sorter: (a, b) => a.sort - b.sort,
            render: (sort, item) => {
                return (
                    <div>
                        {editVisible && editItem === item.id ?
                            <Input
                                autoFocus={editVisible}
                                defaultValue={sort}
                                onBlur={(e) => onUpdateItem(e.target.value, item)}
                                onPressEnter={(e) => onUpdateItem(e.target.value, item)}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setEditItem(item.id)
                                setEditVisible(true)
                            }}>{sort}</div>}
                    </div>
                )
            }
        },
        {
            title: '标题',
            dataIndex: 'title',
            render: (tags, item) => {
                return (
                    <Link to={'/jobs/edit/' + item.id}>
                        <div className='text-dark mb-2'>{subText(tags, 24)}</div>
                        <Space>
                            <Avatar shape="square"  size={16}  src={item.mch.logo} alt={item.mch.name} />
                            <span className='text-muted fs-7'>{subText(item.mch.name , 16)}</span>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: '薪资',
            dataIndex: 'wage_start',
            render: (wage , item) => {
                return (
                    <div>
                        {wage}{parseInt(item.wage_end) !== 0 && ' - '+item.wage_end } 元 /
                        {item.wage_unit === 0 && <span>月薪</span>}
                        {item.wage_unit === 1 && <span>年薪</span>}
                        {item.wage_unit === 2 && <span>日薪</span>}
                    </div>
                )
            }
        },
        {
            title: '分类',
            dataIndex: 'type',
            render: (type) => {
                return (
                    <div>

                        {type === 0 &&   <Tag color="volcano">招聘</Tag> }
                        {type === 1 &&   <Tag color="green">兼职</Tag> }
                        {type === 2 &&  <Tag color="blue">实习</Tag> }
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <div>
                        {status === 0 &&   <Badge  count={'待审核'} style={{backgroundColor: '#fa8c16'}}/> }
                        {status === 1 &&   <Badge  count={'已拒绝'}  style={{backgroundColor: '#dc3545'}}/> }
                        {status === 2 && <Badge count={'已发布'} style={{backgroundColor: '#52c41a'}}/> }
                    </div>
                )
            }
        },
        {
            title: '发布日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                    <div>
                        <Button onClick={() => navigate('/jobs/edit/' + item.id)} className='me-2 text-muted' type="dashed" size='small' icon={<EditOutlined/>}/>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete([item.id])}>
                            <Button className='text-muted' type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];

    /**
     * 自定义表格
     */
    const onUpdateItem = (value, item) => {
        setEditVisible(false)
        if (parseInt(value) === item['sort']) {
            return
        }
        setLoading(true);
        put('admin/jobs/' + item.id , {sort: value} )
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }

    /**
     * 删除列表操作
     * @param {Array} keys  数组id
     */
    const onDelete = (keys) => {
        if (keys.length === 0) {
            return;
        }
        setLoading(true);
        destroy('admin/jobs/batch', {id: keys})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };

    /**
     * 更新列表操作
     * @param {Array} keys  数组id
     * @param {int} status
     */
    const onUpdate = (status) => {
        setLoading(true);
        const dataKey = [...selectedRowKeys];
        put('admin/jobs/batch', {id: dataKey, status: status})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {

        setLoading(true);

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }

        if (status !== 3) {
            params.status = status;
        }
        if (search.length !== 0) {
            params.search = search;
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    };

    /**
     * 重置
     */
    const onReset = () => {
        setStatus(3)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: 15});
    }

    /**
     * 返回视图
     */

    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >招聘</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>全部招聘</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white p-4'>
                <Row>
                    <Col span={10}>
                        <Space>
                            <Select style={{width: 160}} value={status} onChange={setStatus}>
                                <Select.Option value={3}>全部状态</Select.Option>
                                <Select.Option value={0}>待审核</Select.Option>
                                <Select.Option value={1}>已拒绝</Select.Option>
                                <Select.Option value={2}>已通过</Select.Option>
                            </Select>
                            <Button type="primary" className='me-3 ms-1' onClick={() => onPaginationChange({
                                current: 1,
                                pageSize: 15
                            })}>选择状态</Button>
                        </Space>
                    </Col>
                    <Col span={14}>
                        <div className='d-flex justify-content-end'>

                            <Space>
                                <Input.Search
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={(e) => {
                                        if (e.length !== 0) {
                                            onPaginationChange({current: 1, pageSize: perPage})
                                        }
                                    }}
                                    placeholder="输入关键词搜索"
                                    allowClear
                                    enterButton="搜索"
                                    style={{width:480}}
                                />

                                <Button onClick={onReset}>
                                    重置
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>

                <Divider/>
                <div className='mt-4'>
                    <div className='mb-3'>
                        <Button onClick={() => onUpdate(1)} className='me-2' icon={<ArrowDownOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>拒绝</Button>
                        <Button onClick={() => onUpdate(2)} className='me-2' icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>通过</Button>
                        <Button onClick={showConfirm} className='me-2' icon={<DeleteOutlined/>} disabled={selectedRowKeys.length === 0}
                                size='small'>删除</Button>
                        <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 篇新闻` : ''}
                        </span>
                    </div>

                    <Table
                        size="middle"
                        loading={loading}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            size:'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 条招聘`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
export default News;
