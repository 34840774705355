/**
 * Notes: 用户标签
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useState, useRef, useEffect, useCallback} from 'react'
import {Button, message, Divider, Form, Input, Col, Row, Table, Popconfirm, Breadcrumb, Typography} from "antd";
import {PlusOutlined, CloseCircleFilled, DeleteOutlined} from "@ant-design/icons";
import {Attachment, Thumb} from "../../components";
import {useRecoilState} from "recoil";
import {userTagsState} from "../../store";
import {useHttps} from "../../hooks";
import {subText} from "../../utils/commonfn";
const {Title} = Typography;

const Tags = (props) => {
    const [tags, setTags] =  useRecoilState(userTagsState);
    const [loading, setLoading] = useState(true)
    const [sendLoading, setSendLoading] = useState(false)
    const {put, get, destroy, post} = useHttps();
    const [thumb, setThumb] = useState(null)
    const [thumbType, setThumbType] = useState(null)
    const [editItem, setEditItem,] = useState(0)
    const [editVisible, setEditVisible] = useState(false)
    const [descItem, setDescItem,] = useState(0)
    const [descVisible, setDescVisible] = useState(false)
    const formRef = useRef();
    const selectRef = useRef();

    /**
     * 标签数据
     * @param {Object} params
     */
    const getData = useCallback( () => {
        get('admin/user/tags', null , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    setTags(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                }
            });
    },[get, setTags])



    useEffect(() => {
        getData()
    }, [getData]);


    /**
     * 更新标签
     */

    const onUpdate = (value, item, field) => {
        setDescVisible(false)
        setEditVisible(false)
        if (value === item[field]) {
            return
        }

        if (item[field] == null &&  value.length === 0) {
            return
        }
        const dataSource = [...tags];

        const select = dataSource.findIndex((v) => v.id === item.id);
        dataSource[select][field] = value;
        setTags(dataSource)
        setLoading(true)
        item[field] = value;
        put('admin/user/tags/' + item.id, item)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('标签已更新！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    };

    /**
     * 删除标签
     * @param id
     */
    const onDelete = (id) => {
        setLoading(true)
        destroy('admin/user/tags/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('删除添加成功！');
                    const dataSource = [...tags];
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setTags(dataSource)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 添加标签
     * @param params
     */
    const onFinish = (params) => {
        setSendLoading(true)
        post('admin/user/tags', params)
            .then((res) => {
                if (res.mounted) {
                    setSendLoading(false)
                    message.success('标签添加成功！');
                    const dataSource = [...tags];
                    dataSource.unshift(res.data);
                    setTags(dataSource)
                    setThumb(null)
                    formRef.current.setFieldsValue({thumb: null, description: '', name: ''})
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setSendLoading(false)
                    message.error(e.message);
                }

            });
    }




    /**
     * 表格设置
     * @param item
     */

    const columns = [

        {
            title: 'ID',
            dataIndex: 'id',
            render: (text) => {
                return (
                    <span>{text}</span>
                )

            },
        },
        {
            title: '标签图标',
            dataIndex: 'thumb',
            editable: true,
            render: (thumb, item) => {
                return (
                    <Thumb onClick={() => {
                        setThumbType(item)
                        selectRef.current.onOpen(1)
                    }}
                           width={30}
                           height={30}
                           src={thumb}
                    />
                )

            },
        },
        {
            title: '标签名称',
            dataIndex: 'name',
            width: 140,
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {editVisible && editItem === item.id ?
                            <Input
                                maxLength={6}
                                autoFocus={editVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'name')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'name')}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setEditItem(item.id)
                                setEditVisible(true)
                            }}>{text}</div>}
                    </div>
                )

            },
        },
        {
            title: '描述',
            dataIndex: 'description',
            width: 200,
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {descVisible && descItem === item.id ?
                            <Input
                                maxLength={20}
                                autoFocus={descVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'description')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'description')}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setDescItem(item.id)
                                setDescVisible(true)
                            }}>{subText(text , 8)}</div>}
                    </div>
                )

            },
        },
        {
            title: '会员数',
            dataIndex: 'total',
            render: (text) => {
                return (
                    <span>{text}</span>
                )

            },
        },
        {
            title: '操作',
            key: 'action',
            render: (key, item) => {
                return (
                    <div>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete(item.id)}>
                            <Button className='text-muted' type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];

    /**
     * 重置
     */
    const onReset = () => {
        setThumb(null)
        formRef.current.setFieldsValue({thumb: null, description: '', name: ''})

    }
    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>会员标签</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='p-2'>
                <Row gutter={[24, 24]}>
                    <Col span={16}>

                        <Table
                            loading={loading}
                            rowKey={(record) => {
                                return record.id
                            }}
                            columns={columns}
                            dataSource={tags}
                            pagination={{
                                total: tags.length,
                                defaultCurrent:1,
                                showTotal: total => `总计 ${total} 种标签`,
                            }}/>
                    </Col>

                    <Col span={8}>
                        <div className='bg-white p-4'>
                            <Title level={5}>添加新标签</Title>
                            <div className='mt-4'>
                                <Form
                                    initialValues={{description: ''}}
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 0}}
                                    ref={formRef}
                                    name="basic"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        label="标签名称"
                                        name="name"
                                        rules={[{required: true, message: '请输入标签名称！'}]}
                                    >
                                        <Input maxLength={8} placeholder="请输入标签名称"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="图标"
                                        name='thumb'
                                        className="mt-3"
                                        rules={[{required: true, message: '请输入标签图标！'}]}
                                    >
                                        <div className='w-100'>
                                            {thumb ? <div className='border border-light p-1 position-relative'
                                                          style={{width: 70, height: 70}}>
                                                    <CloseCircleFilled onClick={() => {
                                                        setThumb(null);
                                                        formRef.current.setFieldsValue({thumb: null})
                                                    }}
                                                                       className='position-absolute rounded-circle bg-white'
                                                                       style={{
                                                                           fontSize: 20,
                                                                           top: -5,
                                                                           right: -5,
                                                                           zIndex: 1000
                                                                       }}/>
                                                    <img src={thumb + '?x-oss-process=image/resize,w_60,h_60,m_fill,limit_0'}  alt="thumb"/>
                                                </div> :
                                                <div onClick={() => {
                                                    setThumbType(null)
                                                    selectRef.current.onOpen()
                                                }}
                                                     className='bg-light d-flex justify-content-center align-content-center align-items-center position-relative'
                                                     style={{width: 60, height: 60}}>
                                                    <PlusOutlined style={{fontSize: 20}}/>
                                                </div>}
                                        </div>
                                    </Form.Item>
                                    <Form.Item name='description' label="标签描述">
                                        <Input.TextArea maxLength={20} />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 3}}>
                                        <Button loading={sendLoading} className='me-3' type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                        <Button className='me-2' onClick={onReset}>
                                            重置
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </Col>
                </Row>,

            </div>
            <Attachment refs={selectRef}  onChange={(item)=> onUpdate(item[0].url, thumbType, 'thumb')}/>
        </div>
    )
}
export default Tags;
