/**
 * Created by devin on 2021/4/23.
 */

import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
    Button,
    message,
    Divider,
    Input,
    Modal,
    Spin,
    Tooltip,
    Empty,
    PageHeader,
    Breadcrumb, Skeleton, Badge, Radio, Typography
} from "antd";
import {
    EditOutlined, ExclamationCircleOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import 'react-quill/dist/quill.snow.css';
import { userInfo} from "../../store";
import {useRecoilValue} from "recoil";
import {ThumbSelect} from "../../components";
import {useHttps} from "../../hooks";

import {isCorrectFormatPassword, isMPStrict} from "../../utils/commonfn";
const {Text} = Typography;

const Edit = (props) => {
    const user = useRecoilValue(userInfo)
    const {id} = useParams();
    const {put, get, destroy} = useHttps();
    const [avatar, setAvatar] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorInput, setErrorInput] = useState('');
    const [data, setData] = useState(null);
    const [spin, setSpin] = useState(false);
    const [isName, setIsName] = useState(false);
    const [name, setName] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [mobile, setMobile] = useState('');
    const [isStatus, setIsStatus] = useState(false);
    const [status, setStatus] = useState(0);
    const [isPosition, setIsPosition] = useState(false);
    const [position, setPosition] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        get('admin/staff/' + id, null, true)
            .then((res) => {
                if (res.mounted) {
                    setAvatar(res.data.avatar)
                    setData(res.data)
                    setLoading(false);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setErrorText(e.message);
                    setError(true);
                }

            });
    }, [get, id]);

    /**
     * 更新员工内容
     * @param params
     */
    const onUpdate = (params) => {
        Modal.destroyAll()
        setErrorInput('')
        setSpin(true)
        put('admin/staff/' + id, params)
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    setData(res.data)
                    setAvatar(res.data.avatar)
                    message.success('员工已更新成功！');
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 删除员工
     */
    const onDelete = () => {
        setSpin(true)
        destroy('admin/staff/' + id)
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('员工已删除成功！');
                    navigate(-1);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中员工吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }


    /**
     * 错误显示
     */

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>员工</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>全部员工</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>编辑</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                            width: 5,
                            height: 14
                        }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>商户</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>全部商户</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>商户详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='p-4 d-flex justify-content-center' style={{height: 600}}>
                    <div className='bg-white w-75 p-4'>
                        <div className='d-flex justify-content-center w-100 mb-5'>
                            <Skeleton.Avatar size={100}/>
                        </div>

                        <Skeleton/>
                        <Skeleton/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary'
                                                                         style={{
                                                                             width: 5,
                                                                             height: 14
                                                                         }}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item className='text-dark'>员工</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item onClick={() => navigate(-1)}
                                         className='text-dark'>全部员工</Breadcrumb.Item>
                        <Breadcrumb.Separator/>
                        <Breadcrumb.Item className='text-primary'>编辑</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Spin size="large" spinning={spin}>
                    <div className='p-4 d-flex justify-content-center'>

                        <div className='bg-white w-75 p-4'>
                            <PageHeader
                                onBack={() => navigate(-1)}
                                title="员工信息"
                                extra={[
                                    <Button key="1" onClick={() => setIsPassword(true)}>
                                        修改密码
                                    </Button>,
                                    <Button key="2" danger type="primary" disabled={user.id === parseInt(id)} onClick={showConfirm}>
                                        删除
                                    </Button>,
                                ]}
                            />


                            <div className='d-flex justify-content-center w-100 mb-5'>
                                <ThumbSelect size={100} src={avatar} onChange={(e) => {
                                    setAvatar(e)
                                    if(e){
                                        onUpdate({avatar: e})
                                    }
                                }}/>
                            </div>


                            <div className='d-flex w-100'>
                                <div style={{width: 100}} className='text-end text-muted me-5'>姓名</div>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='flex-grow-1'> {data.name} </div>
                                        <div className='cursor' onClick={() => setIsName(true)}><span
                                            className='me-2 text-info'>修改</span> <EditOutlined/></div>
                                    </div>
                                    <Divider/>
                                </div>
                            </div>


                            <div className='d-flex w-100'>
                                <div style={{width: 100}} className='text-end text-muted me-5'>登陆名</div>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='flex-grow-1'>{data.login}</div>
                                        <div><Tooltip title='登陆名称不可修改'><QuestionCircleOutlined/></Tooltip></div>
                                    </div>
                                    <Divider/>
                                </div>
                            </div>

                            <div className='d-flex w-100'>
                                <div style={{width: 100}} className='text-end text-muted me-5'>手机号</div>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='flex-grow-1'> {data.mobile} </div>
                                        <div className='cursor' onClick={() => setIsMobile(true)}><span
                                            className='me-2 text-info'>修改</span> <EditOutlined/></div>
                                    </div>
                                    <Divider/>
                                </div>
                            </div>
                            <div className='d-flex w-100'>
                                <div style={{width: 100}} className='text-end text-muted me-5'>在职位状态</div>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='flex-grow-1'>
                                            {data.status === 0 &&
                                                <Badge count={'待职中'} style={{backgroundColor: '#fa8c16'}}/>}
                                            {data.status === 1 &&
                                                <Badge count={'已离职'} style={{backgroundColor: '#dc3545'}}/>}
                                            {data.status === 2 &&
                                                <Badge count={'在职中'} style={{backgroundColor: '#52c41a'}}/>}
                                            {data.status === 3 &&
                                                <Badge count={'休假中'} style={{backgroundColor: '#0dcaf0'}}/>}
                                        </div>
                                        <div className='cursor' onClick={() => setIsStatus(true)}><span
                                            className='me-2 text-info'>修改</span> <EditOutlined/></div>
                                    </div>
                                    <Divider/>
                                </div>
                            </div>
                            <div className='d-flex w-100'>
                                <div style={{width: 100}} className='text-end text-muted me-5'>职位</div>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='flex-grow-1'> {data.position || '未录入'} </div>
                                        <div className='cursor' onClick={() => setIsPosition(true)}><span
                                            className='me-2 text-info'>修改</span> <EditOutlined/></div>
                                    </div>
                                    <Divider/>
                                </div>
                            </div>
                            <div className='d-flex w-100 mb-4'>
                                <div style={{width: 100}} className='text-end text-muted me-5'>创建日期</div>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='flex-grow-1'> {data.created_at} </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Spin>
            </div>


            <Modal title="修改姓名" visible={isName} centered onOk={() => {
                if(name.length === 0){
                    setErrorInput('姓名必须填写！')
                    return
                }
                setIsName(false)
                onUpdate({name: name})
            }} onCancel={() => setIsName(false)}>
                <Input placeholder='输入真实姓名' maxLength={10} value={name} onChange={(e) => setName(e.target.value)}/>
                <div className='mt-2'>
                    <Text type="danger">{errorInput}</Text>
                </div>
            </Modal>


            <Modal title="修改电话" visible={isMobile} centered onOk={() => {
                const isValue = isMPStrict(mobile)
                if (!isValue) {
                    setErrorInput('手机格式不正确！')
                    return
                }
                setIsMobile(false)
                onUpdate({mobile: mobile})
            }} onCancel={() => setIsMobile(false)}>
                <Input placeholder='输入手机号码' value={mobile} onChange={(e) => setMobile(e.target.value)}/>
                <div className='mt-2'>
                    <Text type="danger">{errorInput}</Text>
                </div>
            </Modal>


            <Modal title="修改职位状态" visible={isStatus} centered onOk={() => {
                setIsStatus(false)
                onUpdate({status: status})
            }} onCancel={() => setIsStatus(false)}>
                <Radio.Group value={status} onChange={(e) => setStatus(e.target.value)}>
                    <Radio value={0}>待入职</Radio>
                    <Radio value={1}>已离职</Radio>
                    <Radio value={2}>在职中</Radio>
                    <Radio value={3}>休假中</Radio>
                </Radio.Group>
                <div className='mt-2'>
                    <Text type="danger">{errorInput}</Text>
                </div>
            </Modal>

            <Modal title="修改密码" visible={isPassword} centered onOk={() => {
                const isValue = isCorrectFormatPassword(password)
                if (!isValue) {
                    setErrorInput('密码格式不正确！')
                    return
                }
                setIsPassword(false)
                onUpdate({password: password})
            }} onCancel={() => setIsPassword(false)}>
                <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} placeholder="输入登陆密码"/>
                <div className='mt-2'>
                    <div className='mb-2 text-muted fs-7'>最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符</div>
                    <Text type="danger">{errorInput}</Text>
                </div>
            </Modal>


            <Modal title="修改密职位" visible={isPosition} centered onOk={() => {
                setIsPosition(false)
                onUpdate({position: position})
            }} onCancel={() => setIsPosition(false)}>
                <Input value={position} onChange={(e) => setPosition(e.target.value)} placeholder="输入所任职称"/>
                <div className='mt-2'>
                    <Text type="danger" className='fs-7'>{errorInput}</Text>
                </div>
            </Modal>

        </div>
    )
}
export default Edit;
