/**
 * Notes: 用户认证
 * User: 李强
 * DateTime: 2021/12/11 16:55
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useCallback} from 'react'
import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Typography,
    Space, Select, Table, Badge, Popconfirm, message, Modal, DatePicker, Breadcrumb, Avatar
} from 'antd';
import { CSVLink, CSVDownload } from "react-csv";
import {
    ArrowDownOutlined, ArrowUpOutlined, PrinterOutlined, CheckOutlined,
    PlusOutlined, EditOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Poster} from "../../components";
import moment from "moment";

const {Text} = Typography;
/*
  用户管理
*/
const Auth = (props) => {
    const {put, get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(4)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRowItems, setSelectedRowItems] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [selectDate, setSelectDate] = useState(null)
    const [search, setSearch] = useState('')
    let [img, setImg] = useState(null);
    const navigate = useNavigate();
    const headers = [
        { label: "姓名", key: "name" },
        { label: "电话", key: "tel" },
        { label: "出生日期", key: "birth" },
        { label: "性别", key: "gender_cn" },
        { label: "身份证号", key: "card_id" },
        { label: "政治面貌", key: "politics" },
        { label: "民族", key: "minority" },
        { label: "紧急联系人", key: "sos_name" },
        { label: "紧急联系人电话", key: "sos_tel" },
    ];
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/user/auth', params ,true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });

    },[get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);


    /**
     * 更新列表操作
     * @param params
     * @param ids
     */
    const onUpdate = (params, ids) => {
        setLoading(true);
        params.id = ids;
        put('admin/user/auth/batch', params)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    message.error(e.message);
                    setLoading(false);
                }
            });
    };

    /**
     * 操作提示信息
     */
    const showConfirm = (type) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要{type === 0 ? '撤回' : '通过'}选中项目吗？</div>,
            onOk() {
                onUpdate({status: type}, selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 操作提示信息
     */

    /**
     * 自定义表格
     * @type {Object}
     */

    const columns = [
        {
            title: '用户',
            dataIndex: 'user',
            render: (user, item) => {
                return (
                    <Link to={'/user/auth/edit/' + item.id}>
                        <Space>
                            <Avatar size={38}  src={user.avatar} alt={user.nickname} />
                            <Text>{user.nickname}</Text>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: '真实姓名',
            dataIndex: 'name',
            render: (name) => {
                return (
                    <Text>{name}</Text>
                )
            }
        },
        {
            title: '联系电话',
            dataIndex: 'tel',
            render: (user, item) => {
                return (
                            <Text>{user}</Text>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (tag, item) => {
                return (
                    item.cancel !== 0 ?  <Badge
                        count={cancelText(item.cancel).text}
                        style={{backgroundColor: cancelText(item.cancel).color}}
                    /> :  <Badge
                        count={statusText(tag).text}
                        style={{backgroundColor: statusText(tag).color}}
                    />
                )
            }
        },
        {
            title: '提交日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                    <Space>
                        <Button onClick={() => navigate('/user/auth/edit/' + item.id)} className='text-muted' type="dashed" size='small' icon={<EditOutlined/>}/>
                        {/*<Popconfirm title="确定撤回报道?" onConfirm={() => onUpdate({status: 1}, [item.id])}>*/}
                        {/*    <Button className='text-muted' type="dashed" size='small' icon={<StopOutlined/>}/>*/}
                        {/*</Popconfirm>*/}
                        {/*<Popconfirm title="确定通过?" onConfirm={() => onUpdate({status: 2}, [item.id])}>*/}
                        {/*    <Button className='text-muted' type="dashed" size='small' icon={<CheckOutlined/>}/>*/}
                        {/*</Popconfirm>*/}
                    </Space>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {

        setLoading(true);

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }
        if (status !== 4) {
            if(status === 6){
                params.cancel = 2;
            }else{
                params.status = status;
            }
        }

        if (search.length !== 0) {
            params.search = search;
        }

        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }

        window.location.hash = `#${pagination.current}`;
        getData(params);
    }

    /**
     * 重置
     */
    const onReset = () => {
        setStatus(4)
        setSearch('')
        setSelectDate(null)
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }

    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys, items) => {
        setSelectedRowKeys(selectedRowKeys)
        setSelectedRowItems(items)
    };



    const printOrder = (id) => {
        const el = document.getElementById(id);
        const iframe = document.createElement('IFRAME');
        let doc = null;
        iframe.setAttribute('style', 'border:no; border:0;position:absolute;0px;height:0px;left:500px;top:500px;');
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        // doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');
        doc.write(el.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        if (navigator.userAgent.indexOf("MSIE") > 0)
        {
            document.body.removeChild(iframe);
        }
    }




    const statusText = (status) => {
        let content = {};
        switch (status) {
            case 0:
                content.color = '#f5222d';
                content.text = '待报道';
                break;
            case 1:
                content.color = '#fa8c16';
                content.text = '待审核';
                break;
            case 2:
                content.color = '#52c41a';
                content.text = '已审核';
                break;
            case 3:
                content.color = '#6c757d';
                content.text = '其他';
                break;
            default:
                content.color = '#fa8c16';
                content.text = '待审核';
        }
        return content;
    };


    const cancelText = (status) => {
        let content = {};
        switch (status) {
            case 0:
                content.color = '#f5222d';
                content.text = '正常';
                break;
            case 1:
                content.color = '#fa8c16';
                content.text = '待转出';
                break;
            case 2:
                content.color = '#6f42c1';
                content.text = '已转出';
                break;
            default:
                content.color = '#fa8c16';
                content.text = '待审核';
        }
        return content;
    };


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>认证信息</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white p-4'>
                <Row justify="space-between">
                    <Col>
                        <Space>
                            {selectedRowKeys.length === 0 && <Button disabled={true}>导出数据</Button>}
                            {selectedRowKeys.length !== 0 && <CSVLink filename={"qingtuan-file.csv"} className='ant-btn ant-btn-primary' data={selectedRowItems} headers={headers} target="_blank" > 导出数据 </CSVLink>}
                        </Space>
                    </Col>
                    <Col className='d-flex align-items-start'>

                        <Space className='me-3'>
                            <DatePicker.RangePicker value={selectDate}  onChange={setSelectDate}/>
                            <Select defaultValue={4} value={status} onChange={setStatus}>
                                <Select.Option value={4}>全部状态</Select.Option>
                                <Select.Option value={0}>待报道</Select.Option>
                                <Select.Option value={1}>待审核</Select.Option>
                                <Select.Option value={2}>已通过</Select.Option>
                                <Select.Option value={6}>已转出</Select.Option>
                                <Select.Option value={3}>其他</Select.Option>
                            </Select>
                            <Button onClick={() => onPaginationChange({
                                current: 1,
                                pageSize: perPage
                            })}
                                    type="primary">筛选</Button>
                        </Space>
                        <Space>
                            <Input.Search
                                placeholder="输入姓名进行搜索"
                                allowClear
                                enterButton="搜索"
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onSearch={(e) => {
                                    onPaginationChange({current: 1, pageSize: perPage})
                                }}
                            />
                            <Button onClick={onReset}>
                                重置
                            </Button>
                        </Space>
                    </Col>
                </Row>


                <Divider/>
                <div className='mt-4'>
                    <Space className='mb-3'>
                        <Button icon={<ArrowDownOutlined/>}
                                disabled={selectedRowKeys.length === 0 }
                                onClick={() => showConfirm(2)}
                                size='small'>通过</Button>
                        <Button icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0 }
                                onClick={() => showConfirm(0)}
                                size='small'>撤回</Button>

                        <Button icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0 }
                                onClick={()=>printOrder('p1')}
                                size='small'>打印</Button>
                        <span className='text-muted'>
                             {selectedRowKeys.length !== 0  ? `已选择 ${selectedRowKeys.length} 个用户` : ''}
                        </span>
                    </Space>

                    <Table
                        size="middle"
                        loading={loading}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            size:'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 篇`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
     <div id='p1' style={{ display:'none'}}>
            {selectedRowItems.map((item ,i)=>{
              return(
                  <div key={i} className='position-relative mb-3'  style={{width:960 , height:219, position:'relative'}}>
                      {item.sign &&  <img src={item.sign}  alt={'DD'} style={{position:'absolute' , left:580 , top:70, zIndex:1000, width:180 , height:180}} /> }
                      {img && <img src={img[item.id]?.images || ''}  alt={'DD'}/>}
                      <Poster drawData={{
                          width: 960,
                          height: 219,
                          backgroundColor: '#fff',
                          views: [
                              {
                                  type: 'image',
                                  url:require('../../assets/p-1.png'), //  eslint-disable-line
                                  top: 0,
                                  left: 0,
                                  width: 960,
                                  height: 219,
                              },

                              {
                                  type: 'text',
                                  content:item?.card_id || '',
                                  fontSize: 14,
                                  color: '#000000',
                                  textAlign: 'center',
                                  top: 70,
                                  left: 350,
                                  width: 200,
                                  lineNum: 3,
                                  lineHeight: 20,
                                  baseLine: 'top',
                              },
                              {
                                  type: 'text',
                                  content:item?.tel || '',
                                  fontSize: 14,
                                  color: '#000000',
                                  textAlign: 'center',
                                  top: 70,
                                  left: 650,
                                  width: 200,
                                  lineNum: 3,
                                  lineHeight: 20,
                                  baseLine: 'top',
                              },
                              {
                                  type: 'text',
                                  content:item? moment(item.created_at).format('YYYY     MM      DD')  : '',
                                  fontSize: 14,
                                  color: '#000000',
                                  textAlign: 'center',
                                  top: 165,
                                  left:805,
                                  width: 200,
                                  lineNum: 3,
                                  lineHeight: 20,
                                  baseLine: 'top',
                              },
                          ],
                      }} success={(res)=>{
                          let obj ={};
                          obj[item.id] = {key: item.id , images :res}
                          let obj2 = {...img, ...obj}
                          setImg(obj2)

                      }}/>
                  </div>
              )
            })}
     </div>
        </div>
    )
}

export default Auth
