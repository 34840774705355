/**
 * Notes: 商户列表
 * User: 李强
 * DateTime: 2021/12/13 12:51
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useCallback} from 'react'

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Typography,
    Breadcrumb,
    Space, Select, Table, Badge, DatePicker, message, Rate, Avatar
} from 'antd';
import {VerticalAlignBottomOutlined} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {useHttps} from '../../hooks'
import moment from "moment";
import {Thumb} from "../../components";
import {subText} from "../../utils/commonfn";

const {Text} = Typography;
/*
  用户管理
*/
const OrderComment = (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(3)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    const [selectDate, setSelectDate] = useState(null)
    const navigate = useNavigate();
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/shop/order/comment', params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }, [get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);


    const columns = [
        {
            title: '订单商品',
            dataIndex: 'order_goods',
            render: (order, item) => {
                return (
                    <Link to={'/order/comment/' + item.id}>
                        <Space>
                            <Thumb
                                width={60}
                                height={60}
                                src={order.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                            />
                            <div>
                                <Text>{subText(order.title, 12)}</Text>
                                <div className='text-muted mt-2 fs-7'>
                                    <Space>
                                        <Avatar shape="square"  size={20}  src={item.mch.logo + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"} alt={item.mch.name} />
                                        <span className='text-muted fs-7'>{item.mch.name}</span>
                                    </Space>
                                </div>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: '评论用户',
            dataIndex: 'user',
            render: (user, item) => {
                return (
                    <Link to={'/user/account/' + user.id}>
                        <Space>
                            <Avatar  size={28}  src={user.avatar} alt={user.nickname} />
                            <div>
                                <Text>{user.nickname}</Text>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: '评分',
            dataIndex: 'score',
            sorter: (a, b) => a.score - b.score,
            render: (score) => {
                return (
                    <div>
                        <Rate style={{fontSize: 14}} disabled defaultValue={score}/>
                    </div>
                )
            }
        },
        {
            title: '提交日期',
            dataIndex: 'created_at',
            render: (created_at) => {
                return (
                    <div>
                        <Text>{moment(created_at).format('YYYY-MM-DD HH:mm')}</Text>
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <div>
                        {status === 0 && <Badge
                            count={'待审核'}
                            style={{backgroundColor: '#6c757d'}}
                        />}
                        {status === 1 && <Badge
                            count={'已拒绝'}
                            style={{backgroundColor: '#dc3545'}}
                        />}
                        {status === 2 && <Badge
                            count={'已通过'}
                            style={{backgroundColor: '#52c41a'}}
                        />}
                    </div>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (

                    <Button onClick={() => navigate( '/order/comment/' + item.id)} className='text-muted' type="dashed" size='small'> 查看评论 </Button>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }

        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        if (status !== 3) {
            params.status = status;
        }
        if (search.length !== 0) {
            params.search = search;
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }

    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setStatus(2)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                        width: 5,
                        height: 14
                    }}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item className='text-dark'>订单</Breadcrumb.Item>
                    <Breadcrumb.Separator/>
                    <Breadcrumb.Item className='text-primary'>订单评论</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            <div className='bg-white p-4'>
                <Row justify="space-between">
                    <Col>
                        <Button
                            type="primary"
                            icon={<VerticalAlignBottomOutlined/>}
                        >
                            导出评论
                        </Button>
                    </Col>
                    <Col className='d-flex align-items-start'>
                        <Space>

                            <DatePicker.RangePicker value={selectDate} onChange={setSelectDate}/>
                            <Select defaultValue={2} value={status} onChange={setStatus}>
                                <Select.Option value={3}>评论状态</Select.Option>
                                <Select.Option value={0}>待审核</Select.Option>
                                <Select.Option value={1}>已拒绝</Select.Option>
                                <Select.Option value={2}>已通过</Select.Option>
                            </Select>

                            <Input.Search
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onSearch={(e) => {
                                    onPaginationChange({current: 1, pageSize: perPage})
                                }}
                                placeholder="输入关键词搜索"
                                allowClear
                                enterButton="筛选"
                            />
                            <Button onClick={onReset}>
                                重置
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 条评论`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderComment
