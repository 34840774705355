/**
 * Notes: 商户列表
 * User: 李强
 * DateTime: 2021/12/13 12:51
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useCallback} from 'react'

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Typography,
    Breadcrumb,
    Space, Select, Table, Badge, DatePicker, message, Avatar
} from 'antd';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {Icon} from "../../Config";
import {useHttps} from '../../hooks'
const {Text} = Typography;
/*
  用户管理
*/
const OrderUser = (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(2)
    const [payType, setPayType] = useState(0)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    const [selectDate, setSelectDate] = useState(null)
    const navigate = useNavigate();
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/shop/order' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);



    const columns = [
        {
            title: '用户',
            dataIndex: 'user',
            render: (user, item) => {
                return (
                    <Link to={'/order/user/' + item.id}>
                        <Space>
                            <Avatar src={user.avatar} size={42} />
                            <div>
                                <Text>{user.nickname}</Text>
                                <div className='text-muted mt-1 fs-7'>联系方式：{user.mobile}</div>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '订单金额',
            dataIndex: 'real_price',
            sorter: (a, b) => a.real_price - b.real_price,
            render: (price) => {
                return (
                    <div>
                        <Text>¥{price}</Text>
                    </div>

                )
            }
        },
        {
            title: '商品数',
            dataIndex: 'order_goods',
            render: (goods) => {
                return (
                    <div>
                        <Text>{goods.length}件</Text>
                    </div>
                )
            }
        },
        {
            title: '支付方式',
            dataIndex: 'pay_type',
            render: (pay) => {
                return (
                    <div>
                        {pay === 2 && <Space>
                            <img
                                style={{width: 22, height: 22}}
                                src={Icon.wechatPay}
                                alt={'微信支付'}/>
                                <Text>微信支付</Text>
                        </Space>
                        }
                        {pay === 1 && <Space>
                            <img
                                style={{width: 22, height: 22}}
                                src={Icon.alipay}
                                alt={'支付宝'}/>
                            <Text>支付宝</Text>
                        </Space>
                        }
                    </div>
                )
            }
        },
        {
            title: '下单日期',
            dataIndex: 'created_at',
            render: (created_at) => {
                return (
                    <div>
                        <Text>{created_at}</Text>
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status , item) => {
                //state
                return (
                    <div>
                        {status === 1 ?
                            <Badge
                                count={'已支付'}
                                style={{backgroundColor: '#52c41a'}}
                            /> :
                            <Badge
                                count={'待支付'}
                                style={{backgroundColor: '#fa8c16'}}
                            />
                        }
                    </div>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (

                        <Button onClick={() => navigate('/order/user/' + item.id)} className='text-muted' type="dashed" size='small'> 查看详情 </Button>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }
        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        if (status !== 2) {
            params.status = status;
        }
        if (search.length !== 0) {
            params.order_sn = search;
        }
        if (payType !== 0) {
            params.pay_type = payType;
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }

    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setPayType(0)
        setStatus(2)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>用户订单</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            <div className='bg-white p-4'>
                <Row justify="space-between">
                    <Col>
                        <Button
                            type="primary"
                            icon={<VerticalAlignBottomOutlined />}
                        >
                            导出订单
                        </Button>
                    </Col>
                    <Col className='d-flex align-items-start'>
                        <Space>

                            <DatePicker.RangePicker value={selectDate} onChange={setSelectDate}/>



                            <Select defaultValue={2} value={status} onChange={setStatus}>
                                <Select.Option value={2}>支付状态</Select.Option>
                                <Select.Option value={0}>待支付</Select.Option>
                                <Select.Option value={1}>已付款</Select.Option>
                            </Select>


                            <Select defaultValue={0} value={payType} onChange={setPayType} >
                                <Select.Option value={0}>全部支付</Select.Option>
                                <Select.Option value={1}>支付宝</Select.Option>
                                <Select.Option value={2}>微信支付</Select.Option>
                            </Select>

                            <Input.Search
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onSearch={(e) => {
                                    onPaginationChange({current: 1, pageSize: perPage})
                                }}
                                placeholder="输入订单号搜索"
                                allowClear
                                enterButton="筛选"
                            />
                            <Button onClick={onReset}>
                                重置
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 份订单`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderUser
