import React from 'react'
import {
  HomeOutlined,
  SettingOutlined,
  BellOutlined,
  ContainerOutlined,
  FolderOutlined,
  TeamOutlined,
  ShoppingOutlined,
  ShopOutlined,
  CameraOutlined,
  CarryOutOutlined,
  IdcardOutlined,
  NotificationOutlined,
  AccountBookOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';

export const menus = [
  {
    path: '/home',
    title: '首页',
    icon: <HomeOutlined />
  },
  {
    path: '/article',
    title: '文章',
    icon: <BellOutlined />,
      children: [
        {
          path: '/article/all',
          title: '全部',
        },
        {
          path: '/article/category',
          title: '分类',
        },
      ]
  },
  {
    path: '/page',
    title: '页面',
    icon: <ContainerOutlined />,
  },
  {
    path: '/user/auth',
    title: '团员',
    icon: <TeamOutlined />,
  },

  {
    path: '/attachment',
    title: '附件',
    icon: <FolderOutlined />,
  },
  {
    path: '/staff',
    title: '员工',
    icon: <UserSwitchOutlined />,
  },

];
