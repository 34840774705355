/**
 * Created by devin on 2021/4/21.
 */

import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilValueLoadable} from 'recoil';

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Select,
    Table,
    Typography,
    Badge,
    Popconfirm,
    Modal,
    message,
    Space, Breadcrumb
} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    PlusOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {articleCategory} from "../../store";
import {useHttps} from "../../hooks";
import {Thumb} from "../../components";

const {Text, Paragraph} = Typography;

const News = (props) => {
    const {put, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [selectCategory, setSelectCategory] = useState(0)
    const [search, setSearch] = useState('')
    const category = useRecoilValueLoadable(articleCategory);
    const navigate = useNavigate();

    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/article',params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])




    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);



    /**
     * 自定义表格
     * @type {Object}
     */

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            width: 360,
            render: (tags, item) => {
                return (
                    <Link to={ '/article/edit/' + item.id}>
                        <Space>
                            <Thumb
                                width={60}
                                height={60}
                                   src={item.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                            />
                            <Paragraph className='mb-0 me-5' ellipsis={{rows: 2}} level={5}>{tags}</Paragraph>
                        </ Space>
                    </Link>
                )
            }
        },
        {
            title: '分类',
            dataIndex: 'category',
            render: (tags) => {
                return (
                    <Text>{tags ? tags.name : '未分类'}</Text>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (tags) => {
                return (
                    <Badge
                        count={tags === 0 ? '隐藏' : '显示'}
                        style={{backgroundColor: tags === 0 ? '#6c757d' : '#28a745'}}
                    />
                )
            }
        },
        {
            title: '发布日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                    <div>
                        <Button onClick={() => navigate('/article/edit/' + item.id)} className='me-2 text-muted' type="dashed" size='small' icon={<EditOutlined/>}/>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete([item.id])}>
                            <Button className='text-muted' type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];

    /**
     * 删除列表操作
     * @param {Array} keys  数组id
     */
    const onDelete = (keys) => {
        if (keys.length === 0) {
            return;
        }
        setLoading(true);
        destroy('admin/article/batch', {id: keys})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };

    /**
     * 更新列表操作
     * @param {Array} keys  数组id
     * @param {int} status
     */
    const onUpdate = (status) => {
        setLoading(true);
        const dataKey = [...selectedRowKeys];
        put('admin/article/batch', {id: dataKey, status: status})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {

        setLoading(true);

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }

        if (selectCategory) {
            params.category_id = selectCategory;
        }

        if (search.length !== 0) {
            params.search = search;
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    };


    /**
     * 返回视图
     */

    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >文章</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>全部文章</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white p-4'>
                <Row>
                    <Col span={10}>
                        <Button
                            onClick={() =>navigate('/article/create')}
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            添加文章
                        </Button></Col>
                    <Col span={14}>
                        <div className='d-flex justify-content-end'>
                            <Space>
                                <Select defaultValue={0} onChange={setSelectCategory}
                                        disabled={category.state !== 'hasValue'}>
                                    <Select.Option value={0}>全部分类</Select.Option>
                                    {category.state === 'hasValue' && category.contents.map((tag) => {
                                        return (
                                            <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                        );
                                    })}
                                </Select>
                                <Button type="primary" className='me-3 ms-1' onClick={() => onPaginationChange({
                                    current: 1,
                                    pageSize: perPage
                                })}>筛选</Button>
                            </Space>
                            <Space>
                                <Input.Search
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={(e) => {
                                        if (e.length !== 0) {
                                            onPaginationChange({current: 1, pageSize: perPage})
                                        }
                                    }}
                                    placeholder="输入关键词搜索"
                                    allowClear
                                    enterButton="搜索"
                                />

                                <Button onClick={() => onPaginationChange({current: 1, pageSize: perPage})}>
                                    重置
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>

                <Divider/>
                <div className='mt-4'>
                    <div className='mb-3'>
                        <Button onClick={() => onUpdate(0)} className='me-2' icon={<ArrowDownOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>隐藏</Button>
                        <Button onClick={() => onUpdate(1)} className='me-2' icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>显示</Button>
                        <Button onClick={showConfirm} className='me-2' icon={<DeleteOutlined/>} disabled={selectedRowKeys.length === 0}
                                size='small'>删除</Button>
                        <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 篇文章` : ''}
                        </span>
                    </div>

                    <Table
                        size="middle"
                        loading={loading}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            size:'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 篇文章`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
export default News;
