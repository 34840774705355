/**
 * Created by devin on 2021/4/21.
 */

import React from 'react'

import {Collapse} from "antd";
const RightMenu = (props) => {
    return (
        <div>
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel header="订单消息" key="1">
                    <p className='text-muted'>暂无订单消息</p>
                </Collapse.Panel>
                <Collapse.Panel header="内部公告" key="2">
                    <p className='text-muted'>暂无内部公告</p>
                </Collapse.Panel>
                <Collapse.Panel header="最新评价" key="3">
                    <p className='text-muted'>暂无任何评价</p>
                </Collapse.Panel>
                <Collapse.Panel header="系统信息" key="4">
                    <p className='text-muted'>暂无升级信息</p>
                </Collapse.Panel>
            </Collapse>,
        </div>
    )
}
export default RightMenu;
