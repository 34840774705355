import React, {useCallback, useEffect, useState} from 'react';
import {Badge, Breadcrumb, Button, Col, Descriptions, Divider, Empty, message, Row, Skeleton, Space, Spin} from 'antd';
import {useNavigate, useParams, withRouter} from 'react-router-dom';
import {useHttps} from "../../hooks";


import {Thumb, Poster} from "../../components";
import moment from "moment";
import {PrinterOutlined} from "@ant-design/icons";


const Print = props => {
	const {put, get, destroy} = useHttps();
	const datas = useParams();
	console.log(datas)
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [img, setImg] = useState('');
	const navigate = useNavigate();
	let drawData= {
		width: 960,
		height: 219,
		backgroundColor: '#fff',
		views: [
			{
				type: 'image',
				url:require('../../assets/t1.png'), //  eslint-disable-line
				top: 0,
				left: 0,
				width: 960,
				height: 219,
			},
			{
				type: 'text',
				content:data?.card_id || '',
				fontSize: 14,
				color: '#000000',
				textAlign: 'center',
				top: 70,
				left: 350,
				width: 200,
				lineNum: 3,
				lineHeight: 20,
				baseLine: 'top',
			},
			{
				type: 'text',
				content:data?.tel || '',
				fontSize: 14,
				color: '#000000',
				textAlign: 'center',
				top: 70,
				left: 650,
				width: 200,
				lineNum: 3,
				lineHeight: 20,
				baseLine: 'top',
			},
			{
				type: 'text',
				content:data? moment(data.created_at).format('YYYY     MM      DD')  : '',
				fontSize: 14,
				color: '#000000',
				textAlign: 'center',
				top: 165,
				left:805,
				width: 200,
				lineNum: 3,
				lineHeight: 20,
				baseLine: 'top',
			},
		],

	}



	//
	// const printOrder = (id) => {
	// 	const el = document.getElementById(id);
	// 	const iframe = document.createElement('IFRAME');
	// 	let doc = null;
	// 	iframe.setAttribute('style', 'border:no; border:0;position:absolute;0px;height:0px;left:500px;top:500px;');
	// 	document.body.appendChild(iframe);
	// 	doc = iframe.contentWindow.document;
	// 	// 引入打印的专有CSS样式，根据实际修改
	// 	// doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');
	// 	doc.write(el.innerHTML);
	// 	doc.close();
	// 	// 获取iframe的焦点，从iframe开始打印
	// 	iframe.contentWindow.focus();
	// 	iframe.contentWindow.print();
	// 	if (navigator.userAgent.indexOf("MSIE") > 0)
	// 	{
	// 		document.body.removeChild(iframe);
	// 	}
	// }



	if (error) {
		return (
			<div className='mx-3'>
				<div className='bg-white p-4'>
					<div>
						<Button className='me-2' onClick={() => navigate(-1)} type="primary">
							返回列表
						</Button>
					</div>
					<Divider/>
					<div className='p-5'>
						<Empty description={errorText} imageStyle={{height: 160}}/>
					</div>
				</div>
			</div>
		);
	}


	/**
	 * 内容加载
	 */
	if (loading) {
		return (
			<div className='mx-3'>
				<div className='bg-white px-4 pt-4' style={{height: 520}}>
					<Space>
						<Skeleton.Button />
						<Skeleton.Button />
						<Skeleton.Button />
					</Space>
					<Skeleton className='mt-5' avatar/>
					<Skeleton className='mt-5'/>
				</div>
			</div>
		)
	}


	return (
		<div className='mx-3'>
			<div className='p-3'>
				<Space align="center">
					<Button className='me-2' onClick={() => navigate(-1)} type="primary">
						返回
					</Button>
					<Button  icon={<PrinterOutlined />}  >  打印</Button>
				</Space>
			</div>


			<div className='position-relative' id='p1' style={{width:960 , height:219, position:'relative'}}>
				<img src={data.sign}  alt={'DD'} style={{position:'absolute' , left:580 , top:80, zIndex:1000, width:180 , height:180}} />
				<img src={img}  alt={'DD'}/>
				<Poster drawData={drawData} success={(res)=>{
					setImg(res)
				}}/>
			</div>


		</div>
	)
}


export default Print;
