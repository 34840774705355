/**
 * Notes: 用户订单
 * User: 李强
 * DateTime: 2021/12/10 14:28
 * Ide: PhpStorm
 */

import React from 'react'

import {Empty} from 'antd';
import {withRouter} from 'react-router-dom'


const UserPoints = (props) => {



    return (
        <div className='bg-white p-4'>
            <Empty />
        </div>
    )
}

export default UserPoints
