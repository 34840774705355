import React, {useEffect, useState} from 'react'
import {Form, Input, Table, Button, Space, InputNumber} from 'antd'
import {AlignRightOutlined} from "@ant-design/icons";
import {priceCheck} from "../../utils/commonfn";

/**
 * @description 合并单元格算法
 * @param {Array} columns 表头数组
 * @param {Array} tableDataList 表格内容数组
 */
const getRowSpan = (columns, tableDataList) => {
    if (tableDataList.length === 0) return false
    const rowSpanConfig = {}
    columns.forEach(item => {
        let point = 0 // 记录用于扩张的单元格index
        const keyName = item.value
        rowSpanConfig[keyName] = []
        tableDataList.forEach((sc, si) => {
            if (!sc[keyName]) return
            // 第一条内容绝逼是展开的，可以直接推
            // 注：每一行占【1】的单元格，所以计算基数为1
            if (si === 0) {
                rowSpanConfig[keyName].push(1)
                return
            }
            // // 对比上一条数据，值相等则触发合并
            if (sc[keyName] === tableDataList[si - 1][keyName]) {
                // eslint-disable-next-line no-plusplus
                rowSpanConfig[keyName][point]++
                rowSpanConfig[keyName].push(0)
            } else {
                rowSpanConfig[keyName].push(1)
                point = si
            }
        })
    })

    return rowSpanConfig
}

/**
 * @description 卡迪儿积算法
 * @param {Array} array 二维数组集合 如[[1, 2, 3], ['a', 'b', 'c']]
 */
const descartes = (array) => {
    if (array.length < 2) return array[0] || []
    return [].reduce.call(array, (col, set) => {
        const res = []
        col.forEach(c => {
            set.forEach(s => {
                const t = [].concat(Array.isArray(c) ? c : [c])
                t.push(s)
                res.push(t)
            })
        })
        return res
    })
}


/**
 * @description sku组合算法
 * @param {Array} sourceData 商品规格传递下来的元数据
 */
const getSkuList = (sourceData) => {
    const data = []
    sourceData.forEach(c => {
        if (c.attr_list && c.attr_list.length > 0) {
            let subSpecData = [];
            c.attr_list.forEach((sc, si) => {
                sc.spec_value = c.value
                subSpecData.push(sc)
            })
            data.push(subSpecData )
        }
    })
    if (!data.length) return false
    return descartes(data)
}


/**
 * @description 获取表格 dataSource 配置
 * @param {Array} sourceData 商品规格传递下来的元数据
 */
const getDataSource = (sourceData) => {
    const skuList = getSkuList(sourceData)
    if (!skuList) return []
    return skuList.map((c, i) => {
        let tmp = {key: i, sku_id: ''  }
        const skuIdArray = []
        if (Array.isArray(c)) {
            c.forEach(sc => {
                tmp[sc.spec_value] = sc.value
                skuIdArray.push(`${sc.spec_id}-${sc.attr_id}`)
                tmp = {...tmp, ...sc}
            })
        } else {
            tmp[c.spec_value] = c.value
            skuIdArray.push(`${c.spec_id}-${c.attr_id}`)
            tmp = {...tmp, ...c}
        }
        tmp.sku_id = skuIdArray.join(':')
        return tmp
    })
}

/**
 * 验证input价格输入
 * @param rule
 * @param value
 * @param callback
 */

const checkPrice = async (rule, value ) => {
    const isValue =  value.replace(/(^\s*)|(\s*$)/g, '');
    if(isValue.length === 0 ){
        await Promise.reject('请输入价格！')
    }
    //判断规格项是否存在
    if (!priceCheck(value)) {
        await Promise.reject('请输入价格！')
    }
    await Promise.resolve()
}
/**
 * @description 获取表格 columns 配置
 * @param {Array} sourceData 商品规格传递下来的元数据
 * @param tableSkuList
 * @param from
 * @param disabled
 */
const getColumns = (sourceData, tableSkuList, from , disabled) => {


    const config = getRowSpan(sourceData, tableSkuList)
    const columns = []
    const concatCol = [
        {
            title: (
                <div>
                    <em style={{color: 'red'}}>* </em>价格（元）
                </div>
            ),
            dataIndex: 'price',
            width: 150,
            ...batchColumn('price', tableSkuList, from, disabled),
            render: (value, row) => {
                return (
                    <Form.Item className='mb-0' name={`${row.sku_id}-price`} rules={[{ required: true, validator:checkPrice,  message: '价格必须填写！'}]}>
                        <Input disabled={disabled} className='w-100' prefix="￥" />
                    </Form.Item>
                );
            },
        },
        {
            title: (
                <div>
                    <em style={{color: 'red'}}>* </em>库存
                </div>
            ),
            dataIndex: 'stock',
            width: 150,
            ...batchColumn('stock', tableSkuList, from , disabled),
            render: (value, row) => (
                <Form.Item className='mb-0'  rules={[{ required: true,  message: '价格必须填写！'}]} name={`${row.sku_id}-stock`}>
                    <InputNumber disabled={disabled} min={0}  className='w-100'/>
                </Form.Item>
            ),
        },
        {
            title: <div>编码</div>,
            dataIndex: 'code',
            width: 150,
            render: (value, row) => (
                <Form.Item noStyle initialValue='' name={`${row.sku_id}-code`}>
                    <Input disabled={disabled}/>
                </Form.Item>
            ),
        },
        {
            title: <div>备注</div>,
            dataIndex: 'remark',
            width: 150,
            render: (value, row) => (
                <Form.Item initialValue=''  noStyle name={`${row.sku_id}-remark`}>
                    <Input disabled={disabled}/>
                </Form.Item>
            ),
        },
    ]

    sourceData.forEach(c => {
        const col = {
            title: c.value,
            dataIndex: c.value,
            onCell: (record, index)=>{
                        return { rowSpan: config[c.value] ? config[c.value][index] : 1 };
            },
        }
        columns.push(col)
    })
    return columns.concat(concatCol)
}


const batchColumn = (dataIndex, tableSkuList, from, disabled) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
        <div style={{padding: 8}}>
            <span>批量{dataIndex === 'price' ? '价格' : '库存'}</span>
            <div className='my-2'>
                {dataIndex === 'price' ?
                    <Form.Item name='batch-price' noStyle>
                        <Input  disabled={disabled}  className='w-100' prefix="￥"/>
                    </Form.Item>
                    :
                    <Form.Item noStyle name='batch-stock'>
                        <InputNumber disabled={disabled}  min={0} className='w-100' />
                    </Form.Item>
                }
            </div>
            <Space>
                <Button
                    block
                    disabled={disabled}
                    type="primary"
                    onClick={() => {
                        confirm()
                        batchUpdate(dataIndex, tableSkuList, from)
                    }}
                    size="small"
                >
                    设置
                </Button>
                <Button  block onClick={() => clearFilters()} size="small">
                    取消
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <AlignRightOutlined/>,
});


const batchUpdate = (dataIndex, tableSkuList, from) => {
    let value = dataIndex === 'price' ? from.getFieldValue('batch-price') : from.getFieldValue('batch-stock');
    if (!value) {
        return
    }

    if(dataIndex === 'price'){
        if (!priceCheck(value)) {
             return;
        }
    }
    for (let i = 0; i < tableSkuList.length; i++) {
        let skuId = tableSkuList[i].sku_id + '-' + dataIndex;
        const reset = {}
        reset[skuId] = value
        from.setFieldsValue(reset)
    }

};

const SkuTable = props => {

    const {tableData, from, skuList, disabled} = props
    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (tableData.length > 0) {
            const tableSkuList = getDataSource(tableData)
            const taleColumns = getColumns(tableData, tableSkuList, from, disabled)
            setColumns(taleColumns)
            setDataSource(tableSkuList)
            skuList(tableSkuList)

        } else {
            // 单规格全部删除，则需清空表格数据
            setDataSource([])
        }
    }, [disabled, from, skuList, tableData])


    return (
        <div className="sku-table">
            {columns && dataSource.length > 0 && (
                <Form.Item className='sku-attr' label="规格明细" extra=' 请设置好规格后在输入规格明细，重新设置规格将使规格明细重置。规格库存是0将不显示'>
                    <Table size="small" columns={columns} dataSource={dataSource} bordered pagination={false}/>
                </Form.Item>
            )}
        </div>
    )
}

export default SkuTable
