/**
 * Notes: 商户列表
 * User: 李强
 * DateTime: 2021/12/13 12:51
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilValueLoadable} from 'recoil';

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Rate,
    Typography,
    Breadcrumb,
    Space, Select, Table, Badge, Popconfirm, DatePicker, Tag, message, Modal, Avatar
} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    PushpinOutlined,
    VerticalAlignBottomOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
    MinusOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'

import {goodsCategory} from "../../store";
import {useHttps} from '../../hooks'
import {subText} from "../../utils/commonfn";
import {Thumb} from "../../components";
const {Text} = Typography;
/*
  用户管理
*/
const Index = (props) => {


    const {put, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(4)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const category = useRecoilValueLoadable(goodsCategory);
    const [search, setSearch] = useState('')
    const [selectCategory, setSelectCategory] = useState(0)
    const [selectDate, setSelectDate] = useState(null)
    const [selectType, setSelectType] = useState(0)
    const [editItem, setEditItem,] = useState(0)
    const [editVisible, setEditVisible] = useState(false)
    const navigate = useNavigate();

    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/shop/goods' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);




    /**
     * 更新列表操作
     * @param params
     * @param ids
     */
    const onUpdate = (params, ids) => {
        setLoading(true);
        params.id = ids;
         put('admin/shop/goods/batch', params)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    message.error(e.message);
                    setLoading(false);
                }
            });
    };


    /**
     * 删除操作
     * @param id
     */
    const onDelete = (id) => {
        setLoading(true);
        destroy('admin/shop/goods/'+ id)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    message.error(e.message);
                    setLoading(false);
                }
            });
    };


    const typeText = (type) => {
        let content = '实体商品';
        switch (type) {
            case 2:
                content = '虚拟商品';
                break;
            case 3:
                content = '外卖订餐';
                break;
            case 4:
                content = '酒店客房';
                break;
            case 5:
                content = '售票卡券';
                break;
            default:
                content = '实体商品';
        }
        return content;
    };

    /**
     * 操作提示信息
     */
    const showConfirm = (text , obj) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要执行{text}操作吗？</div>,
            onOk() {
                onUpdate(obj, selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }
    /**
     * 自定义表格
     */
    const onUpdateItem = (value, item) => {
        setEditVisible(false)
        if (parseInt(value) === item['sort']) {
            return
        }
        onUpdate({sort: value}, [item.id])
    }



    const columns = [
        {
            title: '排序',
            dataIndex: 'sort',
            width: 60,
            sorter: (a, b) => a.sort - b.sort,
            render: (sort, item) => {
                return (
                    <div>
                        {editVisible && editItem === item.id ?
                            <Input
                                autoFocus={editVisible}
                                defaultValue={sort}
                                onBlur={(e) => onUpdateItem(e.target.value, item)}
                                onPressEnter={(e) => onUpdateItem(e.target.value, item)}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setEditItem(item.id)
                                setEditVisible(true)
                            }}>{sort}</div>}
                    </div>
                )
            }
        },
        {
            title: '商品',
            dataIndex: 'title',
            render: (title, item) => {
                return (
                    <Link to={'/goods/details/' + item.id}>
                        <Space>
                            <Thumb
                                width={68}
                                height={68}
                                src={item.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                            />
                            <div>
                                <div className='text-dark mb-2'>{subText(title, 16)}</div>
                                <Space>
                                    <Avatar shape="square"  size={16}  src={item.mch.logo} alt={item.mch.name} />
                                    <span className='text-muted fs-7'>{subText(item.mch.name , 14)}</span>
                                </Space>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '价格',
            dataIndex: 'market_price',
            sorter: (a, b) => a.market_price - b.market_price,
            render: (price) => {
                return (
                    <div>
                        <Text>¥{price}</Text>
                    </div>

                )
            }
        },
        {
            title: '销量',
            dataIndex: 'sales',
            sorter: (a, b) => a.sales - b.sales,
            render: (sales) => {
                return (
                    <div>
                        <Text>{sales}</Text>
                    </div>
                )
            }
        },
        {
            title: '评分',
            dataIndex: 'score',
            sorter: (a, b) => a.score - b.score,
            render: (score) => {
                return (
                    <div>
                        <Rate style={{fontSize:14}} disabled defaultValue={score} />
                    </div>
                )
            }
        },
        {
            title: '商品属性',
            dataIndex: 'type',
            render: (type, item) => {
                return (
                  <div>
                      <Tag className='mb-1' >{typeText(type)}</Tag>
                      {item.is_points &&  <Tag className='mb-1' color='lime' >积分</Tag>}
                      {item.is_level &&  <Tag color="cyan"  className='mb-1' >会员</Tag>}
                  </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status , item) => {
                //state
                return (
                    <div>
                        {status === 2 ?
                            <Badge
                                count={item.state ? '销售中' : '已下架'}
                                style={{backgroundColor: item.state ? '#52c41a' : '#6c757d'}}
                            /> :
                            <Badge
                                count={status === 1 ? '已拒绝' : '待审核'}
                                style={{backgroundColor: status === 1 ? '#f5222d' : '#fa8c16'}}
                            />
                        }
                    </div>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                    <Space>

                        <Button onClick={() => navigate('/goods/edit/' + item.id)} className='text-muted' type="dashed" size='small' icon={<EditOutlined/>}/>
                        <Button onClick={() => navigate('/goods/details/' + item.id)} className='text-muted' type="dashed" size='small' icon={<FileTextOutlined />}/>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete(item.id)}>
                            <Button className='text-muted' type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>

                    </Space>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {

        setLoading(true);

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }


        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        if (selectType !== 0) {
            //判断分组
            if(selectType < 6){
                params.type = selectType;
            }else {
                if(selectType === 6) {
                    params.is_verify = 1
                }
                if(selectType === 7) {
                    params.is_time = 1
                }
                if(selectType === 8) {
                    params.is_level = 1
                }
                if(selectType === 9) {
                    params.is_points = 1
                }
                if(selectType === 10) {
                    params.recommend = 1
                }
            }
        }
        if (status !== 4) {
            params.status = status;
        }

        if (search.length !== 0) {
            params.search = search;
        }

        if (selectCategory) {
            params.category_id = selectCategory;
        }

        window.location.hash = `#${pagination.current}`;
        getData(params);
    }

    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setSelectCategory(0)
        setSelectType(0)
        setStatus(4)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: 15});
    }


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >商品</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>全部商品</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            <div className='bg-white p-4'>
                <Row justify="space-between">
                    <Col>
                        <Button
                            type="primary"
                            icon={<VerticalAlignBottomOutlined />}
                        >
                            导出
                        </Button>
                    </Col>
                    <Col>
                        <Space>
                            <DatePicker.RangePicker value={selectDate} onChange={setSelectDate}/>

                            <Select placeholder={'选择属性'} defaultValue={0} value={selectType} onChange={setSelectType}>
                                <Select.OptGroup label="商品类型">
                                    <Select.Option value={0}>全部类型</Select.Option>
                                    <Select.Option value={1}>实体物品</Select.Option>
                                    <Select.Option value={2}>虚拟商品</Select.Option>
                                    <Select.Option value={3}>外卖订餐</Select.Option>
                                    <Select.Option value={4}>酒店客房</Select.Option>
                                    <Select.Option value={5}>售票卡券</Select.Option>
                                </Select.OptGroup>
                                <Select.OptGroup label="商品规则">
                                    <Select.Option value={6}>核销商品</Select.Option>
                                    <Select.Option value={7}>限时购买</Select.Option>
                                    <Select.Option value={8}>会员商品</Select.Option>
                                    <Select.Option value={9}>积分商品</Select.Option>
                                    <Select.Option value={10}>推荐商品</Select.Option>
                                </Select.OptGroup>
                            </Select>


                            <Select defaultValue={4} value={status} onChange={setStatus}>
                                <Select.Option value={4}>全部状态</Select.Option>
                                <Select.Option value={0}>待审核</Select.Option>
                                <Select.Option value={1}>已拒绝</Select.Option>
                                <Select.Option value={2}>已认证</Select.Option>
                            </Select>


                            <Select  defaultValue={0} value={selectCategory}
                                    onChange={setSelectCategory}
                                    disabled={category.state !== 'hasValue'}>
                                <Select.Option value={0}>全部分类</Select.Option>
                                {category.state === 'hasValue' && category.contents.map((tag) => {
                                    return (
                                        <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                    );
                                })}
                            </Select>

                            <Input.Search
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onSearch={(e) => {
                                    onPaginationChange({current: 1, pageSize: perPage})
                                }}
                                placeholder="输入商品名称搜索"
                                allowClear
                                enterButton="筛选"
                            />
                            <Button onClick={onReset}>
                                重置
                            </Button>
                        </Space>
                    </Col>
                </Row>

                <Divider/>
                <div className='mt-4'>
                    <Space className='mb-3'>
                        <Button icon={<ArrowDownOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                onClick={() => showConfirm('上架', {state: 1})}
                                size='small'>上架</Button>
                        <Button icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                onClick={() => showConfirm('下架', {state: 0})}
                                size='small'>下架</Button>

                        <Button icon={<PushpinOutlined />}
                                disabled={selectedRowKeys.length === 0}
                                onClick={() => showConfirm('推荐', {recommend: 1})}
                                size='small'>设为推荐</Button>
                        <Button icon={<MinusOutlined />}
                                disabled={selectedRowKeys.length === 0}
                                onClick={() => showConfirm('取消推荐', {recommend: 0})}
                                size='small'>取消推荐</Button>
                        <span className='text-muted'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 个商品` : ''}
                        </span>
                    </Space>
                    <Table
                        size="middle"
                        loading={loading}
                        rowSelection={{
                            selectedRowKeys,
                            onChange:setSelectedRowKeys,
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            size:'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 个商品`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Index
