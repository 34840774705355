/**
 * Created by devin on 2021/4/21.
 */

import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilValue} from 'recoil';

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Select,
    List,
    Badge,
    Avatar,
    Card,
    Modal,
    message,
    Space, Breadcrumb, Typography
} from 'antd';
import {
    DeleteOutlined,
    PlusOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {userInfo} from "../../store";
import {useHttps} from "../../hooks";
import {useNavigate} from "react-router-dom";

const {Title} = Typography;

const News = (props) => {
    const user = useRecoilValue(userInfo)
    const {get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [status, setStatus] = useState(4)
    const [search, setSearch] = useState('')
    const navigate = useNavigate();
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/staff',params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])




    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);





    /**
     * 删除列表操作
     * @param id
     */
    const onDelete = (id) => {
        setLoading(true);
        destroy('admin/staff/'+ id )
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };


    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }
        if (status !== 4) {
            params.status= status;
        }
        if (search.length !== 0) {
            params.mobile = search;
        }
        window.location.hash = `#${pagination.current}`;

        getData(params);
    }


    /**
     * 操作提示信息
     */
    const showConfirm = (id) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(id)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }


    /**
     * 返回视图
     */

    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >员工</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>全部员工</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='p-2'>
                <Row>
                    <Col span={10}>
                        <Button
                            onClick={() => navigate('/staff/create')}
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            添加员工
                        </Button></Col>
                    <Col span={14}>
                        <div className='d-flex justify-content-end'>
                            <Space>
                                <Select defaultValue={4} value={status} onChange={setStatus} >
                                    <Select.Option value={4}>全部状态</Select.Option>
                                    <Select.Option value={0}>待职中</Select.Option>
                                    <Select.Option value={1}>已离职</Select.Option>
                                    <Select.Option value={2}>在职中</Select.Option>
                                    <Select.Option value={3}>休假中</Select.Option>
                                </Select>
                                <Button type="primary" className='me-3 ms-1' onClick={() => onPaginationChange({
                                    current: 1,
                                    pageSize: perPage
                                })}>筛选</Button>
                            </Space>
                            <Space>
                                <Input.Search
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={(e) => {
                                        if (e.length !== 0) {
                                            onPaginationChange({current: 1, pageSize: perPage})
                                        }
                                    }}
                                    placeholder="输入手机号搜索"
                                    allowClear
                                    enterButton="搜索"
                                />

                                <Button onClick={() => onPaginationChange({current: 1, pageSize: perPage})}>
                                    重置
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <List
                        loading={loading}
                        grid={{ gutter: 16, column: 4}}
                        dataSource={data}
                        pagination={{
                            defaultCurrent: 1 ,
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 名员工`,
                            pageSize: perPage,
                            onChange: onPaginationChange
                        }}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <Card
                                    actions={[
                                        <EditOutlined key="edit"  onClick={()=> navigate('/staff/edit/' + item.id)} />,
                                        <DeleteOutlined key="ellipsis" style={user.id === parseInt(item.id) ? { color: '#dee2e6'} : null}  onClick={()=>user.id === parseInt(item.id) ? null : showConfirm([item.id])}/>,
                                    ]}
                                >
                                    <div onClick={()=> navigate('/staff/edit/' + item.id)} className='cursor d-flex justify-content-between'>
                                        <Space>
                                            <Avatar  size={50} src={item.avatar}/>
                                            <div>
                                                <Title level={5} className='mb-1'>{item.name}</Title>
                                                <div className='fs-7 text-muted'>{item.mobile}</div>
                                            </div>
                                        </Space>
                                        <div>
                                            {item.status === 0 && <Badge className='ms-3' count={'待职中'}
                                                                         style={{backgroundColor: '#fa8c16'}}/>}
                                            {item.status === 1 && <Badge className='ms-3' count={'已离职'}
                                                                         style={{backgroundColor: '#dc3545'}}/>}
                                            {item.status === 2 && <Badge className='ms-3' count={'在职中'}
                                                                         style={{backgroundColor: '#52c41a'}}/>}
                                            {item.status === 3 && <Badge className='ms-3' count={'休假中'}
                                                                         style={{backgroundColor: '#0dcaf0'}}/>}

                                        </div>
                                    </div>

                                </Card>
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
export default News;
