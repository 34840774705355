/**
 * Notes: 商户选择页面
 * User: 李强
 * DateTime: 2021/10/5 9:37 上午
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useImperativeHandle, useCallback} from 'react'
import {
    Button,
    Col,
    Modal,
    Row,
    Typography,
    Table, Input, Space, Select
} from "antd";


import {Icon} from "../../Config";
import {useHttps} from "../../hooks";


const {Text} = Typography
const MchSelect = (props) => {
    const {type, multiple, selectDataChange} = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectData, setSelectData] = useState([])
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(5)
    const {get} = useHttps();

    const getData = useCallback( (params) => {
        get('admin/mch' , params, true).then((res) =>{
            if (res.mounted) {
                setLoading(false);
                setCurrent(res.meta.current_page)
                setTotal(res.meta.total)
                setPerPage(res.meta.per_page)
                setData(res.data);
            }
        }).catch((e) => {
            if (e.mounted) {
                setLoading(false);
            }
        })
    },[get])




    useEffect(() => {
        const init = {page: 1, per_page: 5}
         if(type){ init.type = type}
         getData(init)
    }, [getData, type]);


    useImperativeHandle(props.refs, () => ({
        // changeVal 就是暴露给父组件的方法
        onOpen: () => {
            setIsModalVisible(true);
        },
    }));

    /**
     * 关闭模态框 返回数据
     */

    const handleOk = () => {
        selectDataChange(selectData);
        setSelectData([])
        setIsModalVisible(false);
    };

    /**
     * 关闭模态框
     */
    const handleCancel = () => {
        setSelectData([])
        setIsModalVisible(false);
    };


    /**
     * 判断当前是否选中
     * @param item
     * @returns {boolean}
     */
    const isSelect = (item) => {
        const select = selectData.findIndex((value) => value.id === item.id);
        return select > -1
    }

    const columns = [
        {
            title: '商户名称',
            dataIndex: 'name',
            render: (name, item) => {
                return (

                        <Space>
                            <img
                                className='rounded'
                                style={{width: 38, height: 38}}
                                src={!item.logo || item.logo.length === 0 ? Icon.emptyIcon : item.logo}
                                alt={name}/>
                            <Text>{name}</Text>
                        </Space>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                     <Button onClick={() => onSelectChange(item)}  type={isSelect(item) ? 'primary' : "dashed"} size='small'> {isSelect(item) ? '取消' : "选择"} </Button>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {

        setLoading(true);

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
         getData(params);
    }


    /**
     * 获取已选择的数据
     * @param item
     */
    const onSelectChange = (item) => {
        const select = selectData.findIndex((value) => value.id === item.id);
        let dataSource = [...selectData];
        if (select > -1) {
            dataSource.splice(select, 1);
        } else {
            //是否为多选
            if (!multiple) {
                dataSource = [];
            }
            //生成唯一索引
            const  key = {key: Date.now().toString()}
            const newItem = {...item , ...key}
            dataSource.push(newItem);
        }
        setSelectData(dataSource)
    }

    return(
        <Modal width={880} title={ `商户选择器 (${selectData.length})`} centered={true} visible={isModalVisible} onOk={handleOk}
               onCancel={handleCancel}>
            <div className='mb-3 bg-light'>
                <Row>
                    <Col span={8}>
                        <div className='me-3'>
                        <Select disabled={type} className='w-100' defaultValue={type}>
                            <Select.Option value={0}>全部商户</Select.Option>
                            <Select.Option value={1}>企业公司</Select.Option>
                            <Select.Option value={2}>政府机关</Select.Option>
                            <Select.Option value={3}>事业单位</Select.Option>
                            <Select.Option value={4}>个体商户</Select.Option>
                        </Select>
                        </div>
                    </Col>

                    <Col span={16}>
                        <Input.Search placeholder="输入关键词搜索"  enterButton />
                    </Col>
                </Row>
            </div>
            <Table
                size="small"
                loading={loading}
                columns={columns}
                dataSource={data}
              //  showHeader={false}
                pagination={{
                    size:'default',
                    total: total,
                    current: current,
                    showTotal: total => `已选择 ${selectData.length} 个商户`,
                    pageSize: perPage,
                }}
                onChange={onPaginationChange}
                rowKey={(record) => {
                    return record.id
                }}
            />
        </Modal>
    )
}

export default MchSelect;
