/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useEffect, useState} from 'react'

import {
    Badge,
    Breadcrumb,
    Button,
    Col,
    Descriptions,
    Divider,
    Empty,
    message,
    Row,
    Skeleton,
    Space,
    Spin,
    Typography
} from 'antd';
import {useNavigate, useParams} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Poster, Thumb} from "../../components";
import {PrinterOutlined} from "@ant-design/icons";
import moment from "moment";

const {Text} = Typography;

const AuthEdit = (props) => {
    const {put, get, destroy} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const navigate = useNavigate();
    const [img, setImg] = useState('');
    const [imgA, setImgA] = useState('');
    const [imgB, setImgB] = useState('');
    const [imgC, setImgC] = useState('');
    useEffect(() => {
        get('admin/user/auth/' + id, null,  true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);



    /**
     * 更新用户资料
     * @param params
     */
    const onUpdate = (params) => {
        setSpin(true)
        put('admin/user/auth/' + id, params)
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('用户已更新成功！');
                    setData(res.data)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 更新用户资料
     * @param params
     */
    const onDelete = () => {
        setSpin(true)
        destroy('admin/user/auth/' + id)
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('用户已删除！');
                    navigate(-1)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }


    const statusText = () => {
        let content = {};
        switch (data.status) {
            case 0:
                content.color = '#f5222d';
                content.text = '待报道';
                break;
            case 1:
                content.color = '#fa8c16';
                content.text = '待审核';
                break;
            case 2:
                content.color = '#52c41a';
                content.text = '已审核';
                break;
            case 3:
                content.color = '#6c757d';
                content.text = '其他';
                break;
            default:
                content.color = '#fa8c16';
                content.text = '待审核';
        }
        return content;
    };

    const cancelText = () => {
        let content = {};
        switch (data.cancel) {
            case 0:
                content.color = '#f5222d';
                content.text = '正常';
                break;
            case 1:
                content.color = '#fa8c16';
                content.text = '待转出';
                break;
            case 2:
                content.color = '#52c41a';
                content.text = '已转出';
                break;
            default:
                content.color = '#fa8c16';
                content.text = '待审核';
        }
        return content;
    };

    const printOrder = (id) => {
        const el = document.getElementById(id);
        const iframe = document.createElement('IFRAME');
        let doc = null;
        iframe.setAttribute('style', 'border:no; border:0;position:absolute;0px;height:0px;left:500px;top:500px;');
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        // doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');
        doc.write(el.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        if (navigator.userAgent.indexOf("MSIE") > 0)
        {
            document.body.removeChild(iframe);
        }
    }

   const getAge = (birthday) => {
        let birthdays = new Date(birthday.replace(/-/g, "/"));
        let d = new Date();
       return d.getFullYear() -
            birthdays.getFullYear() -
            (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() === birthdays.getMonth() &&
                d.getDate() < birthdays.getDate())
                ? 1
                : 0);
    }

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>认证信息</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>认证信息</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4 pt-4' style={{height: 520}}>
                    <Space>
                        <Skeleton.Button />
                        <Skeleton.Button />
                        <Skeleton.Button />
                    </Space>
                    <Skeleton className='mt-5' avatar/>
                    <Skeleton className='mt-5'/>
                </div>
            </div>
        )
    }


    return (
        <div className='mx-3'>
            <Spin size="large" spinning={spin}>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>认证信息</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item  className='text-primary'>详情</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white p-4'>
                <Row justify="space-between">
                    <Col>
                        <Space align="center">
                            <Button className='me-2' type="primary"  onClick={() => navigate(-1)}>
                                返回
                            </Button>
                            <Button  disabled={data.status === 0}  onClick={()=>onUpdate({status:0})} className='me-2' type='primary' danger>
                                撤回报道
                            </Button>
                            <Button  disabled={data.status === 2}  onClick={()=>onUpdate({status:2})}>
                                通过申请
                            </Button>
                            <Button  disabled={data.cancel !== 1}  onClick={()=>onUpdate({cancel:2})}>
                                同意转出
                            </Button>
                            <Button  disabled={data.cancel !== 1}  onClick={()=>onUpdate({cancel:0})}>
                                拒绝转出
                            </Button>
                            <Button danger  onClick={()=>onDelete()}>
                              删除
                            </Button>
                        </Space>
                    </Col>
                    <Col >

                        <Space align="center">
                            <Button disabled={!data.sign}  icon={<PrinterOutlined />} onClick={()=>  printOrder('p1')}>  打印告知书</Button>
                            <Button disabled={!data.cancel_group}  icon={<PrinterOutlined />}  onClick={()=>  printOrder('p4')}>  打印介绍信</Button>
                        </Space>
                    </Col>
                </Row>

                <Divider/>
                <div className='mt-4'>
                    <Descriptions title={data.group_id ?  '团员编号:' + data.group_id : '用户信息' }>
                        <Descriptions.Item label="用户昵称">

                            <Space size={5}>
                                <img
                                        className='me-2 rounded-circle'
                                        style={{width: 22, height: 22}}
                                        src={data.user.avatar}
                                        alt={data.user.nickname}/>
                                <Text className='me-3'>{data.user.nickname}</Text>

                                {data.cancel ===0 ?  <Badge
                                    count={statusText().text}
                                    style={{backgroundColor: statusText().color}}
                                /> :
                                    <Badge
                                        count={cancelText().text}
                                        style={{backgroundColor: cancelText().color}}
                                    />
                                }
                                {data.cancel ===2 && <div>
                                    第{data.cancel_id}位转出团员
                                </div>}
                            </Space>
                        </Descriptions.Item>

                        <Descriptions.Item label="手机号">{data.user.mobile}</Descriptions.Item>
                        <Descriptions.Item label="申请时间">{data.created_at}</Descriptions.Item>
                        {data.group_id &&<Descriptions.Item label="团员编号">{data.group_id}</Descriptions.Item>}
                    </Descriptions>
                    {data.cancel !== 0 && <div>
                        <Divider style={{ marginBottom: 32 }} />
                        <Descriptions title="转出信息">
                            <Descriptions.Item label="转出团组织">{data.cancel_group}</Descriptions.Item>
                            <Descriptions.Item label="转出理由">{data.cancel_explain}</Descriptions.Item>
                            {data.cancel === 2 &&<Descriptions.Item label="转出时间">{data.cancel_date}</Descriptions.Item>}
                        </Descriptions>
                    </div> }

                    <Divider style={{ marginBottom: 32 }} />
                    <Descriptions title="申请信息">
                        <Descriptions.Item label="真实姓名">{data.name}</Descriptions.Item>
                        <Descriptions.Item label="性别">{data.gender === 1 ?'男':'女'}</Descriptions.Item>
                        <Descriptions.Item label="出生日期">{moment(data.birth).format('YYYY-MM-DD')}</Descriptions.Item>
                        <Descriptions.Item label="毕业学校">{data.school  ? data.school:'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="专业班级">{data.classes  ? data.classes:'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="联系电话">{data.tel}</Descriptions.Item>
                        <Descriptions.Item label="身份证号">{data.card_id ? data.card_id : '未填写'}</Descriptions.Item>

                        <Descriptions.Item label="是否留在大连">{data.is_dalian === 1 ?'是':'否'}</Descriptions.Item>
                        <Descriptions.Item label="是否能做到下列承诺">{data.is_promise === 1 ?'是':'否'}</Descriptions.Item>
                        <Descriptions.Item label="是否能线下报道">{data.is_live === 1 ?'是':'否'}</Descriptions.Item>
                        <Descriptions.Item label="“智慧团建”线上转接是否已经申请并且转出单位已审批">{data.is_wisdom === 1 ?'是':'否'}</Descriptions.Item>
                        <Descriptions.Item label="团组织名称">{data.group_name  ? data.group_name :'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="政治面貌">{data.politics ? data.politics :'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="是否需要党关系转接">{data.is_transfer === 1 ? '是' :'否'}</Descriptions.Item>
                        <Descriptions.Item label="民族">{data.minority  ? data.minority  :'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="现阶段状态">{data.works  ? data.works  :'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="单位名称">{data.company  ? data.company  :'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="考取意向">{data.ideal  ? data.ideal  :'未填写'}</Descriptions.Item>
                        <Descriptions.Item label="考取学院">{data.ideal_school  ? data.ideal_school  :'未填写'}</Descriptions.Item>

                        <Descriptions.Item label="近期免冠照片">
                        {data.photo && <div className='d-flex flex-wrap'>
                            {data.photo.map((tag, index) => {
                                return (
                                    <Thumb
                                        key={index}
                                        width={100}
                                        height={100}
                                        src={tag.url + "?x-oss-process=image/resize,w_200,h_200,m_fill,limit_0"}
                                        preview={tag.url}
                                    />
                                );
                            })}
                        </div>
                        }
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider/>
                    <Descriptions title="报道信息">
                        <Descriptions.Item label="紧急联系人姓名">{data.sos_name ? data.sos_name : '未填写'}</Descriptions.Item>
                        <Descriptions.Item label="紧急联系人电话">{data.sos_tel ? data.sos_tel : '未填写'}</Descriptions.Item>
                        <Descriptions.Item label="紧急联系人关系">{data.sos_relation ? data.sos_relation : '未填写'}</Descriptions.Item>
                        <Descriptions.Item label="毕业年份">{data.graduation?  data.graduation : '未填写'}</Descriptions.Item>
                        <Descriptions.Item label="介绍信照片">
                            {data.letter_files && <div className='d-flex flex-wrap'>
                                {data.letter_files.map((tag, index) => {
                                    return (
                                        <div className='ms-2'>
                                            <Thumb

                                                key={index}
                                                width={100}
                                                height={100}
                                                src={tag.url + "?x-oss-process=image/resize,w_200,h_200,m_fill,limit_0"}
                                                preview={tag.url}
                                            /></div>
                                    );
                                })}
                            </div>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="窗口合影照">
                            {data.files && <div className='d-flex flex-wrap'>
                                {data.files.map((tag, index) => {
                                    return (
                                        <div className='ms-2'>
                                        <Thumb

                                            key={index}
                                            width={100}
                                            height={100}
                                            src={tag.url + "?x-oss-process=image/resize,w_200,h_200,m_fill,limit_0"}
                                            preview={tag.url}
                                        /></div>
                                    );
                                })}
                            </div>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="签名">
                                        <div className='ms-2'>
                                            <Thumb
                                                width={100}
                                                height={100}
                                                src={data.sign}
                                                preview={data.sign}
                                            /></div>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>





            </Spin>
            <div className='position-relative' id='p1' style={{width:960 , height:219, position:'relative', display:'none'}}>
                <img src={data.sign}  alt={'DD'} style={{position:'absolute' , left:580 , top:80, zIndex:1000, width:180 , height:180}} />
                <img src={img}  alt={'DD'}/>
                <Poster drawData={{
                    width: 960,
                    height: 219,
                    backgroundColor: '#fff',
                    views: [
                        {
                            type: 'image',
                            url:require('../../assets/p-1.png'), //  eslint-disable-line
                            top: 0,
                            left: 0,
                            width: 960,
                            height: 219,
                        },
                        {
                            type: 'text',
                            content:data?.card_id || '',
                            fontSize: 14,
                            color: '#000000',
                            textAlign: 'center',
                            top: 70,
                            left: 350,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data?.tel || '',
                            fontSize: 14,
                            color: '#000000',
                            textAlign: 'center',
                            top: 70,
                            left: 650,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data? moment(data.created_at).format('YYYY     MM      DD')  : '',
                            fontSize: 14,
                            color: '#000000',
                            textAlign: 'center',
                            top: 165,
                            left:805,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                    ],
                }} success={(res)=>{
                    setImg(res)
                }}/>
            </div>

            <div className='position-relative' id='p4' style={{width:1024 , height:1499, position:'relative', display:'none'}}>
                <img src={imgC}  alt={'DD'}/>
                <Poster drawData={{
                    width: 1024,
                    height: 1499,
                    backgroundColor: '#fff',
                    views: [
                        {
                            type: 'image',
                            url:require('../../assets/mail.jpg'), //  eslint-disable-line
                            top: 0,
                            left: 0,
                            width: 960,
                            height: 1499,
                        },
                        {
                            type: 'text',
                            content:data?.name || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 75,
                            left: 240,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data?.cancel_group || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 120,
                            left: 635,
                            width: 300,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },

                        {
                            type: 'text',
                            content:data? moment(data.created_at).format('YYYY        MM         DD')  : '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 230,
                            left:665,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data?.cancel_group || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 465,
                            left: 250,
                            width: 300,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data?.name || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 530,
                            left: 170,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data && data.birth ? getAge(data.birth) : '',
                            fontSize: 24,
                            color: '#000000',
                            textAlign: 'center',
                            top: 525,
                            left: 500,
                            width: 300,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data?.minority || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 530,
                            left: 680,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },

                        {
                            type: 'text',
                            content:data?.card_id || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 580,
                            left: 600,
                            width: 300,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data?.cancel_group || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 615,
                            left: 650,
                            width: 300,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data? moment(data.created_at).format('YYYY     MM      DD')  : '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 825,
                            left:675,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data? data.tel: '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 875,
                            left:505,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                        {
                            type: 'text',
                            content:data?.name || '',
                            fontSize: 20,
                            color: '#000000',
                            textAlign: 'center',
                            top: 1215,
                            left: 140,
                            width: 200,
                            lineNum: 3,
                            lineHeight: 20,
                            baseLine: 'top',
                        },
                    ],
                }} success={(res)=>{
                    setImgC(res)
                }}/>
            </div>
        </div>
    )
}

export default AuthEdit
