/**
 * Created by devin on 2021/4/21.
 */

import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilValueLoadable} from 'recoil';

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Select,
    Avatar,
    List,
    Typography,
    Badge,
    Modal,
    message,
    Checkbox,
    Space, Breadcrumb, Card
} from 'antd';
import {
    CheckCircleOutlined,
    MinusCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined, EllipsisOutlined, EditOutlined
} from '@ant-design/icons';

import {Link, useNavigate} from 'react-router-dom'
import {blogCategory} from "../../store";
import {useHttps} from "../../hooks";
import moment from "moment";
import {Thumb} from "../../components";
import {subText} from "../../utils/commonfn";

const {Text} = Typography;

const Activity = (props) => {
    const {put, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(16)
    const [selectCategory, setSelectCategory] = useState(0)
    const [status, setStatus] = useState(3)
    const [search, setSearch] = useState('')
    const [selectDate, setSelectDate] = useState(null)
    const category = useRecoilValueLoadable(blogCategory);
    const navigate = useNavigate();
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/activity',params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])




    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 16});
    }, [getData]);


    /**
     * 删除列表操作
     * @param ids
     */
    const onDelete = (ids) => {
        setLoading(true);
        destroy('admin/activity/batch', {id: ids})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };

    /**
     * 更新列表操作
     * @param {Array} keys  数组id
     * @param {int} status
     */
    const onUpdate = (status) => {
        setLoading(true);
        put('admin/activity/batch', {id: selectedRowKeys, status: status})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };

    /**
     * 表格操作触发
     * @param pagination
     * @param pageSize
     */
    const onPaginationChange = (pagination, pageSize) => {

        setLoading(true);

        const params = {
            page: pagination,
            per_page: pageSize,
        }

        if (selectCategory) {
            params.category_id = selectCategory;
        }
        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }

        if (status !== 9) {
            params.status = status;
        }

        if (search.length !== 0) {
            params.search = search;
        }
        window.location.hash = `#${pagination}`;
        getData(params);
    }


    /**
     * 操作提示信息
     */
    const showConfirm = (ids) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(ids)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 选择列表
     * @param key
     */
    const onSelectChange = (key) => {
        let dataSource = [...selectedRowKeys];
       const index = selectedRowKeys.findIndex(id => id === key)
        if(index >= 0) {
            dataSource.splice(index, 1)
        }else{
            dataSource.push(key)
       }
        setSelectedRowKeys(dataSource)


    };

    /**
     * 全选处理
     * @param e
     */
    const onAllChange = (e) => {
        const checked = e.target.checked;
        const checkedData = []
        if(checked){
            data.forEach(item => {
                checkedData.push(item.id)
            })
        }
      setSelectedRowKeys(checkedData)
    }


    /**
     * 判断列表项是否选中
     * @param id
     * @returns {*}
     */
    const isCheckbox = (id) => {
        return selectedRowKeys.find(val => val === id) != null ;
    }


    /**
     * 重置
     */
    const onReset = () => {
        setSelectCategory(0)
        setStatus(3)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
         setSelectDate(null)
        getData({page: 1, per_page: 16});
    }


    /**
     * 返回视图
     */

    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >活动</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>活动列表</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='p-2'>
                <Row justify="space-between" align="middle">
                    <Col>
                            <Space>
                                <Checkbox onChange={onAllChange}>全选</Checkbox>
                                <Button onClick={() => onUpdate(1)}  icon={<MinusCircleOutlined />}
                                        disabled={selectedRowKeys.length === 0}
                                        size='small'>拒绝</Button>
                                <Button onClick={() => onUpdate(2)} icon={<CheckCircleOutlined />}
                                        disabled={selectedRowKeys.length === 0}
                                        size='small'>通过</Button>
                                <Button onClick={()=>showConfirm(selectedRowKeys)} icon={<DeleteOutlined/>} disabled={selectedRowKeys.length === 0}
                                        size='small'>删除</Button>
                                <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 篇活动` : ''}
                        </span>
                            </Space>
                      </Col>
                    <Col>
                        <Space>
                                <Select style={{width: 160}} value={status} onChange={setStatus}>
                                            <Select.Option  value={3}>全部状态</Select.Option>
                                            <Select.Option  value={0}>待审核</Select.Option>
                                            <Select.Option  value={1}>已拒绝</Select.Option>
                                            <Select.Option  value={2}>已通过</Select.Option>
                                </Select>

                                <Select style={{width: 160}} value={selectCategory} onChange={setSelectCategory}
                                        disabled={category.state !== 'hasValue'}>
                                    <Select.Option value={9}>全部分类</Select.Option>
                                    <Select.Option value={0}>公益活动</Select.Option>
                                    <Select.Option value={1}>线上活动</Select.Option>

                                </Select>
                            <Space>
                                <Input.Search
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={(e) => {
                                        onPaginationChange({current: 1, pageSize: perPage})
                                    }}
                                    placeholder="输入关键词搜索"
                                    allowClear
                                    enterButton="筛选"
                                />

                                <Button onClick={onReset}>
                                    重置
                                </Button>
                            </Space>
                            </Space>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <div>
                        <List
                            loading={loading}
                            grid={{ gutter: 16, column: 4}}
                            dataSource={data}
                            pagination={{
                                defaultCurrent: 1 ,
                                total: total,
                                current: current,
                                showTotal: total => `总计 ${total} 项活动`,
                                pageSize: perPage,
                                onChange: onPaginationChange
                            }}

                            renderItem={item => (
                                <List.Item key={item.id}>
                                    <Card
                                        actions={[
                                            <EditOutlined key="edit" onClick={()=> navigate('/activity/edit/' + item.id)} />,
                                            <DeleteOutlined key="ellipsis"  onClick={()=>showConfirm([item.id])}/>,
                                            <EllipsisOutlined key="ellipsis"  onClick={()=> navigate('/activity/details/' + item.id)}  />,
                                        ]}
                                        cover={
                                        <div className='bg-dark position-relative'>
                                            <div className='position-absolute bottom-0 end-0 text-white me-2 mb-1 ' style={{zIndex:100}}><span className='text-white'>{item.apply_count} / {item.total}</span></div>
                                            <div className='opacity-75'>
                                            <Thumb onClick={()=> navigate('/activity/details/' + item.id)}
                                                   rounded='rounded-0'
                                                   src={item.thumb + "?x-oss-process=image/resize,w_500,h_280,m_fill,limit_0"}
                                            />
                                            </div>
                                        </div>
                                        }
                                    >
                                        <Card.Meta title={item.title}  />
                                        <div className='d-flex justify-content-between position-absolute top-0 start-0 w-100 p-3'>
                                                <Space>
                                                    <Avatar src={item.mch.logo} />
                                                    <Text className='text-white'>{subText(item.mch.name, 10)}</Text>
                                                    {item.status === 0 &&  <Badge count="待审核" color="yellow" /> }
                                                    {item.status === 1 &&  <Badge count="已拒绝" color="red" /> }
                                                    {item.status === 2 &&  <Badge count="已拒绝" color="green" /> }

                                                </Space>
                                                <Checkbox checked={isCheckbox(item.id)} onChange={() => onSelectChange(item.id)} />

                                        </div>
                                        <div className='mt-3'>
                                            <Link to={ '/activity/edit/' + item.id}>
                                            <div><Space><span className='text-dark'>活动地点:</span> <Text className='text-muted'>{subText(item.address, 16)}</Text></Space></div>
                                            <div className='mt-2'><Space><span className='text-dark'>活动时间:</span> <Text className='text-muted'>{moment(item.activity_start_date).format('YYYY-MM-DD HH:mm')}</Text></Space></div>
                                            </Link>
                                        </div>
                                        <div className='mt-3' style={{height:30}}>
                                            {item.apply_user.length !==0 && <Avatar.Group
                                                maxCount={7}
                                                maxPopoverTrigger="click"
                                                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                                            >
                                                {item.apply_user.map((tag, index) => {
                                                    return (
                                                        <Avatar key={index} src={tag.avatar} />
                                                    );
                                                })
                                                }
                                            </Avatar.Group>}
                                            {item.apply_user.length ===0 && <Text className='text-muted'>暂无任何人报名</Text> }
                                        </div>

                                    </Card>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Activity;
