/**
 * Created by devin on 2021/4/23.
 */

import React, {useRef, useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Button , Typography} from "antd";
import {CloseCircleFilled, DiffFilled, DownloadOutlined} from "@ant-design/icons";
import 'react-quill/dist/quill.snow.css';
import {Attachment} from "../../components";


const FileSelect = (props) => {
    const {onChange , list , disabled} = props
    const [file, setFile] = useState([])
    const selectRef = useRef();

    useEffect(() => {
       setFile(list);
    }, [list]);


    /**
     * 附加图片处理
     * @param item
     */
    const fileInsert = (item) => {
        const dataSource = [...item, ...file];
        setFile(dataSource)
        onChange(dataSource)
    }

    /**
     * 删除文件处理
     * @param index
     */
    const fileDelete = (index) => {
        const dataSource = [...file];
        dataSource.splice(index, 1)
        setFile(dataSource)
        onChange(dataSource)
    }



    return (
        <div>
                {file.length !== 0 && <div className='d-flex flex-wrap'>
                    {file.map((tag, index) => {
                        return (
                            <div
                                key={index}
                                className="d-flex justify-content-between align-content-center align-items-center p-2 bg-light me-2 mb-2"
                                style={{width: 200}}>
                                <div className="w-75 d-flex align-items-center">
                                    <DiffFilled className="text-success font-size-2 me-2"/>
                                    <Typography.Text className="d-block font-size-0"
                                          ellipsis>{tag.name}
                                    </Typography.Text>
                                </div>
                                {!disabled &&  <CloseCircleFilled onClick={()=>fileDelete(index)}/> }
                            </div>
                        );
                    })
                    }
                </div>
                }
                <Button disabled={disabled} onClick={() =>  selectRef.current.onOpen(3 ,true) } icon={<DownloadOutlined/>}>
                    选择文件
                </Button>
              <Attachment refs={selectRef} onChange={fileInsert}/>
        </div>
    )

}

FileSelect.propTypes = {
    disabled: PropTypes.bool,
    list:PropTypes.array,
    onChange:PropTypes.func,
};


FileSelect.defaultProps = {
    disabled:false
};

export default FileSelect;
