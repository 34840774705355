/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    PageHeader,
    Divider,
    Spin,
    Row,
    Col,
    Table,
    Descriptions,

    Space, Empty, Popconfirm, Skeleton, Typography, message, Statistic, Breadcrumb, Tag, Avatar
} from 'antd';
import {Link, useNavigate, useParams,} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Thumb} from "../../components";
const {Text, Title} = Typography;



const OrderGoodsDetails = (props) => {
    const {post, get} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [verify, setVerify] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const printRef = useRef() ;
    const navigate = useNavigate();


    useEffect(() => {
        get('admin/shop/order/goods/' + id, null,  true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setVerify([])
                   // setGoodsData(res.data.order_goods)
                   // setRemark(res.data.remark)
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);


    /**
     * 更新用户资料
     */
    const onRefund = () => {
        setSpin(true)
        post('admin/shop/order/goods/refund/' + id)
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('退款提交成功！');
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 商品状态
     * @returns {{}}
     */
    const statusText = () => {
        let content = {};
        switch (data.status) {
            case 1:
                content.color = '#0d6efd';
                content.text = '待确认';
                break;
            case 2:
                content.color = '#52c41a';
                content.text = '已完成';
                break;
            default:
                content.color = '#fa8c16';
                content.text = '待付款';
        }
        return content;
    };

    /**
     * 商品类型
     * @param type
     * @returns {string}
     */
    const typeText = (type) => {
        let content = '实体商品';
        switch (type) {
            case 2:
                content = '虚拟商品';
                break;
            case 3:
                content = '外卖订餐';
                break;
            case 4:
                content = '酒店客房';
                break;
            case 5:
                content = '售票卡券';
                break;
            default:
                content = '实体商品';
        }
        return content;
    };




const printOrder = (id) => {
  //  const el = document.getElementById(id);
    const iframe = document.createElement('IFRAME');
    let doc = null;

    iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
    document.body.appendChild(iframe);
    doc = iframe.contentWindow.document;
    // 引入打印的专有CSS样式，根据实际修改
    doc.write('<LINK rel="stylesheet" href="https://tbbdev.effects.cn/css/print.css">');
    //el.innerHTML
    doc.write('打印模式待确定！！');
    doc.close();
    // 获取iframe的焦点，从iframe开始打印
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    setTimeout(() => {
        document.body.removeChild(iframe);
    }, 500);
}


    const columns = [
        {
            title: '核销日期',
            dataIndex: 'title',
            render: (title, item) => {
                return (
                    <Link to={'/order/user/' + item.id}>
                        <Space>
                            <img
                                className='rounded'
                                style={{width: 52, height: 52}}
                                src={item.thumb + '?x-oss-process=image/resize,w_150,h_150,m_fill,limit_0'}
                                alt={title}/>
                            <div>
                                <Text>{title}</Text>
                                <div className='text-muted mt-1 fs-7'>规格：默认</div>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '核销次数',
            dataIndex: 'real_price',
        },
        {
            title: '核销人',
            dataIndex: 'total',
            render: (total) => {
                return (
                    <Text> x{total}</Text>
                )
            }
        },
    ];


    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>订单商品</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>订单详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>订单商品</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>订单详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4' style={{height: 520}}>
                    <Skeleton className='mt-5'/>
                    <Skeleton className='mt-5'/>
                    <Skeleton />
                </div>
            </div>
        )
    }




    return (
        <div className='mx-3'>
            <Spin size="large" spinning={spin}>
                <div className='my-4' >
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>订单商品</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>订单详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className='bg-white' id='order' ref={printRef}>
                        <PageHeader
                            ghost={false}
                            title={"订单号: #" + data.order_id}
                            tags={<Tag color="blue" onClick={() => navigate('/order/user/' + data.order_sn)}>查看用户订单</Tag>}
                            extra={[
                                <Button key="2" onClick={()=>printOrder('order')}>打印订单</Button>,
                                <Button key="1" type="primary" onClick={() => navigate(-1)}>返回列表</Button>
                            ]}
                        >
                            <div className='mt-3'>
                            <Row>
                                <Col span={18}>
                                    <Space>
                                        <Thumb
                                            width={110}
                                            height={110}
                                            src={data.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                                            preview={data.thumb}
                                        />
                                           <div>
                                            <Title className='mb-0' level={5}>{data.title}</Title>
                                            <div className='text-muted mt-1'>规格：默认</div>
                                               <div className='mt-3'>
                                                <Tag className='mb-1' >{typeText(data.goods_type)}</Tag>
                                               {data.is_verify &&  <Tag className='mb-1' >线下核销</Tag>}
                                               {data.is_points &&  <Tag className='mb-1' >积分兑换</Tag>}
                                               {data.is_level &&  <Tag className='mb-1' >会员商品</Tag>}
                                               </div>
                                           </div>
                                            </Space>
                                </Col>
                                <Col flex={1}>
                                    {data.refund === 4  ? <Statistic title="支付状态" value={'已退款'} valueStyle={{ color:'#dc3545' }}  /> :
                                    <Statistic title="支付状态" value={statusText().text} valueStyle={{ color:statusText().color }}  />}
                                </Col>
                                    <Col>
                                    <Statistic
                                        title="商品金额"
                                        prefix="¥"
                                        value={data.real_price}
                                        style={{
                                            margin: '0 32px',
                                        }}
                                    />
                                    </Col>
                            </Row>
                        </div>
                        </PageHeader>

                    {data.refund > 0 &&

                        <div>
                            <Divider style={{ marginBottom: 32 }} />
                            <div className='px-4'>
                                <Row align="middle">
                                    <Col span={data.refund === 2 ? 20 : 24}>
                                <Descriptions title="退款详情">
                                    <Descriptions.Item label="退款金额">2222</Descriptions.Item>
                                    <Descriptions.Item label="申请日期">{data.refund_date}</Descriptions.Item>
                                    <Descriptions.Item label="退款状态">
                                        {data.refund === 1 && <div>用户申请退款<span className='text-warning'>「等待商户核准」</span></div>}
                                        {data.refund === 2 && <div>商户已经核准<span className='text-primary'>「等待平台确认」</span></div>}
                                        {data.refund === 3 && <div>平台已经核准<span className='text-info'>「等待系统退款」</span></div>}
                                        {data.refund === 4 && <div>订单退回账户<span className='text-success'>「退款成功」</span></div>}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="退款原因">{data.refund_remark ? <div><span className='text-warning'>「{data.refund_remark.type}」</span>{data.refund_remark.remark}</div> : '未录入原因'}</Descriptions.Item>
                                </Descriptions>
                                    </Col>
                                    {data.refund === 2 && <Col span={4}>
                                        <Popconfirm title="是否确认退款? 退款后无法返回操作！" onConfirm={onRefund}>
                                        <Button key="1" type="primary">确认退款</Button>
                                        </Popconfirm>
                                    </Col>}
                                </Row>
                            </div>
                        </div>

                    }

                    <Divider style={{ marginBottom: 32 }} />
                      <div className='px-4'>
                        <Descriptions title="订单详情">
                            <Descriptions.Item label="下单用户">
                                <Space>
                                    <Avatar src={data.user.avatar} size={20} />
                                    <Text>{data.user.nickname}</Text>
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label="联系方式">{data.user.mobile}</Descriptions.Item>
                            <Descriptions.Item label="下单日期">{data.created_at}</Descriptions.Item>
                            <Descriptions.Item label="所属商户">
                                {data.mch.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="联系方式">{data.mch.tel}</Descriptions.Item>
                            <Descriptions.Item label="商户地址">{data.mch.address}</Descriptions.Item>
                            <Descriptions.Item label="实际价格">¥{data.price}</Descriptions.Item>
                            <Descriptions.Item label="支付价格">¥{data.real_price}</Descriptions.Item>
                            <Descriptions.Item label="优惠价格">¥{data.coupon_price}</Descriptions.Item>
                            <Descriptions.Item label="选择优惠">当前未选择任何优惠</Descriptions.Item>

                        </Descriptions>
                    </div>

                    {data.is_verify &&  <div>
                    <Divider style={{ marginBottom: 32 }} />
                       <div className='px-4'>
                           <Descriptions title="核销记录">
                               <Descriptions.Item label="核销次数">{data.verify_num * data.total}</Descriptions.Item>
                               <Descriptions.Item label="核销有效期">{data.verify_start_date}至{data.verify_end_date}</Descriptions.Item>
                               <Descriptions.Item label="已核销次数">
                                   {verify.length}
                               </Descriptions.Item>
                           </Descriptions>
                           <div className='mt-3'>
                               <Table size='small'  rowKey={(record) => {
                                   return record.id
                               }} pagination={false} dataSource={verify} columns={columns} />
                           </div>

                       </div>
                    </div>}
                </div>
            </Spin>
        </div>
    )
}

export default OrderGoodsDetails
