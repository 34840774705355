import { useEffect, useRef, useCallback } from 'react';
import {useRecoilValue} from "recoil";
import {userToken} from "../store";
import {Api} from "../Config";


const useFetch = (props = {}) => {

    const abortController = useRef(null);
    const mounted = useRef(true);
    const token = useRecoilValue(userToken);

    /**
     * fetch 请求封装
     * @param url
     * @param options
     * @returns {Promise<{[p: string]: *}>}
     */
    const request = async (url, options) => {
        let response;

        abortController.current = new AbortController();
        try {
            response = await fetch(url, {
                signal: abortController.current.signal,
                ...options
            });
        } catch (error) {
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
                mounted: mounted.current
            });
        }

        if (!response.ok) {
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
                mounted: mounted.current
            });
        }

        const json = await response.json();

        if(json.code === 0){
            return Promise.reject({...json , mounted: mounted.current});
        }

        return Promise.resolve({
            ...json,
            mounted: mounted.current
        });
    }




    /**
     * 对象转URL字符串
     * @param params
     * @returns {string}
     */
    const urlEncode = (params) => {
        const data = [];
        for (let attr in params) {
            data.push(`${attr}=${params[attr]}`);
        }
        return '?' + data.join('&');
    }


    /**
     * get 请求
     * @type {function(*, *=, *=): Promise<{[p: string]: *}>}
     */
    const get = useCallback ((url ,params = null , auth = false) => {
        console.log('token' , token)
         const config = {method: 'GET'}
         //如果需要认证
         if(auth){
             config.headers = { authorization: 'Bearer ' + token, }
         }
         //如果存在参数
        if(params) {
            url =  url  + urlEncode(params);
        }
         return request(Api + url , config)
    },[token])


    /**
     * post 请求
     * @type {(function(*, *=): (Promise<never>))|*}
     */
    const post = useCallback ((url ,params = null) => {
        //如果不存在参数

        const config = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }
        if(params) {
            config.body = JSON.stringify(params)
        }
        return request(Api + url , config)

    },[token])


    /**
     * put 请求
     * @type {(function(*, *=): (Promise<never>))|*}
     */
    const put = useCallback ((url ,params = null) => {
        //如果不存在参数
        if(!params) {
            return Promise.reject({
                code : 0,
                message : '修改参数未输入！',
                status : 503,
                mounted: mounted.current
            });
        }
        const config = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + token,
            },
            body : JSON.stringify(params)
        }

        return request(Api + url , config)
    },[token])


    /**
     * delete 请求
     * @type {function(*, *=): Promise<{[p: string]: *}>}
     */
    const destroy = useCallback ((url ,params = null) => {
        const config = {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }
        //如果存在参数
        if(params) {
            config.body = JSON.stringify(params)
        }
        return request(Api + url , config)
    },[token])



    useEffect(() => {
        return () => {
            mounted.current = false;
            if (abortController.current) {
                abortController.current.abort();
            }
        }
    }, []);


    return { get , post , put , destroy, request };
}


export default  useFetch;
