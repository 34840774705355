/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    Divider,
    Spin,
    Row,
    Col,
    Avatar,
    Space, Empty, Skeleton, Typography, message, Breadcrumb, Badge, Rate
} from 'antd';
import {useNavigate, useParams} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Thumb} from "../../components";
const {Title} = Typography;



const OrderCommentDetails = (props) => {
    const {put, get} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const printRef = useRef() ;
    const navigate = useNavigate();


    useEffect(() => {
        get('admin/shop/order/comment/' + id, null,  true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);


    /**
     * 更新用户资料
     */
    const onUpdate = (status) => {
        setSpin(true)
        put('admin/shop/order/comment/' + id, {status:status,order_goods_id:id, score:data.score, content:data.content})
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('评论状态提交成功！');
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>订单评论</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>评论详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>订单评论</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>评论详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4' style={{height: 520}}>
                    <Skeleton className='mt-5'/>
                    <Skeleton className='mt-5'/>
                    <Skeleton />
                </div>
            </div>
        )
    }




    return (
        <div className='mx-3'>
            <Spin size="large" spinning={spin}>
                <div className='my-4' >
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>订单评论</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>评论详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className='bg-white py-4' id='order' ref={printRef}>
                            <div className='px-4'>
                            <Row  justify="space-between">
                                <Col>
                                    <Space>
                                        <Thumb
                                            width={80}
                                            height={80}
                                            src={data.order_goods.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                                            preview={data.order_goods.thumb}
                                        />
                                        <div>
                                            <Title className='mb-0' level={5}>{data.order_goods.title}</Title>
                                            <div className='text-muted mt-2'>订单价格: ¥{data.order_goods.real_price} <span className='ms-2'>x{data.order_goods.total}</span></div>
                                        </div>

                                    </Space>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button disabled={data.status === 2} onClick={()=>onUpdate(2)}>通过</Button>
                                        <Button type="primary" disabled={data.status === 1} danger onClick={()=>onUpdate(1)}>拒绝</Button>
                                        <Button type="primary" onClick={() => navigate(-1)}>返回列表</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                      <Divider plain>用户评论</Divider>
                      <div className='px-4'>
                          <div className='d-flex justify-content-between align-items-center'>
                              <Space>
                              <Avatar size={44}  src={data.user.avatar} alt={data.user.nickname} />
                              <Title className='mb-0' level={5}>{data.user.nickname}</Title>
                              <span className='text-muted'>{data.created_at}</span>
                              <Rate style={{fontSize: 14}} disabled defaultValue={data.score}/>
                              </Space>
                              <div>
                                  {data.status === 0 && <Badge
                                      count={'待审核'}
                                      style={{backgroundColor: '#6c757d'}}
                                  />}
                                  {data.status === 1 && <Badge
                                      count={'已拒绝'}
                                      style={{backgroundColor: '#dc3545'}}
                                  />}
                                  {data.status === 2 && <Badge
                                      count={'已通过'}
                                      style={{backgroundColor: '#52c41a'}}
                                  />}
                              </div>
                          </div>

                          <div className='p-3 bg-light mt-3'>
                              {data.content}
                              {data.thumb_url && <div className='mt-3 d-flex flex-wrap'>
                                      {data.thumb_url.map((tag, index) => {
                                          return (
                                              <Thumb
                                                  key={index}
                                                  width={100}
                                                  height={100}
                                                  src={tag.url + "?x-oss-process=image/resize,w_200,h_200,m_fill,limit_0"}
                                                  preview={tag.url}
                                              />
                                          );
                                      })}
                                     </div>
                              }
                          </div>
                      </div>

                    <Divider plain>商户回复</Divider>
                    <div className='px-4'>
                        <div>
                            <Space>
                                <Avatar shape="square"  size={44}  src={data.mch.logo + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"} alt={data.mch.name} />
                                <Title className='mb-0' level={5}>{data.mch.name}</Title>
                            </Space>
                        </div>
                        <div className='p-3 bg-light mt-3'>
                            {!data.reply_content && data.reply_content.length === 0 ? '商家未回复！' : data.reply_content}
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default OrderCommentDetails
