/**
 * Created by devin on 2021/4/23.
 */

import React, {useRef, useState} from 'react'

import {useNavigate, withRouter} from 'react-router-dom'
import {
    Button,
    Divider,
    Form,
    Input,
    message,
    Radio,
    Spin,
    Tag,
    Select,
    Typography,
    Cascader,
    InputNumber, Breadcrumb
} from "antd";

import {PlusOutlined} from "@ant-design/icons";
import cityData from '../../utils/City'
import {mchCategory, mchTags} from "../../store";
import {useRecoilValueLoadable} from "recoil";
import {Attachment, SortableImage, ThumbSelect} from '../../components'
import {useHttps} from "../../hooks";
const {Text} = Typography

const Create = (props) => {
    const {post} = useHttps();
    const category = useRecoilValueLoadable(mchCategory);
    const tags = useRecoilValueLoadable(mchTags);
    const [type, setType] = useState(1)
    const [file, setFile] = useState([])
    const [logo, setLogo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [service, setService] = useState([])
    let [serviceVisible, setServiceVisible] = useState(false)
    const [serviceValue, setServiceValue,] = useState('')
    const formRef = useRef();
    const selectRef = useRef();
    const saveServiceRef = useRef(null);
    const navigate = useNavigate();

    const onFinish = (params) => {
        params.service = service.join('|');
        params.province = params.city_data[0];
        params.city = params.city_data[1];
        params.area = params.city_data[2];
        setLoading(true)
        post('admin/mch', params)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('商家已创建成功！');
                    navigate(-1);
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error('网络错误稍后重试！');
                }
            });
    }


    /**
     * 附加图片处理
     * @param item
     */
    const imageInsert = (item) => {
            const dataSource = [...item, ...file];
            formRef.current.setFieldsValue({card_thumb: dataSource})
            setFile(dataSource)
    }


    /**
     * 添加关键字
     */

    const serviceConfirm = () => {
        const dataSource = [...service];
        if (serviceValue && service.indexOf(serviceValue) === -1) {
            dataSource.push(serviceValue);
            setService(dataSource)
        }
        setServiceVisible(false)
        setServiceValue('')
    };


    /**
     * 删除关键字
     * @param removedTag
     */
    const serviceClose = (removedTag) => {
        const dataSource = service.filter(tag => tag !== removedTag);
        setService(dataSource)
    };

    /**
     * 验证坐标
     * @param rule
     * @param value
     * @param callback
     */

    const checkLocation = async (rule, value) => {
        const location = value.split(",");
        if(location.length !== 2) {
            await Promise.reject('请输入正确格式！')
        }
        const long = /^-?((0|1?[0-8]?[0-9]?)(([.][0-9]{1,10})?)|180(([.][0]{1,10})?))$/;
        if(!long.test(location[0])){
            await Promise.reject('请输入正确格式！')
        }
        const lat = /^-?((0|[1-8]?[0-9]?)(([.][0-9]{1,10})?)|90(([.][0]{1,10})?))$/;
        if(!lat.test(location[1])){
            await Promise.reject('请输入正确格式！')
        }
        await Promise.resolve()
    }

    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-dark' >商户</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部商户</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item  className='text-primary'>添加</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <div>
                            <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                                返回列表
                            </Button>
                        </div>
                        <Divider/>
                        <div>
                            <Form

                                ref={formRef}
                                labelCol={{span: 4}}
                                wrapperCol={{span: 16}}
                                name="basic"
                                initialValues={{
                                    status: 0,
                                    type: 1,
                                    link: '',
                                    description: '',
                                    city_data:['210000', '210200', '210202'],
                                    transfer_rate:1,
                                    card_id:null,
                                    card_thumb:[],
                                    tags:[],
                                    logo:null
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item label="公司名称"  required>
                                <Form.Item
                                    className='d-inline-block w-50 mb-0'
                                    name="name"
                                    rules={[{required: true, message: '请输入商户全称！'}]}
                                >
                                    <Input maxLength={50} placeholder='请填写公司证件的名称！'/>
                                </Form.Item>
                                <Form.Item
                                    className='d-inline-block w-25 ms-3 mb-0'
                                    name="short_name"
                                    rules={[{required: true, message: '请输入商户简称！'}]}
                                >
                                    <Input maxLength={10}  placeholder='请填写公司简称例如：百度科技' />
                                </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label="联系电话"
                                    name="tel"
                                    wrapperCol={{ span: 4 }}
                                    rules={[{
                                        required: true,
                                        message: '请输入联系电话！',
                                        validator: async (rule, value) => {
                                            const regPos = /^\d+(\.\d+)?$/;
                                            if(!regPos.test(value)){
                                                return Promise.reject()
                                            }
                                        }
                                    }]}
                                >
                                    <Input placeholder='格式为手机或或座机041188888888' />
                                </Form.Item>


                                <Form.Item
                                    label="商户Logo"
                                    name='logo'
                                    extra="上传尺寸建议220x220"
                                    className="mt-3"
                                    wrapperCol={{ span: 4 }}
                                >
                                    <ThumbSelect src={logo}
                                                 onChange={(e) => {

                                                         setLogo(e);
                                                         formRef.current.setFieldsValue({logo: e})


                                                 }} />



                                </Form.Item>
                                <Form.Item
                                    name="type"
                                    label="商户类型"
                                    rules={[{required: true, message: '请选择分类！'}]}
                                  >
                                    <Select placeholder="选择商户类型" style={{width: 160}} value={type} onChange={setType} >
                                        <Select.Option value={1}>企业公司</Select.Option>
                                        <Select.Option value={2}>政府机关</Select.Option>
                                        <Select.Option value={3}>事业单位</Select.Option>
                                        <Select.Option value={4}>个体商户</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="证件号码"
                                    name="card_id"
                                    wrapperCol={{ span: 6 }}

                                >
                                    <Input maxLength={20} placeholder='请填写公司证件的号码！' />
                                </Form.Item>
                                <Form.Item
                                    label="选择城市"
                                    name="city_data"
                                    rules={[{required: true, message: '请输入城市！'}]}
                                >
                                    <Cascader
                                        style={{width:380}}
                                        options={cityData}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="详细地址"
                                    name="address"
                                    wrapperCol={{ span: 6 }}
                                    rules={[{required: true, message: '请输入标题！'}]}
                                >
                                    <Input maxLength={50}  placeholder="请输入精准到门牌号"/>
                                </Form.Item>

                                <Form.Item
                                    label="商户坐标"
                                    wrapperCol={{ span: 4 }}
                                    name="location"
                                    rules={[{required: true,  validator: checkLocation}]}
                                >
                                    <div>
                                    <Input/>
                                        <Text type="secondary" > 请进入<a href='https://lbs.amap.com/tools/picker' rel="noopener noreferrer" target="_blank">高德地图</a>进行坐标查询</Text>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="证件上传"
                                    name='card_thumb'
                                >
                                    <div className='w-100'>
                                            <SortableImage  list={file} onChange={(e) => {
                                                    setFile(e)
                                                    formRef.current.setFieldsValue({card_thumb: e})
                                            }}/>
                                        <div>
                                            <Button onClick={() => {
                                                selectRef.current.onOpen(1, true)
                                            }} type="dashed" icon={<PlusOutlined/>}>
                                                上传证件
                                            </Button>
                                        </div>
                                    </div>
                                </Form.Item>
                                <Form.Item name='description' label="商户简介">
                                    <Input.TextArea maxLength={200} showCount/>
                                </Form.Item>
                                <Form.Item   rules={[{required: true, message: '请选择分类！'}]}  name='category' label="商户分类">
                                    <Select
                                        mode="multiple"
                                        placeholder='选择分类'
                                        optionFilterProp="label"
                                        disabled={category.state !== 'hasValue'}
                                    >
                                        {category.state === 'hasValue' && category.contents.map((tag) => {
                                            return (
                                                <Select.Option key={tag.id} value={tag.id} label={tag.name}> {tag.name}</Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item name='tags' label="商户标签">
                                <Select
                                    mode="multiple"
                                    placeholder='选择标签'
                                    disabled={tags.state !== 'hasValue'}
                                >
                                    {tags.state === 'hasValue' && tags.contents.map((tag) => {
                                        return (
                                            <Select.Option key={tag.id} value={tag.id}> {tag.name}</Select.Option>
                                        );
                                    })}
                                </Select>
                                </Form.Item>
                                <Form.Item name='transfer_rate' label="商户手续费">
                                    <InputNumber
                                        decimalSeparator
                                        step={0.1}
                                        min={0.6}
                                        max={3}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                                <Form.Item name='service' label="服务项目"
                                           rules={[{
                                               required: true,
                                               message: '请输入服务内容！',
                                               validator: async (rule, value) => {
                                                   if (service.length === 0) {
                                                       return Promise.reject()
                                                   }
                                               }
                                           }
                                           ]}
                                >
                                    <div>
                                        {service.map((tag) => {
                                            return (
                                                <Tag
                                                    color="blue"
                                                    className="edit-tag"
                                                    key={tag}
                                                    closable
                                                    onClose={() => serviceClose(tag)}
                                                >
                                                    <span> {tag} </span>
                                                </Tag>
                                            );
                                        })}
                                        {serviceVisible && (
                                            <Input
                                                maxLength={8}
                                                name='service'
                                                ref={saveServiceRef}
                                                type="text"
                                                autoFocus={serviceVisible}
                                                size="small"
                                                style={{width: 100}}
                                                value={serviceValue}
                                                onChange={(e) => {
                                                    setServiceValue(e.target.value)
                                                }}
                                                onBlur={serviceConfirm}
                                                onPressEnter={serviceConfirm}
                                            />
                                        )}
                                        {!serviceVisible && (
                                            <Tag className="site-tag-plus" onClick={() => {
                                                setServiceVisible(true)
                                            }}>
                                                <PlusOutlined/> 添加服务
                                            </Tag>
                                        )}
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="status"
                                    label="是否认证"
                                >
                                    <Radio.Group value={0}>
                                        <Radio value={0}>待审核</Radio>
                                        <Radio value={2}>已通过</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item wrapperCol={{offset: 4, span: 8}}>
                                    <Button className='me-3' type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                    <Button className='me-2' onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
            <Attachment refs={selectRef} onChange={(item) => imageInsert(item)}/>
        </div>
    )
}
export default Create;
