import React from 'react'
import {
    Breadcrumb,
    Divider,
    Typography,
    Row,
    Col,
    Card,
    List,
    Avatar,
    DatePicker,
    Button,
    Tabs,
    Tag,
    Statistic,
    Space,
    Table,
    Badge
} from "antd";
import {withRouter} from "react-router-dom";

import { WechatFilled, ArrowUpOutlined ,UserOutlined, InfoCircleOutlined,
} from "@ant-design/icons";
import { Chart, Interval,Tooltip, LineAdvance, DonutChart } from 'bizcharts';
import {Thumb} from "../../components";
const { Text, Paragraph } = Typography;


/*
  主页
*/
const Home =   () => {

    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >首页</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>仪表盘</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className= 'p-3'>
                <div>
                    <Row gutter={16}>
                        <Col span={6}>
                            <div className='p-3 bg-white'>
                                <div className='position-relative'>
                                    <InfoCircleOutlined  className='position-absolute end-0 text-muted'/>
                                </div>
                                <Statistic title="总销售额"  prefix={'¥'} value={1128} />
                                <div className='mt-3'>
                                        <Tag color="green">+12%</Tag>周销售同比
                                </div>
                                <Divider className='mb-3' />
                                <div>今日销售：¥258.00</div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='p-3 bg-white'>
                                <div className='position-relative'>
                                    <InfoCircleOutlined  className='position-absolute end-0 text-muted'/>
                                </div>
                                <Statistic title="订单总数" groupSeparator='' value={1128} />
                                <div className='mt-3'>
                                            <UserOutlined /> 新客户下单  <ArrowUpOutlined className='text-success'/> 80%
                                </div>
                                <Divider className='mb-3' />
                                <div>今日订单：128</div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='p-3 bg-white'>
                                <div className='position-relative'>
                                    <InfoCircleOutlined  className='position-absolute end-0 text-muted'/>
                                </div>
                                <Statistic title="会员总数" groupSeparator='' value={368892} />
                                        <div className='text-muted mt-3'>
                                            <WechatFilled  className='fs-6 me-2' style={{
                                                color:'#04BE02'
                                            }} /> <span className='fw-bold'>小程序 89756</span> 人
                                        </div>
                                <Divider className='mb-3' />
                                <div>今日新增：96</div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='p-3 bg-white'>
                                <div className='position-relative'>
                                    <InfoCircleOutlined  className='position-absolute end-0 text-muted'/>
                                </div>
                                <Statistic title="商户总数" groupSeparator='' value={2230} />
                                <div className='mt-3'>
                                    <Space size={30}>
                                        <div className='text-muted'>
                                            政府: <span className='text-dark fw-bold'>962</span> 家
                                        </div>
                                        <div className='text-muted'>
                                            企业: <span className='text-dark fw-bold'>437</span> 家
                                        </div>
                                    </Space>
                                </div>
                                <Divider className='mb-3' />
                                <div>今日入驻：13</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
export default Home;
