/**
 * Notes: 商户列表
 * User: 李强
 * DateTime: 2021/12/13 12:51
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilValueLoadable} from 'recoil';

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Typography,
    Space, Select, Table, Badge, Popconfirm, Tag, message, Modal, Breadcrumb, Avatar
} from 'antd';
import {
    ArrowDownOutlined, ArrowUpOutlined, StopOutlined, CheckOutlined,
    PlusOutlined, EditOutlined, ExclamationCircleOutlined,FileTextOutlined
} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {mchCategory} from "../../store";
import {useHttps} from "../../hooks";
const {Text} = Typography;


const Index = (props) => {
    const {put, get} = useHttps();
    const category = useRecoilValueLoadable(mchCategory);
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(4)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    const [selectCategory, setSelectCategory] = useState(0)
    const [selectDate, setSelectDate] = useState(null)
    const [selectType, setSelectType] = useState(0)
    const [editItem, setEditItem,] = useState(0)
    const [editVisible, setEditVisible] = useState(false)
    const navigate = useNavigate();

    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/mch', params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);



    /**
     * 更新列表操作
     * @param params
     * @param ids
     */
    const onUpdate = (params, ids) => {
        setLoading(true);
        params.id = ids ;
        put('admin/mch/batch', params)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    message.error(e.message);
                    setLoading(false);
                }
            });
    };



    const statusText = (status) => {
        let content = {};
        switch (status) {
            case 1:
                content.color = '#f5222d';
                content.text = '已拒绝';
                break;
            case 2:
                content.color = '#52c41a';
                content.text = '已通过';
                break;
            default:
                content.color = '#fa8c16';
                content.text = '待审核';
        }
        return content;
    };


    const typeText = (status) => {
        let content = {};
        switch (status) {
            case 1:
                content.color = 'magenta';
                content.text = '企业公司';
                break;
            case 2:
                content.color = 'lime';
                content.text = '政府机关';
                break;
            case 3:
                content.color = 'purple';
                content.text = '事业单位';
                break;
            default:
                content.color = 'geekblue';
                content.text = '个体商户';
        }
        return content;
    };


    /**
     * 操作提示信息
     */
    const showConfirm = (type) => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要{type === 1 ? '拒绝' : '通过'}选中项目吗？</div>,
            onOk() {
                onUpdate({status: type}, selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }


    /**
     * 自定义表格
     */
    const onUpdateItem = (value, item) => {
        setEditVisible(false)
        if (parseInt(value) === item['sort']) {
            return
        }
        onUpdate({sort: value}, [item.id])
    }



    const columns = [
        {
            title: '排序',
            dataIndex: 'sort',
            width:60,
            sorter: (a, b) => a.sort - b.sort,
            render: (sort, item) => {
                return (
                    <div>
                        {editVisible && editItem === item.id ?
                            <Input
                                autoFocus={editVisible}
                                defaultValue={sort}
                                onBlur={(e) => onUpdateItem(e.target.value, item)}
                                onPressEnter={(e) => onUpdateItem(e.target.value, item)}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setEditItem(item.id)
                                setEditVisible(true)
                            }}>{sort}</div>}
                    </div>
                )
            }
        },
        {
            title: '商户名称',
            dataIndex: 'name',
            render: (name, item) => {
                return (
                    <Link to={'/mch/account/' + item.id}>
                        <Space>
                            <Avatar shape="square"  size={38}  src={item.logo} />
                            <Text>{name}</Text>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '商户分类',
            dataIndex: 'category',
            render: (category) => {
                return (
                    <div>
                        {category.slice(-3).map((tag) => {
                            return (
                                <Tag key={tag.id} className='mb-1' >{tag.name}</Tag>
                            );
                        })}
                        {category.length === 0 &&   <Tag>未分类</Tag>}
                    </div>

                )
            }
        },
        {
            title: '商户类型',
            dataIndex: 'type',
            render: (type) => {
                return (
                    <Tag color={typeText(type).color}>{typeText(type).text}</Tag>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (status) => {
                return (
                    <Badge
                        count={statusText(status).text}
                        style={{backgroundColor: statusText(status).color}}
                    />
                )
            }
        },
        {
            title: '注册日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (
                    <Space>
                        <Button onClick={() => navigate('/mch/account/' + item.id)} className='text-muted' type="dashed" size='small' icon={<FileTextOutlined />}/>
                        <Button onClick={() => navigate('/mch/edit/' + item.id)} className='text-muted' type="dashed" size='small' icon={<EditOutlined/>}/>
                        <Popconfirm title="确定拒绝?" onConfirm={() => onUpdate({status: 1}, [item.id])}>
                            <Button className='text-muted' type="dashed" size='small' icon={<StopOutlined/>}/>
                        </Popconfirm>
                        <Popconfirm title="确定通过?" onConfirm={() => onUpdate({status: 2}, [item.id])}>
                            <Button className='text-muted' type="dashed" size='small' icon={<CheckOutlined/>}/>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination, filters, sorter) => {

        setLoading(true);

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (sorter && sorter.order) {
            const order = sorter.order === 'ascend' ? 'asc' : 'desc'
            params.order_by = sorter.field
            params.order = order
        }
        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        if (selectType !== 0) {
            params.type = selectType;
        }
        if (status !== 4) {
            params.status = status;
        }
        if (search.length !== 0) {
            params.search = search;
        }

        if (selectCategory) {
            params.category_id = selectCategory;
        }

        window.location.hash = `#${pagination.current}`;
        getData(params);
    }

    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setSelectCategory(0)
        setSelectType(0)
        setStatus(4)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }


    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    };



    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >商户</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>全部商户</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white p-4'>
                <Row justify="space-between">
                    <Col>
                        <Button
                            onClick={() =>navigate('/mch/create')}
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            添加
                        </Button>
                    </Col>
                    <Col>

                        <Space>
                            <Select defaultValue={0} value={selectType} onChange={setSelectType}>
                                <Select.Option value={0}>全部用户</Select.Option>
                                <Select.Option value={1}>企业公司</Select.Option>
                                <Select.Option value={2}>政府机关</Select.Option>
                                <Select.Option value={3}>事业单位</Select.Option>
                                <Select.Option value={4}>个体商户</Select.Option>
                            </Select>

                            <Select defaultValue={4} value={status} onChange={setStatus} >
                                <Select.Option value={4}>全部状态</Select.Option>
                                <Select.Option value={0}>待审核</Select.Option>
                                <Select.Option value={1}>已拒绝</Select.Option>
                                <Select.Option value={2}>已认证</Select.Option>
                            </Select>

                            <Select defaultValue={0} value={selectCategory} onChange={setSelectCategory}
                                    disabled={category.state !== 'hasValue'}>
                                <Select.Option value={0}>全部分类</Select.Option>
                                {category.state === 'hasValue' && category.contents.map((tag) => {
                                    return (
                                        <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                    );
                                })}
                            </Select>

                            <Input.Search
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onSearch={(e) => {
                                    onPaginationChange({current: 1, pageSize: perPage})
                                }}
                                placeholder="输入商户名称搜索"
                                allowClear
                                enterButton="筛选"
                            />
                            <Button  type="primary" onClick={onReset}>
                                导出
                            </Button>
                            <Button onClick={onReset}>
                                重置
                            </Button>
                        </Space>
                    </Col>
                </Row>

                <Divider/>
                <div className='mt-4'>
                    <Space className='mb-3'>
                        <Button icon={<ArrowDownOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                onClick={() => showConfirm(2)}
                                size='small'>通过</Button>
                        <Button icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                onClick={() => showConfirm(1)}
                                size='small'>拒绝</Button>
                        <span className='text-muted'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 个商户` : ''}
                        </span>
                    </Space>
                    <Table
                        size="middle"
                        loading={loading}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            size:'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 家商户`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Index
