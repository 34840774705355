/**
 * Notes: 个人资料
 * User: 李强
 * DateTime: 2021/12/10 10:29
 * Ide: PhpStorm
 */

import React, {useEffect, useState} from 'react'

import {useRecoilValueLoadable} from 'recoil';

import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Empty,
    message,
    Modal,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    Tabs,
    Tag,
    Typography,
    Avatar
} from 'antd';
import {ExclamationCircleOutlined,} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom'
import { Icon} from '../../Config';
import {userGrade, userTags} from "../../store";
import UserOrder from './UserOrder'
import UserBlog from './UserBlog'
import UserActivity from './UserActivity'
import UserPoints from './UserPoints'

import {useHttps} from "../../hooks";

const {Text, Title} = Typography;
/*
  用户管理
*/
const Account = (props) => {

     const {id} = useParams();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState('1')
    const [initTags ,setInitTags] = useState([]);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {put, get} = useHttps();
    const tags = useRecoilValueLoadable(userTags);
    const grade = useRecoilValueLoadable(userGrade);
    const [selectGrade, setSelectGrade] = useState(0)
    const [selectTag, setSelectTag] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        get('admin/user/' + id, null , true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                    const tags =  res.data.tags.map((item) => {
                        return item.id
                    });
                    setInitTags(tags)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);



    /**
     * 更新用户资料
     * @param params
     */
    const onUpdate = (params) => {
        setSpin(true)
        put('admin/user/' + id, params)
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('用户已更新成功！');
                    setData(res.data)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error('网络错误稍后重试！');
                }
            });
    }


    /**
     * 更新用户字段
     */

    const onOk = () => {
        let params = {};
        setIsModalVisible(false)
        //判断等级是否更改
        if(selectGrade &&  data.grade.id !== selectGrade){
            params.grade_id = selectGrade;
        }
        //判断标签是否更改
         params.tags = selectTag.map((item) => {
                return item.key
        });
        //更新数据
        onUpdate(params)
    }



    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>{data.status === 1 ? '确定要拉入黑名单吗？' : '确定要取消黑名单吗？'}</div>,
            onOk: function () {
                const status = data.status === 1 ? 2 : 1;
                onUpdate({status: status})
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 默认Tags
     */


    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'><Divider type='vertical' className='bg-primary'
                                               style={{width: 5, height: 14}}/> 当前位置：<span
                    className='text-primary'>个人资料</span></div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部会员</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>会员资料</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4 pt-4' style={{height: 290}}>
                    <Skeleton avatar/>
                </div>
                <div className='bg-white px-4  mt-3' style={{height: 320}}>
                    <Skeleton/>
                    <Skeleton/>
                </div>
            </div>
        )
    }


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部会员</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item  className='text-primary'>会员资料</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='bg-white px-4 pt-4'>
                <Spin size="large" spinning={spin}>
                <Row justify="space-between">
                    <Col flex={1}>
                        <Space>
                            <Avatar shape="square"  size={160} src={data.avatar} alt={data.nickname} />
                            <div>
                                <Space align="center" size={20}>
                                    <Title className='mb-0' level={5}>{data.nickname}</Title>
                                    <img onClick={() => {
                                        if(data.auth && data.auth.status === 2 ){
                                            navigate('/user/auth/edit/' + data.auth.id)
                                        }
                                    }}  style={{height: 22}} src={data.auth && data.auth.status === 2 ? Icon.auth : Icon.authNot}
                                         alt={data.nickname}/>
                                    <Avatar shape="square" size="small" src={data.grade.thumb} />
                                    <div>
                                        {data.tags.map((tag) => {
                                            return (
                                                <Tag key={tag.id} className='mb-1' color={tag.color}>{tag.name}</Tag>
                                            );
                                        })}
                                    </div>
                                </Space>
                                <div className='mt-2'>
                                    <Space align="center" size={30}>
                                        <Text className='mb-0  text-muted' level={5}>手机号:{data.mobile}</Text>
                                        {data.auth && <Text className='mb-0  text-muted' level={5}>身份号:{data.auth.card_id}</Text>}
                                        <Text className='mb-0  text-muted' level={5}>注册日期:{data.created_at}</Text>
                                    </Space>
                                </div>
                                <div className='mt-4'>
                                    <Space align="center" size={15}>
                                        <div className='border border-gray-300 border-dashed rounded p-2'
                                             style={{width: 100, height: 65}}>
                                            <Title className='mb-1' level={5}>{data.statistic.order}</Title>
                                            <span className='d-block text-muted font-size-0'>订单总数</span>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded p-2'
                                             style={{width: 100, height: 65}}>
                                            <Title className='mb-1' level={5}><span className='font-size-0'>¥</span>{data.statistic.consume}</Title>
                                            <span className='d-block text-muted font-size-0'>累计消费</span>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded p-2'
                                             style={{width: 100, height: 65}}>
                                            <Title className='mb-1' level={5}>{data.points}</Title>
                                            <span className='d-block text-muted font-size-0'>剩余积分</span>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded p-2'
                                             style={{width: 100, height: 65}}>
                                            <Title className='mb-1' level={5}>{data.statistic.total_points}</Title>
                                            <span className='d-block text-muted font-size-0'>积分总计</span>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </Space>
                    </Col>
                    <Col>
                            <Space align="center">
                                <Button className='me-2' type="primary"  onClick={() => navigate(-1)}>
                                    返回
                                </Button>
                                <Button onClick={showConfirm} className='me-2' danger={data.status === 1}>
                                    {data.status === 1 ? '加入黑名单' : '取消黑名单'}
                                </Button>
                                <Button  onClick={()=>setIsModalVisible(true)}>
                                    编辑
                                </Button>
                            </Space>
                    </Col>
                </Row>
                </Spin>

                <div className='mt-5 account-tabs'>
                    <Tabs defaultActiveKey="1" size='large' onChange={setContent}>
                        <Tabs.TabPane tab="全部订单" key="1"/>
                        <Tabs.TabPane tab="博客列表" key="2"/>
                        <Tabs.TabPane tab="活动记录" key="3"/>
                        <Tabs.TabPane tab="积分日志" key="4"/>
                    </Tabs>
                </div>

            </div>

            <div>
                {content === '1' && <UserOrder id={id}/> }
                {content === '2' && <UserBlog id={id}/> }
                {content === '3' && <UserActivity id={id}/> }
                {content === '4' && <UserPoints id={id}/> }


            </div>
            <Modal title="编辑用户" visible={isModalVisible} onCancel={()=>setIsModalVisible(false)} onOk={onOk}>
                <div>
                    <Text>设置等级</Text>
                        <Select placeholder="设置等级" className='w-100 mt-2' defaultValue={data.grade.id ? data.grade.id : null} onChange={setSelectGrade}
                                disabled={grade.state !== 'hasValue'}>
                            {grade.state === 'hasValue' && grade.contents.map((tag) => {
                                return (
                                    <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                );
                            })}
                        </Select>
                </div>
                <div className='my-4'>
                    <Text>编辑标签</Text>
                    <Select
                        className='w-100 mt-2'
                        mode="tags"
                        showArrow
                        placeholder="设置标签"
                        defaultValue={initTags}
                        onChange={(e,k)=> setSelectTag(k)}
                    >
                        {tags.state === 'hasValue' && tags.contents.map((tag) => {
                            return (
                                <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                            );
                        })}
                    </Select>
                </div>
            </Modal>
        </div>

    )
}

export default Account

