/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
    Button,
    Divider,
    Spin,
    Comment,
    List,
    Avatar,
    Space, Empty, Skeleton, message, Breadcrumb, Badge
} from 'antd';
import {Link, useNavigate, useParams, withRouter} from 'react-router-dom'
import {useHttps} from "../../hooks";
import moment from "moment";
import {LikeOutlined, CommentOutlined, HeartOutlined, MessageOutlined} from "@ant-design/icons";
import {Thumb} from "../../components";


const BlogDetails = (props) => {
    const {put, get} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [commentLoading, setCommentLoading] = useState(true)
    const [comment, setComment] = useState([])
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(8)
    const printRef = useRef() ;
    const navigate = useNavigate();

    useEffect(() => {
        get('admin/blog/' + id, null,  true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);

    /**
     * 数据请求
     * @param {Object} params
     */
    const getCommentData = useCallback((params) => {
        get('admin/blog/comment',params , true)
            .then((res) => {
                if (res.mounted) {
                    setCommentLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setComment(res.data);
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setCommentLoading(false);
                }
            });
    },[get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getCommentData({page: pageNumber, per_page: 8 , blog_id:id});
    }, [getCommentData, id]);


    /**
     * 更新用户资料
     */
    const onUpdate = (status) => {
        setSpin(true)
        put('admin/blog/' + id, {status:status})
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('审核提交成功！');
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 表格操作触发
     * @param pagination
     * @param pageSize
     */
    const onPaginationChange = (pagination, pageSize) => {
        setCommentLoading(true);
        const params = {
            page: pagination,
            per_page: pageSize,
            blog_id:id
        }
        window.location.hash = `#${pagination.current}`;
        getCommentData(params);
    }

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>订单评论</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>评论详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >博客</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>博客列表</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4 pt-2' style={{height: 520}}>
                    <Skeleton avatar paragraph={{ rows: 4 }} className='mt-5'/>
                    <Skeleton avatar paragraph={{ rows: 2 }} className='mt-5'/>
                </div>
            </div>
        )
    }




    return (
        <div className='mx-3'>
            <Spin size="large" spinning={spin}>
                <div className='my-4' >
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >博客</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>博客列表</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className='bg-white py-4' id='order' ref={printRef}>
                    <div className='px-4'>
                        <div className='d-flex justify-content-between'>
                            <Link to={{pathname: '/user/account/' + data.user.id}}>
                                <Space>
                                    <Avatar size={60} src={data.user.avatar} />
                                    <div>
                                        <Space>
                                            <span className='mb-1'>{data.user.nickname}</span>
                                            {data.status === 0 && <Badge
                                                count={'待审核'}
                                                style={{backgroundColor: '#6c757d'}}
                                            />}
                                            {data.status === 1 && <Badge
                                                count={'已拒绝'}
                                                style={{backgroundColor: '#dc3545'}}
                                            />}
                                            {data.status === 2 && <Badge
                                                count={'已通过'}
                                                style={{backgroundColor: '#52c41a'}}
                                            />}
                                        </Space>
                                        <div className='text-muted fs-7 mt-1'>发布于：{moment(data.created_at).format('YYYY-MM-DD HH:mm')}</div>
                                    </div>
                                </Space>
                            </Link>
                            <Space>
                                <Button disabled={data.status === 2} onClick={()=>onUpdate(2)}>通过</Button>
                                <Button type="primary" disabled={data.status === 1} danger onClick={()=>onUpdate(1)}>拒绝</Button>
                                <Button type="primary" onClick={() => navigate(-1)}>返回列表</Button>
                            </Space>
                        </div>
                        <div className='my-3'>
                                <p className='mb-3'> {data.content} </p>
                            <Space>
                                {data.thumb_url && data.thumb_url.map((tag, index) => {
                                    return (
                                        <Thumb
                                            key={index}
                                            width={100}
                                            height={100}
                                            src={tag.url + "?x-oss-process=image/resize,w_300,h_300,m_fill,limit_0"}
                                            preview={tag.url}
                                        />
                                    );
                                })
                                }
                            </Space>
                        </div>

                        <div className='text-muted d-flex justify-content-between align-items-center'>
                            <Space size={30}>
                                <div>
                                    <MessageOutlined /> {data.comment_count}
                                </div>
                                <div>
                                    <HeartOutlined /> {data.like_count}
                                </div>
                            </Space>

                        </div>
                    </div>
                    <Divider plain>用户评论</Divider>
                    <ul className='px-4'>
                        <List
                            split
                            loading={commentLoading}
                            itemLayout="horizontal"
                            dataSource={comment}
                            pagination={{
                                defaultCurrent: 1 ,
                                total: total,
                                current: current,
                                showTotal: total => `总计 ${total} 条评论`,
                                pageSize: perPage,
                                onChange: onPaginationChange
                            }}
                            renderItem={item => (
                                <li key={item.id}>
                                    <Comment
                                        actions={[<div className='me-3' key={1}><LikeOutlined /> {item.like_count}</div>, <div key={2}><CommentOutlined /> {item.comment_count}</div>]}
                                        author={item.user.nickname}
                                        avatar={item.user.avatar}
                                        content={item.content}
                                        datetime={moment(item.created_at).format('YYYY-MM-DD HH:mm')}
                                    />
                                </li>
                            )}
                        />
                    </ul>
                </div>
            </Spin>
        </div>
    )
}

export default BlogDetails
