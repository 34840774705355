/**
 * Notes: 附件选择页面
 * User: 李强
 * DateTime: 2021/10/5 9:37 上午
 * Ide: PhpStorm
 */
/**
 * Created by devin on 2021/4/21.
 */

import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
    Button,
    Col,
    Divider,
    Progress,
    Row,
    List,
    Spin,
    Select,
    Modal,
    Upload,
    message,
    Image,
    DatePicker,
    Breadcrumb
} from "antd";
import {
    ExclamationCircleOutlined,
    CheckOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    PlusOutlined,
    DiffFilled, PlayCircleFilled
} from "@ant-design/icons";

import {ossSing, userToken} from "../../store";


import {Player} from '../../components'
import {useRecoilValue, useRecoilValueLoadable} from "recoil";
import moment from "moment";
import {useHttps} from "../../hooks";
import {subText} from "../../utils/commonfn";

const Attachment = (props) => {
    const oss = useRecoilValueLoadable(ossSing);
    const token = useRecoilValue(userToken);
    const {get , destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [selectType, setSelectType] = useState(0)
    const [data, setData] = useState([])
    const [selectData, setSelectData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(35)
    let [percent, setPercent] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [thumb, setThumb] = useState({src:''});
    const [selectDate, setSelectDate] = useState('')
    const [fileList, setFileList] = useState([])
    const playerRef = useRef();
    const uploadCount = useRef(0);
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/attachment' , params , true )
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])


    useEffect(() => {
        getData({page: 1, per_page: 35});
    }, [getData]);




    /**
     * OSS上传数据
     * @param file
     * @returns {*}
     */
    const getExtraData = file => {
        return {
            key: file.url,
            OSSAccessKeyId: oss.contents.accessid,
            policy: oss.contents.policy,
            callback: oss.contents.callback,
            'x:token': token,
            'x:name': file.name.substring(0, file.name.lastIndexOf(".")),
            Signature: oss.contents.signature,
        };
    };



    /**
     * 翻页操作触发
     * @param {int} page 翻页信息
     * @param {int} pageSize 内容过滤
     */

    const onPaginationChange = (page, pageSize) => {
        setLoading(true);
        const params = {
            page: page,
            per_page: pageSize,
        }
        if (selectType !== 0) {
            params.type = selectType;
        }
        if (selectDate) {
            const startDate = selectDate.startOf("month").format('YYYY-MM-DD');
            const endDate = selectDate.endOf("month").format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        getData(params);
    }


    /**
     * 上传回调
     * @returns {*}
     * @param info
     */
    const uploadChange = info => {

        let dataSource = [...info.fileList];
        if (info.file.status === 'uploading' ) {
            setFileList(dataSource)
            const total = dataSource.reduce((total, item) => total + item.percent , 0)
            const  percentTotal = total / dataSource.length
            setPercent(parseInt(percentTotal.toString()))
            setUploading(true)
            setLoading(true)
        }
        if (info.file.status === 'done') {
            uploadCount.current -= 1 ;
            if(uploadCount.current === 0){
                setLoading(false)
                setUploading(false)
                dataSource.splice(0,dataSource.length)
                setFileList(dataSource)
            }
            //如果是第一页则添加
            if (info.file.response.code !== 0 && current === 1 && info.file.response.data ) {
                const res = info.file.response.data;
                let dataSource = [...data];
                    dataSource.slice(0, -1);
                if (selectType === 1) {
                    if (res.type === 1) {
                        dataSource.unshift(info.file.response.data) ;
                        setData(dataSource)
                    }
                } else if (selectType === 2) {
                    if (res.type === 2) {
                        dataSource.unshift(info.file.response.data) ;
                        setData(dataSource)
                    }
                } else if (selectType === 3) {
                    if (res.type === 3) {
                        dataSource.unshift(info.file.response.data) ;
                        setData(dataSource)
                    }
                } else {
                    dataSource.unshift(info.file.response.data) ;
                    setData(dataSource)
                }
            }
        }
    };

    /**
     * 生成唯一图片名
     * @param file
     * @returns {*}
     */

    const genNonDuplicateID = () => {
        let str = Math.random().toString(36).substr(3);
        str += Date.now().toString(16).substr(4);
        return str;
    };


    /**
     * 上传前回调
     * @param file
     * @returns {*}
     */
    const beforeUpload = async file => {
        if (oss.state !== 'hasValue') {
            message.error('网络错误，请稍后重试!');
            return Upload.LIST_IGNORE;
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M && file.type !== 'video/mp4') {
            message.error('上传文件不能大于 20MB!');
            return Upload.LIST_IGNORE;
        }
        uploadCount.current += 1
        setPercent(0)
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = genNonDuplicateID() + suffix;
        file.url = oss.contents.dir + filename;
        return file;
    };

    /**
     * 选择重置
     */

    const handleReset = () => {
        const dataSource = [];
        setSelectData(dataSource)
    }

    /**
     * 选择触发
     * @param item
     */

    const onSelectChange = (item) => {
        const select = selectData.findIndex((value) => value.id === item.id);

        const dataSource = [...selectData];
        if (select > -1) {
            dataSource.splice(select, 1);
        } else {
            dataSource.push(item);
        }

        setSelectData(dataSource)
    }

    /**
     * 验证是否已选择
     * @param item
     * @returns {boolean}
     */
    const isSelect = (item) => {
        const select = selectData.findIndex((value) => value.id === item.id);
        return select > -1
    }

    /**
     * 设置开始时间
     * @param current
     * @returns {boolean}
     */
    const disabledDate = (current) => {
        return current < moment(new Date('2021/01/01')) || current > moment().endOf('day')
    }

    /**
     * 删除文件
     * @param  items
     * @returns {boolean}
     */
    const deleteItem = (items) => {
        setLoading(true);
        //获取全部id
        let ids = items.map((item) => {
            return item.id
        });
        //提交删除
        destroy('admin/attachment/batch', {id: ids})
            .then((res) => {
                if (res.mounted) {
                    onPaginationChange(current, perPage)
                    setSelectData([])
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }

    /**
     * 确认删除文件
     * @param  item
     * @returns {boolean}
     */
    const confirm = (item) => {
        Modal.confirm({
            centered: true,
            title: '是否要删除所选文件',
            icon: <ExclamationCircleOutlined/>,
            okText: '确认',
            cancelText: '取消',
            onOk: () => deleteItem(item)
        });
    }


    return (
        <Spin size="large" indicator={uploading ? <Progress type="circle" percent={percent} /> : {}} spinning={loading}>
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-primary' >附件管理</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <Row>
                        <Col span={10}>
                            <Upload
                                multiple
                                fileList={fileList}
                                showUploadList={false}
                                accept={".jpg,.jpeg,.png,.bmp,.gif,.mp4,.pdf"}
                                action={oss.state === 'hasValue' ? oss.contents.host : ''}
                                onChange={uploadChange}
                                data={getExtraData}
                                beforeUpload={beforeUpload}
                            >
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined/>}
                                >
                                    上传附件
                                </Button>
                            </Upload>

                        </Col>
                        <Col span={14} className='d-flex flex-row justify-content-end'>
                            <div className='w-auto'>
                                <DatePicker disabledDate={disabledDate} onChange={setSelectDate} picker="month"/>
                                <Button onClick={() => onPaginationChange(1, perPage)} type="primary"
                                        className='me-3 ms-1'>日期筛选</Button>
                            </div>
                            <div className='w-auto'>
                                <Select defaultValue="0" style={{width:160}} onChange={setSelectType}>
                                    <Select.Option value="0">全部类型</Select.Option>
                                    <Select.Option value="1">图片</Select.Option>
                                    <Select.Option value="2">视频</Select.Option>
                                    <Select.Option value="3">文件</Select.Option>
                                </Select>
                                <Button onClick={() => onPaginationChange(1, perPage)} type="primary"
                                        className='me-3 ms-1'>选择类型</Button>
                            </div>

                        </Col>
                    </Row>

                    <Divider/>
                    <div className='mt-4'>
                        <div className='mb-3'>
                            <Button onClick={() => handleReset()} icon={<ArrowUpOutlined/>}
                                    size='small' disabled={selectData.length === 0}>重选</Button>

                            <Button onClick={() => confirm(selectData)} className='ms-2' icon={<DeleteOutlined/>}
                                    size='small' disabled={selectData.length === 0}>删除</Button>
                            <span className='text-muted ms-2'>
                            已选择{selectData.length}项
                        </span>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <List
                            grid={{gutter: 10, column: 7}}
                            dataSource={data}
                            rowKey={(record) => {
                                return record.id
                            }}
                            pagination={{
                                    defaultCurrent:1,
                                    total:total,
                                    current:current,
                                    pageSize:perPage,
                                    onChange:onPaginationChange
                                }}
                            renderItem={item => (
                                <List.Item>

                                    <div className='attachment-list'>
                                        {item.type === 1 && <div className='attachment' onClick={()=>{
                                            setThumb({src: item.url })
                                            setVisible(true)
                                        }} style={{
                                            borderWidth: 3,
                                            borderStyle: 'solid',
                                            borderColor: isSelect(item) ? '#07c160' : '#ffffff',
                                            backgroundImage: `url(${item.url + "?x-oss-process=image/resize,h_330,limit_0"})`

                                        }}/>}
                                        {item.type === 2 && <div className='attachment' onClick={() => playerRef.current.onOpen(item.url)} style={{
                                            borderWidth: 3,
                                            borderStyle: 'solid',
                                            borderColor: isSelect(item) ? '#07c160' : '#ffffff',
                                            backgroundImage: `url(${item.url + "?x-oss-process=video/snapshot,t_1000,m_fast,f_jpg,h_400"})`

                                        }}>
                                            <div className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
                                                <PlayCircleFilled className="text-white fs-3 opacity-75"/>
                                            </div>
                                        </div>
                                        }
                                        {item.type === 3 && <div className='attachment' style={{
                                            borderWidth: 3,
                                            borderStyle: 'solid',
                                            borderColor: isSelect(item) ? '#07c160' : '#ffffff',
                                        }}>
                                            <div onClick={() => window.open('about:blank').location.href=item.url}>
                                                <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                                                    <div className='text-center '>
                                                        <DiffFilled className="text-success fs-2"/>
                                                        <div className="mt-2" >{subText(item.name , 8)}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        }

                                        <div onClick={() => onSelectChange(item)}
                                             className="d-flex attachment-select-image">
                                            <div
                                                className="rounded-circle shadow d-flex justify-content-center align-items-center select-image-item"
                                                style={{
                                                    backgroundColor: isSelect(item) ? '#07c160' : '#ffffff',

                                                }}>
                                                <CheckOutlined style={{color: isSelect(item) ? '#ffffff' : '#000000'}}/>
                                            </div>
                                            <div onClick={() => confirm([item])}
                                                 className="rounded-circle d-flex shadow justify-content-center align-items-center select-image-item ms-1">
                                                <DeleteOutlined/>
                                            </div>
                                        </div>

                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>

                </div>
            </div>
            <Player refs={playerRef}/>
            <Image
                width={100}
                style={{ display: 'none' }}
                src={thumb.src + "?x-oss-process=image/resize,h_330,limit_0"}
                preview={{
                    visible,
                    src: thumb.src,
                    onVisibleChange: value => {
                        setThumb({src: ''})
                    },
                }}
            />
        </Spin>
    )
}
export default Attachment;
