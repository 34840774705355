/**
 * Notes: 用户订单
 * User: 李强
 * DateTime: 2021/12/10 14:28
 * Ide: PhpStorm
 */

import React, {useCallback, useEffect, useState} from 'react'

import {
    Badge, message, Space, Table, Typography
} from 'antd';
import {Link, withRouter} from 'react-router-dom'
import {Icon} from "../../Config";
import {useHttps} from "../../hooks";
const {Text} = Typography;
/*
  用户管理
*/
const UserOrder = (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)

    useEffect(() => {

    }, []);

    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/shop/order' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15, user_id:props.id});
    }, [getData, props.id]);



    const columns = [
        {
            title: '订单号',
            dataIndex: 'order_sn',
            render: (sn, item) => {
                return (
                    <Link to={{pathname: '/order/user/' + item.id}}>
                        <Text>#{sn}</Text>
                    </Link>

                )
            }
        },
        {
            title: '订单金额',
            dataIndex: 'real_price',
            sorter: (a, b) => a.real_price - b.real_price,
            render: (price) => {
                return (
                    <div>
                        <Text>¥{price}</Text>
                    </div>

                )
            }
        },
        {
            title: '支付方式',
            dataIndex: 'pay_type',
            render: (pay) => {
                return (
                    <div>
                        {pay === 2 && <Space>
                            <img
                                style={{width: 22, height: 22}}
                                src={Icon.wechatPay}
                                alt={'微信支付'}/>
                            <Text>微信支付</Text>
                        </Space>
                        }
                        {pay === 1 && <Space>
                            <img
                                style={{width: 22, height: 22}}
                                src={Icon.alipay}
                                alt={'支付宝'}/>
                            <Text>支付宝</Text>
                        </Space>
                        }
                    </div>
                )
            }
        },
        {
            title: '下单日期',
            dataIndex: 'created_at',
            render: (created_at) => {
                return (
                    <div>
                        <Text>{created_at}</Text>
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status , item) => {
                //state
                return (
                    <div>
                        {status === 1 ?
                            <Badge
                                count={'已支付'}
                                style={{backgroundColor: '#52c41a'}}
                            /> :
                            <Badge
                                count={'待支付'}
                                style={{backgroundColor: '#fa8c16'}}
                            />
                        }
                    </div>
                )
            }
        },
    ];

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            user_id:props.id
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    return (
            <div className='bg-white px-4'>
                <Table
                    size='middle'
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        size:'default',
                        total: total,
                        current: current,
                        showTotal: total => `总计 ${total} 份订单`,
                        pageSize: perPage,
                    }}
                    onChange={onPaginationChange}
                    rowKey={(record) => {
                        return record.id
                    }}
                />
            </div>
    )
}

export default UserOrder
