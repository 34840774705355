/**
 * Created by devin on 2021/4/23.
 */

import React, {useState, useRef, useEffect} from 'react'
import {useNavigate, useParams, withRouter} from 'react-router-dom'
import {Button, message, Divider, Form, Input, Radio, Spin, Modal, Empty, Breadcrumb} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";

import 'react-quill/dist/quill.snow.css';
import {Editor, ThumbSelect} from '../../components'
import {useHttps} from "../../hooks";


const Edit = (props) => {
    const {id} = useParams();
    const {put , get , destroy} = useHttps();
    const [thumb, setThumb] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [content, setContent] = useState('');
    const formRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        get('admin/page/' + id, null , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setThumb(res.data.thumb)
                    formRef.current.setFieldsValue(res.data)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setErrorText(e.message);
                    setError(true);
                }
            });
    }, [get, id]);


    /**
     * 更新页面内容
     * @param params
     */
    const onFinish = (params) => {
        setLoading(true)
        put('admin/page/' + id, params)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('页面已更新成功！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 删除页面
     */
    const onDelete = () => {
        setLoading(true)
        destroy('admin/page/' + id, )
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('页面已删除成功！');
                    navigate(-1);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除这个页面吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }




    /**
     * 错误显示
     */

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >页面</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部页面</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>编辑</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{ height: 160 }}/>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-dark' >页面</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部页面</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item  className='text-primary'>编辑</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <div>
                            <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                                返回列表
                            </Button>
                            <Button onClick={() => showConfirm()} type="primary" danger>
                                删除
                            </Button>
                        </div>
                        <Divider/>
                        <div>
                            <Form
                                ref={formRef}
                                labelCol={{span: 4}}
                                wrapperCol={{span: 16}}
                                name="basic"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="页面标题"
                                    name="title"
                                    rules={[{required: true, message: '请输入标题！'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="链接"
                                    name="link"
                                    rules={[{type: 'url'}]}
                                    extra="如果需要链接到外部网页，请输入https://开头地址"
                                >
                                    <Input placeholder='https://'/>
                                </Form.Item>
                                <Form.Item
                                    label="封面图"
                                    name='thumb'
                                    extra="上传尺寸建议980x460"
                                    className="mt-3"
                                    rules={[{
                                        required: true,
                                        message: '请选择封面图片！',
                                        validator: async (rule, value) => {
                                            if (!thumb) {
                                                return Promise.reject()
                                            }
                                        }
                                    }
                                    ]}
                                >
                                    <div className='w-100'>
                                        <ThumbSelect src={thumb}
                                                     onChange={(e) => {
                                                         setThumb(e);
                                                         formRef.current.setFieldsValue({thumb: e})
                                                     }}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item label="页面内容" name="content"
                                           rules={[{
                                               required: true,
                                               message: '请输入20字符以上内容。！',
                                               validator: async (rule, value) => {
                                                   if (value.length <= 20) {
                                                       return Promise.reject()
                                                   }
                                               }
                                           }
                                           ]}
                                >
                                    <Editor
                                        value={content}
                                        onChange={setContent}
                                    />
                                </Form.Item>
                                <Form.Item name='description' label="摘要">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item
                                    name="status"
                                    label="是否显示"
                                >
                                    <Radio.Group value={0}>
                                        <Radio value={0}>隐藏</Radio>
                                        <Radio value={1}>显示</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item wrapperCol={{offset: 4}}>
                                    <Button className='me-3' type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                    <Button className='me-2' onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>


                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default Edit;
