/**
 * Created by devin on 2021/4/21.
 */

import React, {useState, useEffect, useCallback} from 'react'


import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Select,
    Avatar,
    List,
    Comment,
    Modal,
    message,
    Checkbox,
    Space,
    Breadcrumb,
    Tooltip,
    Badge, Typography
} from 'antd';
import {
    CheckCircleOutlined,
    MinusCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {Link} from 'react-router-dom'
import {useHttps} from "../../hooks";
import moment from "moment";
import {subText} from "../../utils/commonfn";
const {Paragraph} = Typography;

const BlogComment = (props) => {
    const {put, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(24)
    const [status, setStatus] = useState(3)
    const [search, setSearch] = useState('')


    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/blog/comment', params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    }, [get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 24});
    }, [getData]);


    /**
     * 删除列表操作
     * @param {Array} keys  数组id
     */
    const onDelete = () => {
        setLoading(true);
        destroy('admin/blog/comment/batch', {id: selectedRowKeys})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };

    /**
     * 更新列表操作
     * @param {Array} keys  数组id
     * @param {int} status
     */
    const onUpdate = (status) => {
        setLoading(true);
        put('admin/blog/comment/batch', {id: selectedRowKeys, status: status})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };

    /**
     * 表格操作触发
     * @param pagination
     * @param pageSize
     */
    const onPaginationChange = (pagination, pageSize) => {

        setLoading(true);

        const params = {
            page: pagination,
            per_page: pageSize,
        }

        if (status !== 3) {
            params.status = status;
        }

        if (search.length !== 0) {
            params.search = search;
        }
        window.location.hash = `#${pagination}`;
        getData(params);
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 选择列表
     * @param key
     */
    const onSelectChange = (key) => {
        let dataSource = [...selectedRowKeys];
        const index = selectedRowKeys.findIndex(id => id === key)
        if (index >= 0) {
            dataSource.splice(index, 1)
        } else {
            dataSource.push(key)
        }
        setSelectedRowKeys(dataSource)


    };

    /**
     * 全选处理
     * @param e
     */
    const onAllChange = (e) => {
        const checked = e.target.checked;
        const checkedData = []
        if (checked) {
            data.forEach(item => {
                checkedData.push(item.id)
            })
        }
        setSelectedRowKeys(checkedData)
    }


    /**
     * 判断列表项是否选中
     * @param id
     * @returns {*}
     */
    const isCheckbox = (id) => {
        return selectedRowKeys.find(val => val === id) != null;
    }


    /**
     * 重置
     */
    const onReset = () => {
        setStatus(3)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: 24});
    }


    /**
     * 返回视图
     */

    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                        width: 5,
                        height: 14
                    }}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item className='text-dark'>博客</Breadcrumb.Item>
                    <Breadcrumb.Separator/>
                    <Breadcrumb.Item className='text-primary'>博客评论</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='p-2'>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Space>
                            <Checkbox onChange={onAllChange}>全选</Checkbox>
                            <Button onClick={() => onUpdate(1)} icon={<MinusCircleOutlined/>}
                                    disabled={selectedRowKeys.length === 0}
                                    size='small'>拒绝</Button>
                            <Button onClick={() => onUpdate(2)} icon={<CheckCircleOutlined/>}
                                    disabled={selectedRowKeys.length === 0}
                                    size='small'>通过</Button>
                            <Button onClick={showConfirm} icon={<DeleteOutlined/>}
                                    disabled={selectedRowKeys.length === 0}
                                    size='small'>删除</Button>
                            <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 篇博客` : ''}
                        </span>
                        </Space>
                    </Col>
                    <Col>
                        <Space>

                            <Select style={{width: 160}} value={status} onChange={setStatus}>
                                <Select.Option value={3}>全部状态</Select.Option>
                                <Select.Option value={0}>待审核</Select.Option>
                                <Select.Option value={1}>已拒绝</Select.Option>
                                <Select.Option value={2}>已通过</Select.Option>
                            </Select>
                            <Space>
                                <Input.Search
                                    style={{width: 300}}
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={(e) => {
                                        onPaginationChange({current: 1, pageSize: perPage})
                                    }}
                                    placeholder="输入关键词搜索"
                                    allowClear
                                    enterButton="筛选"
                                />

                                <Button onClick={onReset}>
                                    重置
                                </Button>
                            </Space>
                        </Space>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <div>
                        <List
                            loading={loading}
                            grid={{gutter: 16, column: 3}}
                            dataSource={data}
                            pagination={{
                                defaultCurrent: 1,
                                total: total,
                                current: current,
                                showTotal: total => `总计 ${total} 条评论`,
                                pageSize: perPage,
                                onChange: onPaginationChange
                            }}

                            renderItem={item => (
                                <List.Item key={item.id}>
                                    <div className='bg-white px-3'>
                                        <Comment
                                            actions={[
                                                <div className='position-absolute top-0 end-0'>
                                                    <Checkbox checked={isCheckbox(item.id)}
                                                              onChange={() => onSelectChange(item.id)}/>
                                                </div>,
                                                <Link to={'/blog/edit/' + item.blog.id}>
                                                <div className='fs-7 text-muted'>
                                                    <span className='text-primary me-2'>@ {item.blog.user.nickname} </span>
                                                    {subText(item.blog.content, 15)}
                                                </div>
                                                </Link>
                                            ]}
                                            author={item.user.nickname}
                                            avatar={
                                                <Avatar src={item.user.avatar} alt={item.user.nickname}/>
                                            }
                                            content={
                                                <div style={{height: 25,  marginBottom:35}}>
                                                    <Link to={'/blog/edit/' + item.blog.id}>
                                                        <Tooltip title={item.content}>
                                                            <div className='text-dark'>
                                                                <Paragraph ellipsis={{ rows: 2}}>
                                                                   {item.content}
                                                                </Paragraph>
                                                            </div>
                                                        </Tooltip>
                                                    </Link>
                                                </div>
                                            }
                                            datetime={
                                                <Space>
                                                    <Tooltip
                                                        title={moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                                                        <span>{moment(item.created_at).fromNow()}</span>
                                                    </Tooltip>
                                                    {item.status === 0 && <Badge count="待审核" color="yellow"/>}
                                                    {item.status === 1 && <Badge count="已拒绝" color="red"/>}
                                                    {item.status === 2 && <Badge count="已拒绝" color="green"/>}
                                                </Space>
                                            }
                                        />
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogComment;
