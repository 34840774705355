import React, {useEffect, useState} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import {useHttps} from "../../hooks";
import {Empty, Skeleton, Typography} from "antd";
import moment from "moment";


const Pages = (props) => {

	const {get} = useHttps();
	const {id} = useParams();
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const [data, setData] = useState([])

	useEffect((params) => {
		get('page/' + id)
			.then((res) => {
				if (res.mounted) {
					setLoading(false);
					setData(res.data);
				}
			})
			.catch((e) => {
				if (e.mounted) {
					setLoading(false);
					setError(true)
				}
			});
	},[get, id])

	/**
	 * 内容加载
	 */
	if (loading) {
		return (
			<div>
				<div className='bg-white px-4' style={{height: 520}}>
					<Skeleton />
					<Skeleton className='mt-5'/>
					<Skeleton />
				</div>
			</div>
		)
	}

	/**
	 * 内容加载
	 */
	if (error) {
		return (
			<div>
				<div className='bg-white p-4' style={{height: 520}}>
					<Empty />
				</div>
			</div>
		)
	}

	return (
		<div>
			<div className='bg-white p-4 webview' >
				<Typography.Title level={4}>{data.title}</Typography.Title>
				<Typography.Text type="secondary">发布于：{moment(data.created_at).format('YYYY-MM-DD')}</Typography.Text>
				<div className='mt-3' dangerouslySetInnerHTML={{__html:data.content}}>
				</div>
			</div>
		</div>


	);
};

export default Pages;
