/**
 * Created by devin on 2021/4/23.
 */

import React, {useState, useRef, useEffect} from 'react'
import {useNavigate, useParams, withRouter} from 'react-router-dom'
import {
    Button,
    message,
    Divider,
    Form,
    Input,
    Radio,
    Spin,
    Modal,
    Empty,
    Breadcrumb, Space, Avatar
} from "antd";
import {
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {Editor, ThumbSelect} from "../../components";
import {useHttps} from "../../hooks";


const Edit = (props) => {
    const {id} = useParams();
    const {put, get, destroy} = useHttps();
    const [data, setData] = useState(null)
    const [thumb, setThumb] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [content, setContent] = useState('');
    const formRef = useRef();
    const navigate = useNavigate();
    useEffect(() => {
        get('admin/news/' + id, null , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    formRef.current.setFieldsValue(res.data)
                    setThumb(res.data.thumb)
                    setData(res.data)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setErrorText(e.message);
                    setError(true);
                }

            });
    }, [get, id]);

    /**
     * 更新新闻内容
     * @param params
     */
    const onFinish = (params) => {
        setLoading(true)
        put('admin/news/' + id, {status:params.status})
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('新闻已更新成功！');
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 删除新闻
     */
    const onDelete = () => {
        setLoading(true)
        destroy('admin/news/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('新闻已删除成功！');
                    navigate(-1);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除这篇新闻吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }




    /**
     * 错误显示
     */

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >新闻</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部新闻</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>编辑</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{ height: 160 }}/>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-dark' >新闻</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部新闻</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item  className='text-primary'>编辑</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <div>
                            <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                                返回列表
                            </Button>
                            <Button onClick={() => showConfirm()} type="primary" danger>
                                删除
                            </Button>
                        </div>
                        <Divider/>
                        <div>
                            <Form
                                ref={formRef}
                                labelCol={{span: 4}}
                                wrapperCol={{span: 16}}
                                name="basic"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="发布者"
                                >
                                    {data && <div>
                                        <Space>
                                        <Avatar shape="square"  size={20}  src={data.mch.logo} alt={data.mch.name} />
                                        <span>{data.mch.name}</span>
                                    </Space>
                                    </div>}
                                </Form.Item>

                                <Form.Item
                                    label="新闻标题"
                                    name="title"
                                    rules={[{required: true, message: '请输入标题！'}]}
                                >
                                    <Input disabled/>
                                </Form.Item>
                                <Form.Item
                                    label="封面图"
                                    name='thumb'
                                    extra="上传尺寸建议980x460"
                                    className="mt-3"
                                    rules={[{required: true, message: '请选择缩略图！'}]}
                                >
                                    <div className='w-100'>
                                        <ThumbSelect disabled src={thumb}
                                                     onChange={(e) => {
                                                         setThumb(e);
                                                         formRef.current.setFieldsValue({thumb: e})
                                                     }}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item label="新闻内容" name="content"
                                           rules={[{
                                               required: true,
                                               message: '请输入20字符以上内容。！',
                                               validator: async (rule, value) => {
                                                   if (value.length <= 20) {
                                                       return Promise.reject()
                                                   }
                                               }
                                           }
                                           ]}
                                >
                                    <Editor
                                        disabled
                                        value={content}
                                        onChange={setContent}
                                    />
                                </Form.Item>
                                <Form.Item name='description' label="摘要">
                                    <Input.TextArea disabled/>
                                </Form.Item>
                                <Form.Item
                                    name="status"
                                    label="审核状态"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>待审核</Radio>
                                        <Radio value={1}>拒绝</Radio>
                                        <Radio value={2}>通过</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item wrapperCol={{offset: 4}}>
                                    <Button className='me-3' type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                    <Button className='me-2' onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default Edit;
