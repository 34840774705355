/**
 * Notes: 用户订单
 * User: 李强
 * DateTime: 2021/12/10 14:28
 * Ide: PhpStorm
 */

import React, {useCallback, useEffect, useState} from 'react'

import {
    Badge, message, Rate, Space, Table,Typography
} from 'antd';
import {Link, withRouter} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Thumb} from "../../components";
const {Text, Paragraph} = Typography;
/*
  用户管理
*/
const MchGoods= (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)



    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/shop/goods' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15, mch_id:props.id});
    }, [getData, props.id]);



    const columns = [
        {
            title: '商品',
            dataIndex: 'title',
            width: 360,
            render: (title, item) => {
                return (
                    <Link to={{pathname: '/goods/details/' + item.id}}>
                        <Space>
                            <Thumb
                                width={48}
                                height={48}
                                src={item.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                            />
                            <Paragraph className='mb-0 me-5' ellipsis={{rows: 2}} level={5}>{title}</Paragraph>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '价格',
            dataIndex: 'market_price',
            sorter: (a, b) => a.market_price - b.market_price,
            render: (price) => {
                return (
                    <div>
                        <Text>¥{price}</Text>
                    </div>

                )
            }
        },
        {
            title: '销量',
            dataIndex: 'sales',
            sorter: (a, b) => a.sales - b.sales,
            render: (sales) => {
                return (
                    <div>
                        <Text>{sales}</Text>
                    </div>
                )
            }
        },
        {
            title: '评分',
            dataIndex: 'score',
            sorter: (a, b) => a.score - b.score,
            render: (score) => {
                return (
                    <div>
                        <Rate style={{fontSize:14}} disabled defaultValue={score} />
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status , item) => {
                //state
                return (
                    <div>
                        {status === 2 ?
                            <Badge
                                count={item.state ? '销售中' : '已下架'}
                                style={{backgroundColor: item.state ? '#52c41a' : '#6c757d'}}
                            /> :
                            <Badge
                                count={status === 1 ? '已拒绝' : '待审核'}
                                style={{backgroundColor: status === 1 ? '#f5222d' : '#fa8c16'}}
                            />
                        }
                    </div>
                )
            }
        },
    ];

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            mch_id:props.id
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    return (
        <div className='bg-white px-4'>
            <Table
                size='middle'
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    size:'default',
                    total: total,
                    current: current,
                    showTotal: total => `总计 ${total} 件商品`,
                    pageSize: perPage,
                }}
                onChange={onPaginationChange}
                rowKey={(record) => {
                    return record.id
                }}
            />
        </div>
    )
}

export default MchGoods
