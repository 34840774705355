import React from 'react';
import { Routes, Route, } from "react-router-dom";
import { routes } from '../../routers/router';
import Error404 from "../Error/Error404";

const MainContent = () => {
    //请求初始化数据
  return (
      <Routes>
          {routes.map(
              (ele) => {
                  return (
                      <Route element={<ele.component/>} key={ele.path} path={ele.path}/>
                  )
              })}
          <Route path="*" element={<Error404 />} />
      </Routes>

  )
}

export default MainContent;
