import React from 'react';
import wx from "weixin-js-sdk";

const News = props => {

	const wxMessage= () => {
		console.log('wenview')
		//后退传惨
		wx.miniProgram.postMessage({
			data: {
				imgData: 'remoteUrl',
			},
		});
		//传惨
		wx.miniProgram.navigateTo({url: '/pages/login/index'})
	}

	return (
		<div className='mx-3 mt-5 webview'>
			<div className='bg-white p-4'>
				<div className='bg-white p-4'>
					文章页面
				</div>
			</div>
		</div>


	);
};

export default News;
