/**
 * Notes: 用户分类
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useState, useRef, useEffect, useCallback} from 'react'
import {Button, message, Divider, Form, Input, Col, Row, Table, Popconfirm, Breadcrumb, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

import {Attachment, ThumbSelect, Thumb} from "../../components";
import {useRecoilState} from "recoil";
import {articleCategoryState} from "../../store";
import {useHttps} from "../../hooks";
import {subText} from "../../utils/commonfn";
const {Title} = Typography;
const Category = (props) => {

    const [category, setCategory] = useRecoilState(articleCategoryState);
    const [loading, setLoading] = useState(true)
    const [sendLoading, setSendLoading] = useState(false)
    const {put, get, destroy, post} = useHttps();
    const [thumb, setThumb] = useState(null)
    const [thumbType, setThumbType] = useState(null)
    const [editItem, setEditItem,] = useState(0)
    const [editVisible, setEditVisible] = useState(false)
    const [descItem, setDescItem,] = useState(0)
    const [descVisible, setDescVisible] = useState(false)
    const formRef = useRef();
    const selectRef = useRef();

    /**
     * 分类数据
     * @param {Object} params
     */
    const getData = useCallback(() => {
        get('admin/article/category', null, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    setCategory(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                }
            });
    }, [get, setCategory])


    useEffect(() => {
        getData()
    }, [getData]);


    /**
     * 更新分类
     */



    const onUpdate = (value, item, field) => {
        setDescVisible(false)
        setEditVisible(false)
        if (value === item[field]) {
            return
        }

        if (item[field] == null && value.length === 0) {
            return
        }
        const dataSource = [...category];
        const select = dataSource.findIndex((v) => v.id === item.id);
        dataSource[select][field] = value;
        setCategory(dataSource)
        setLoading(true)
        item[field] = value;
        put('admin/article/category/' + item.id, item)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('分类已更新！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    };

    /**
     * 删除分类
     * @param id
     */


    const onDelete = (id) => {
        setLoading(true)
        destroy('admin/article/category/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('删除添加成功！');
                    const dataSource = [...category];
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setCategory(dataSource)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 添加分类
     * @param params
     */


    const onFinish = (params) => {
        setSendLoading(true)
        post('admin/article/category', params)
            .then((res) => {
                if (res.mounted) {
                    setSendLoading(false)
                    message.success('分类添加成功！');
                    const dataSource = [...category];
                    dataSource.unshift(res.data);
                    setCategory(dataSource)
                    setThumb(null)
                    formRef.current.setFieldsValue({thumb: null, description: '', name: ''})
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setSendLoading(false)
                    message.error(e.message);
                }

            });
    }


    /**
     * 重置
     */
    const onReset = () => {

        window.location.hash = '#1';
        setThumb(null)
        formRef.current.setFieldsValue({thumb: null, description: '', name: ''})

    }
    /**
     * 表格设置
     * @param item
     */

    const columns = [

        {
            title: 'ID',
            dataIndex: 'id',
            render: (text) => {
                return (
                    <span>{text}</span>
                )

            },
        },
        {
            title: '图标',
            dataIndex: 'thumb',
            editable: true,
            render: (thumb, item) => {
                return (
                    <Thumb onClick={() => {
                        setThumbType(item)
                        selectRef.current.onOpen(1)
                    }}
                           width={30}
                           height={30}
                           src={thumb}
                    />
                )

            },
        },
        {
            title: '分类名称',
            dataIndex: 'name',
            width: 140,
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {editVisible && editItem === item.id ?
                            <Input
                                maxLength={8}
                                autoFocus={editVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'name')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'name')}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setEditItem(item.id)
                                setEditVisible(true)
                            }}>{text}</div>}
                    </div>
                )

            },
        },
        {
            title: '分类描述',
            dataIndex: 'description',
            width: 200,
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {descVisible && descItem === item.id ?
                            <Input
                                maxLength={20}
                                autoFocus={descVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'description')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'description')}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setDescItem(item.id)
                                setDescVisible(true)
                            }}> {subText(text , 8)}
                        </div>}
                    </div>
                )

            },
        },
        {
            title: '内容',
            dataIndex: 'total',
            render: (text) => {
                return (
                    <span>{text}</span>
                )

            },
        },
        {
            title: '操作',
            key: 'action',
            render: (key, item) => {
                return (
                    <div>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete(item.id)}>
                            <Button className='text-muted' type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                        width: 5,
                        height: 14
                    }}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item className='text-dark'>文章</Breadcrumb.Item>
                    <Breadcrumb.Separator/>
                    <Breadcrumb.Item className='text-primary'>文章分类</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='p-2'>
                <Row gutter={[24, 24]}>
                    <Col span={16}>

                        <Table
                            loading={loading}
                            rowKey={(record) => {
                                return record.id
                            }}
                            columns={columns}
                            dataSource={category}
                            pagination={{
                                total: category.length,
                                defaultCurrent: 1,
                                showTotal: total => `总计 ${total} 个分类`,
                            }}/>
                    </Col>

                    <Col span={8}>
                        <div className='bg-white p-4'>
                            <Title level={5}>添加新分类</Title>
                            <div className='mt-4'>
                                <Form
                                    initialValues={{description: ''}}
                                    labelCol={{ span: 6 }}
                                    ref={formRef}
                                    name="basic"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        label="分类名称"
                                        name="name"
                                        rules={[{required: true, message: '请输入分类名称！'}]}
                                    >
                                        <Input maxLength={8} placeholder="请输入分类名称"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="分类图标"
                                        name='thumb'
                                        className="mt-3"
                                    >

                                        <div className='w-100'>
                                            <ThumbSelect src={thumb}
                                                         size={60}
                                                         onChange={(e) => {
                                                             setThumb(e);
                                                             formRef.current.setFieldsValue({thumb: e})
                                                         }}
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item name='description' label="分类描述">
                                        <Input.TextArea maxLength={20}/>
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 6}}>
                                        <Button loading={sendLoading} className='me-3' type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                        <Button className='me-2' onClick={onReset}>
                                            重置
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </Col>
                </Row>,

            </div>
            <Attachment refs={selectRef} onChange={(item)=> onUpdate(item[0].url, thumbType, 'thumb')}/>
        </div>
    )
}
export default Category;
