/**
 * Notes: 用户等级
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useState, useRef, useEffect, useCallback} from 'react'
import {Button, message, Divider, Form, Input, Col, Row, Table, Popconfirm, Breadcrumb, Typography} from "antd";
import { DeleteOutlined} from "@ant-design/icons";
import {Icon} from "../../Config";
import {Attachment, ThumbSelect} from "../../components";
import {useRecoilState} from "recoil";
import {userGradeState} from "../../store";
import {useHttps} from "../../hooks";
import {subText} from "../../utils/commonfn";
const {Title} = Typography;

const Grade = (props) => {
    const [grade, setGrade] =  useRecoilState(userGradeState);
    const [loading, setLoading] = useState(true)
    const [sendLoading, setSendLoading] = useState(false)
    const {put, get, destroy, post} = useHttps();
    const [thumb, setThumb] = useState(null)
    const [thumbType, setThumbType] = useState(null)
    const [editItem, setEditItem,] = useState(0)
    const [editVisible, setEditVisible] = useState(false)
    const [descItem, setDescItem,] = useState(0)
    const [descVisible, setDescVisible] = useState(false)
    const [slugItem, setSlugItem,] = useState(0)
    const [slugVisible, setSlugVisible] = useState(false)
    const formRef = useRef();
    const selectRef = useRef();

    /**
     * 等级数据
     * @param {Object} params
     */
    const getData = useCallback( () => {
        get('admin/user/grade', null , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    setGrade(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                }
            });
    },[get, setGrade])



    useEffect(() => {
        getData()
    }, [getData]);


    /**
     * 更新等级
     */

    const onUpdate = (value, item, field) => {
        setDescVisible(false)
        setEditVisible(false)
        setSlugVisible(false)
        if (value === item[field]) {
            return
        }

        if (item[field] == null &&  value.length === 0) {
            return
        }
        const dataSource = [...grade];

        const select = dataSource.findIndex((v) => v.id === item.id);
        dataSource[select][field] = value;
        setGrade(dataSource)
        setLoading(true)
        item[field] = value;
        put('admin/user/grade/' + item.id, item)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('等级已更新！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    };

    /**
     * 删除等级
     * @param id
     */
    const onDelete = (id) => {
        setLoading(true)
        destroy('admin/user/grade/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('删除添加成功！');
                    const dataSource = [...grade];
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setGrade(dataSource)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 添加等级
     * @param params
     */
    const onFinish = (params) => {
        setSendLoading(true)
        post('admin/user/grade', params)
            .then((res) => {
                if (res.mounted) {
                    setSendLoading(false)
                    message.success('等级添加成功！');
                    const dataSource = [...grade];
                    dataSource.unshift(res.data);
                    setGrade(dataSource)
                    setThumb(null)
                    formRef.current.setFieldsValue({thumb: null, description: '', name: ''})
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setSendLoading(false)
                    message.error(e.message);
                }

            });
    }




    /**
     * 表格设置
     * @param item
     */

    const columns = [

        {
            title: 'ID',
            dataIndex: 'id',
            render: (text) => {
                return (
                    <span>{text}</span>
                )

            },
        },
        {
            title: '图标',
            dataIndex: 'thumb',
            editable: true,
            render: (thumb, item) => {
                return (
                    <img onClick={() => {
                        setThumbType(item)
                        selectRef.current.onOpen()
                    }} className='rounded' style={{width: 30}}
                         src={!thumb || thumb.length === 0 ? Icon.emptyIcon : thumb + '?x-oss-process=image/resize,w_40,h_40,m_fill,limit_0'} alt="tag"/>
                )

            },
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 140,
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {editVisible && editItem === item.id ?
                            <Input
                                maxLength={6}
                                autoFocus={editVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'name')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'name')}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setEditItem(item.id)
                                setEditVisible(true)
                            }}>{text}</div>}
                    </div>
                )

            },
        },
        {
            title: '标识',
            dataIndex: 'slug',
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {slugVisible && slugItem === item.id ?
                            <Input
                                autoFocus={slugVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'slug')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'slug')}
                            />
                            : <div className='editable-cell-value-wrap w-100' onClick={() => {
                                setSlugItem(item.id)
                                setSlugVisible(true)
                            }}> {text}</div>}
                    </div>
                )

            },
        },
        {
            title: '描述',
            dataIndex: 'description',
            width: 200,
            editable: true,
            render: (text, item) => {
                return (
                    <div>
                        {descVisible && descItem === item.id ?
                            <Input
                                maxLength={20}
                                autoFocus={descVisible}
                                defaultValue={text}
                                onBlur={(e) => onUpdate(e.target.value, item, 'description')}
                                onPressEnter={(e) => onUpdate(e.target.value, item, 'description')}
                            />
                            : <div className='editable-cell-value-wrap' onClick={() => {
                                setDescItem(item.id)
                                setDescVisible(true)
                            }}>{subText(text , 8)}</div>}
                    </div>
                )

            },
        },
        {
            title: '会员',
            dataIndex: 'total',
            render: (text) => {
                return (
                    <span>{text}</span>
                )

            },
        },
        {
            title: '操作',
            key: 'action',
            render: (key, item) => {
                return (
                    <div>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete(item.id)}>
                            <Button className='text-muted' type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];

    /**
     * 重置
     */
    const onReset = () => {
        setThumb(null)
        formRef.current.setFieldsValue({thumb: null, description: '',slug:'', name: ''})

    }
    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item  className='text-dark' >会员</Breadcrumb.Item>
                    <Breadcrumb.Separator />
                    <Breadcrumb.Item className='text-primary'>会员等级</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='p-2'>
                <Row gutter={[24, 24]}>
                    <Col span={16}>

                        <Table
                            loading={loading}
                            rowKey={(record) => {
                                return record.id
                            }}
                            columns={columns}
                            dataSource={grade}
                            pagination={{
                                total: grade.length,
                                defaultCurrent:1,
                                showTotal: total => `总计 ${total} 种等级`,
                            }}/>
                    </Col>

                    <Col span={8}>
                        <div className='bg-white p-4'>
                            <Title level={5}>添加新等级</Title>
                            <div className='mt-4'>
                                <Form
                                    initialValues={{description: ''}}
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 0}}
                                    ref={formRef}
                                    name="basic"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        label="等级名称"
                                        name="name"
                                        rules={[{required: true, message: '请输入等级名称！'}]}
                                    >
                                        <Input maxLength={8} placeholder="请输入等级名称"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="等级"
                                        name="slug"
                                        rules={[{required: true, message: '请输入等级标识！'}]}
                                    >
                                        <Input placeholder="请输入以等级标识"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="图标"
                                        name='thumb'
                                        className="mt-3"
                                        rules={[{required: true, message: '请输入等级图标！'}]}
                                    >
                                        <div className='w-100'>
                                            <ThumbSelect src={thumb}
                                                         size={60}
                                                         onChange={(e) => {
                                                             setThumb(e);
                                                             formRef.current.setFieldsValue({thumb: e})
                                                         }}
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item name='description' label="等级描述">
                                        <Input.TextArea maxLength={20} />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 3}}>
                                        <Button loading={sendLoading} className='me-3' type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                        <Button className='me-2' onClick={onReset}>
                                            重置
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </Col>
                </Row>,

            </div>
            <Attachment refs={selectRef}  onChange={(item)=> onUpdate(item[0].url, thumbType, 'thumb')}/>
        </div>
    )
}
export default Grade;
