/**
 * Created by devin on 2021/4/23.
 */

import React, {useState, useRef, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
    Button,
    message,
    Divider,
    Form,
    Input,
    Radio,
    Spin,
    Modal,
    Empty,
    Breadcrumb, Space, Avatar
} from "antd";
import {
    ExclamationCircleOutlined, PlusOutlined,
} from "@ant-design/icons";

import {useHttps} from "../../hooks";



const Edit = (props) => {
    const {id} = useParams();
    const {put, get, destroy} = useHttps();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const formRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        get('admin/jobs/' + id, null , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    formRef.current.setFieldsValue(res.data)
                    setData(res.data)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setErrorText(e.message);
                    setError(true);
                }

            });
    }, [get, id]);

    /**
     * 更新招聘内容
     * @param params
     */
    const onFinish = (params) => {
        setLoading(true)
        put('admin/jobs/' + id, {status:params.status})
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('招聘已更新成功！');
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 删除招聘
     */
    const onDelete = () => {
        setLoading(true)
        destroy('admin/jobs/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('招聘已删除成功！');
                    navigate(-1);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除这篇招聘吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }




    /**
     * 错误显示
     */

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >招聘</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部招聘</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>编辑</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{ height: 160 }}/>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-dark' >招聘</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部招聘</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item  className='text-primary'>编辑</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <div>
                            <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                                返回列表
                            </Button>
                            <Button onClick={() => showConfirm()} type="primary" danger>
                                删除
                            </Button>
                        </div>
                        <Divider/>
                        <div>
                            <Form
                                ref={formRef}
                                labelCol={{span: 4}}
                                wrapperCol={{span: 16}}
                                name="basic"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="发布者"
                                >
                                    {data && <div>
                                        <Space>
                                            <Avatar shape="square"  size={30}  src={data.mch.logo} alt={data.mch.name} />
                                            <span>{data.mch.name}</span>
                                        </Space>
                                    </div>}
                                </Form.Item>
                                <Form.Item
                                    label="招聘标题"
                                    name="title"
                                    rules={[{required: true, message: '请输入标题！'}]}
                                >
                                    <Input disabled/>
                                </Form.Item>

                                <Form.Item
                                    name="type"
                                    label="招聘类型"
                                >
                                    <Radio.Group disabled>
                                        <Radio value={0}>招聘</Radio>
                                        <Radio value={1}>兼职</Radio>
                                        <Radio value={2}>实习</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="薪资待遇" className='mb-0' required>
                                    <Form.Item
                                        className='d-inline-block me-3'
                                        name="wage_start"
                                        rules={[{required: true, message: '请输入薪资！'}]}
                                    >
                                        <Input disabled placeholder='薪资' prefix="￥" type='number'  suffix="RMB"/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center  me-3'
                                          style={{lineHeight: '32px'}}>至</span>
                                    <Form.Item
                                        className='d-inline-block me-3'
                                        name="wage_end"
                                        rules={[{required: true, message: '请输入薪资！'}]}
                                    >
                                        <Input disabled placeholder='薪资' prefix="￥" type='number'  suffix="RMB"/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    name="wage_unit"
                                    label="薪资类型"
                                    rules={[{required: true,  message: '请输入薪资类型！'}]}
                                >
                                        <Radio.Group disabled>
                                            <Radio value={0}>月薪</Radio>
                                            <Radio value={1}>年薪</Radio>
                                            <Radio value={2}>日薪</Radio>
                                        </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="职位描述"
                                >
                                    <Form.List name="description">
                                        {(fields, {add, remove}) => (
                                            <>
                                                {fields.map(({key, name, ...restField}) => (
                                                    <div className='d-flex align-items-center align-content-center'
                                                         key={key}>
                                                        <Form.Item
                                                            className='me-3'
                                                            {...restField}
                                                            name={[name, 'key']}
                                                            rules={[{required: true, message: '标题'}]}
                                                        >
                                                            <Input disabled maxLength={8} placeholder="标题"/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            className='w-100 me-3'
                                                            {...restField}
                                                            name={[name, 'value']}
                                                            rules={[{required: true, message: '描述内容'}]}
                                                        >
                                                            <Input disabled maxLength={50} placeholder="输入描述内容说明"/>
                                                        </Form.Item>
                                                        {/*<div className='mb-4'>*/}
                                                        {/*    <MinusCircleOutlined onClick={() => remove(name)}/>*/}
                                                        {/*</div>*/}

                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button disabled onClick={() => add()} icon={<PlusOutlined/>}>
                                                        添加描述
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>

                                <Form.Item label="招聘简介" name="content"
                                           rules={[{
                                               required: true,
                                               message: '请输入20字符以上内容。！',
                                               validator: async (rule, value) => {
                                                   if (value.length <= 20) {
                                                       return Promise.reject()
                                                   }
                                               }
                                           }
                                           ]}
                                >
                                    <Input.TextArea disabled/>
                                </Form.Item>
                                <Form.Item
                                    name="status"
                                    label="审核状态"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>待审核</Radio>
                                        <Radio value={1}>拒绝</Radio>
                                        <Radio value={2}>通过</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item wrapperCol={{offset: 4}}>
                                    <Button className='me-3' type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                    <Button className='me-2' onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default Edit;
