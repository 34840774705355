import {atom, selector} from "recoil";
import {Api} from "../Config";




/**
 * 用户token
 */
const tokenState = atom({
    key: 'tokenState',
    default: null,
});


const userToken = selector({
    key: 'userToken',
    get:  ({ get }) => {
        const token = get(tokenState)
        if(token){
            return token
        }
        return sessionStorage.getItem("token") ;
    }
});



/**
 * 用户信息
 */
const userState = atom({
    key: 'userState',
    default: null,
    dangerouslyAllowMutability: true,
});



const userInfo = selector({
    key: 'userInfo',
    get:  ({ get }) => {
        const user = get(userState)
        if(user){
            return user
        }
        const getUser = JSON.parse(sessionStorage.getItem("user"));
        if( typeof getUser === 'object') {
            return getUser;
        }
        return null ;
    }
});







const collapsedStore = atom({
    key: 'collapsedStore',
    default: false,
});






 const ossSing = selector({
    key: 'ossSingSelector',
    get: async ({ get }) => {
        let response;
        try{
             response = await fetch(Api + 'upload/oss', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve({
            ...json,
        });
    }
});




/**
 * 商户分类
 */

const mchCategoryState = atom({
    key: 'mchCategoryState',
    default: [],
    dangerouslyAllowMutability: true,
});


const mchCategory = selector({
    key: 'mchCategory',
    get: async ({ get }) => {
        const mchCategory =  get(mchCategoryState)

        if(mchCategory.length !== 0){
            return Promise.resolve(mchCategory);
        }
        let response ;
        try{
            response = await fetch(Api + 'admin/mch/category', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});






/**
 * 商户标签
 */
const mchTagsState = atom({
    key: 'mchTagsState',
    default: [],
    dangerouslyAllowMutability: true,
});


const mchTags = selector({
    key: 'mchTags',
    get: async ({ get }) => {
       const mchTags =  get(mchTagsState)

        if(mchTags.length !== 0){
            return Promise.resolve(mchTags);
        }
        let response ;
        try{
            response = await fetch(Api + 'admin/mch/tags', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});


/**
 * 商品标签状态
 */

const goodsTagsState = atom({
    key: 'goodsTagsState',
    default: [],
    dangerouslyAllowMutability: true,
});


const goodsTags = selector({
    key: 'goodsTags',
    get: async ({ get }) => {
        const goodsTags =  get(goodsTagsState)

        if(goodsTags.length !== 0){
            return Promise.resolve(goodsTags);
        }
        let response ;
        try{
            response = await fetch(Api + 'admin/shop/goods/tags', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});


/**
 * 商品分类状态
 */

const goodsCategoryState = atom({
    key: 'goodsCategoryState',
    default: [],
    dangerouslyAllowMutability: true,
});

const goodsCategory = selector({
    key: 'goodsCategory',
    get: async ({ get }) => {
        const goodsCategory =  get(goodsCategoryState)

        if(goodsCategory.length !== 0){
            return Promise.resolve(goodsCategory);
        }
        let response ;
        try{
            response = await fetch(Api + 'admin/shop/goods/category', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});


/**
 * 文章分类状态
 */


const articleCategoryState = atom({
    key: 'articleCategoryState',
    default: [],
    dangerouslyAllowMutability: true,
});


const articleCategory = selector({
    key: 'articleCategory',
    get: async ({ get }) => {
        const articleCategory =  get(articleCategoryState)

        if(articleCategory.length !== 0){
            return Promise.resolve(articleCategory);
        }
        let response ;

        try{
            response = await fetch(Api + 'admin/article/category', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});

/**
 * 文章分类状态
 */


const blogCategoryState = atom({
    key: 'blogCategoryState',
    default: [],
    dangerouslyAllowMutability: true,
});

const blogCategory = selector({
    key: 'blogCategory',
    get: async ({ get }) => {
        const blogCategory =  get(blogCategoryState)

        if(blogCategoryState.length !== 0){
            return Promise.resolve(blogCategory);
        }
        let response ;

        try{
            response = await fetch(Api + 'admin/blog/category', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();

        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});
/**
 * 用户标签
 */

const userTagsState = atom({
    key: 'userTagsState',
    default: [],
    dangerouslyAllowMutability: true,
});

const userTags = selector({
    key: 'userTags',
    get: async ({ get }) => {
        const mchTags =  get(userTagsState)

        if(mchTags.length !== 0){
            return Promise.resolve(mchTags);
        }
        let response ;
        try{
            response = await fetch(Api + 'admin/user/tags', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});

/**
 * 用户等级
 */

const userGradeState = atom({
    key: 'userGradeState',
    default: [],
    dangerouslyAllowMutability: true,
});

const userGrade = selector({
    key: 'userGrade',
    get: async ({ get }) => {
        const userGrade =  get(userTagsState)
        if(userGrade.length !== 0){
            return Promise.resolve(userGrade);
        }
        let response ;
        try{
            response = await fetch(Api + 'admin/user/grade', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + get(userToken),
                },
            });
        }catch(error){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        const json = await response.json();
        if(json.code === 0){
            return Promise.reject({
                code : 0,
                message : '网络错误请稍后重试！',
                status : 503,
            });
        }

        return Promise.resolve(json.data);
    }
});


/**
 * 用户等级
 */

const AttachmentImageState = atom({
    key: 'AttachmentImageState',
    default: 1,
    dangerouslyAllowMutability: true,
});


export {
    AttachmentImageState,
    tokenState,
    userState,
    userInfo,
    collapsedStore,
    ossSing,
    mchTagsState,
    mchTags,
    mchCategoryState,
    mchCategory,
    goodsTags,
    goodsCategoryState,
    goodsCategory,
    goodsTagsState,
    userTags,
    userTagsState,
    userToken,
    articleCategoryState,
    articleCategory,
    userGrade,
    userGradeState,
    blogCategory,
    blogCategoryState,
};
