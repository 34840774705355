/**
 * Notes: 用户订单
 * User: 李强
 * DateTime: 2021/12/10 14:28
 * Ide: PhpStorm
 */

import React, {useCallback, useEffect, useState} from 'react'

import {
    Avatar,
    Badge, Card, List, message, Space, Typography
} from 'antd';
import {Link, withRouter} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Thumb} from "../../components";
import {subText} from "../../utils/commonfn";
import moment from "moment";
const {Text} = Typography;
/*
  用户管理
*/
const MchOrder = (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)



    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/activity' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15, mch_id:props.id});
    }, [getData, props.id]);



    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            mch_id:props.id
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    return (
        <div className='p-4'>
                <List
                    loading={loading}
                    grid={{ gutter: 16, column: 4}}
                    dataSource={data}
                    pagination={{
                        defaultCurrent: 1 ,
                        total: total,
                        current: current,
                        showTotal: total => `总计 ${total} 项活动`,
                        pageSize: perPage,
                        onChange: onPaginationChange
                    }}

                    renderItem={item => (
                        <List.Item key={item.id}>
                            <Card
                                cover={
                                    <div className='bg-dark position-relative'>
                                        <div className='position-absolute bottom-0 end-0 text-white me-2 mb-1 ' style={{zIndex:100}}><span className='text-white'>{item.apply_count} / {item.total}</span></div>
                                        <div className='opacity-75'>
                                            <Thumb onClick={()=> props.history.push({pathname: '/activity/details/' + item.id})}
                                                   rounded='rounded-0'
                                                   src={item.thumb + "?x-oss-process=image/resize,w_500,h_280,m_fill,limit_0"}
                                            />
                                        </div>
                                    </div>
                                }
                            >
                                <Card.Meta title={item.title}  />
                                <div className='d-flex justify-content-between position-absolute top-0 start-0 w-100 p-3'>
                                    <Space>
                                        <Avatar src={item.mch.logo} />
                                        <Text className='text-white'>{subText(item.mch.name, 10)}</Text>
                                        {item.status === 0 &&  <Badge count="待审核" color="yellow" /> }
                                        {item.status === 1 &&  <Badge count="已拒绝" color="red" /> }
                                        {item.status === 2 &&  <Badge count="已拒绝" color="green" /> }
                                    </Space>
                                </div>
                                <div className='mt-3'>
                                    <Link to={{pathname: '/activity/edit/' + item.id}}>
                                        <div><Space><span className='text-dark'>活动地点:</span> <Text className='text-muted'>{subText(item.address, 16)}</Text></Space></div>
                                        <div className='mt-2'><Space><span className='text-dark'>活动时间:</span> <Text className='text-muted'>{moment(item.activity_start_date).format('YYYY-MM-DD HH:mm')}</Text></Space></div>
                                    </Link>
                                </div>
                            </Card>
                        </List.Item>
                    )}
                />
        </div>
    )
}

export default MchOrder
