/**
 * Notes: 用户订单
 * User: 李强
 * DateTime: 2021/12/10 14:28
 * Ide: PhpStorm
 */

import React, {useCallback, useEffect, useState} from 'react'

import {  Badge, message, Table, Tag } from 'antd';
import {Link, withRouter} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {subText} from "../../utils/commonfn";
/*
  用户管理
*/
const MchJobs= (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)



    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/jobs' , params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    },[get])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15, mch_id:props.id});
    }, [getData, props.id]);



    /**
     * 自定义表格
     * @type {Object}
     */

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            render: (tags, item) => {
                return (
                    <Link to={{pathname: '/jobs/edit/' + item.id}}>
                        <div className='text-dark mb-2'>{subText(tags , 24)}</div>
                    </Link>
                )
            }
        },
        {
            title: '薪资',
            dataIndex: 'wage_start',
            render: (wage , item) => {
                return (
                    <div>
                        {wage}{parseInt(item.wage_end) !== 0 && ' - '+item.wage_end } 元 /
                        {item.wage_unit === 0 && <span>月薪</span>}
                        {item.wage_unit === 1 && <span>年薪</span>}
                        {item.wage_unit === 2 && <span>日薪</span>}
                    </div>
                )
            }
        },
        {
            title: '分类',
            dataIndex: 'type',
            render: (type) => {
                return (
                    <div>

                        {type === 0 &&   <Tag color="volcano">招聘</Tag> }
                        {type === 1 &&   <Tag color="green">兼职</Tag> }
                        {type === 2 &&  <Tag color="blue">实习</Tag> }
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <div>
                        {status === 0 &&   <Badge  count={'待审核'} style={{backgroundColor: '#fa8c16'}}/> }
                        {status === 1 &&   <Badge  count={'已拒绝'}  style={{backgroundColor: '#dc3545'}}/> }
                        {status === 2 && <Badge count={'已发布'} style={{backgroundColor: '#52c41a'}}/> }
                    </div>
                )
            }
        },
        {
            title: '发布日期',
            dataIndex: 'created_at',
        },
    ];

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            mch_id:props.id
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }


    return (
        <div className='bg-white px-4'>
            <Table
                size='middle'
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    size:'default',
                    total: total,
                    current: current,
                    showTotal: total => `总计 ${total} 条招聘`,
                    pageSize: perPage,
                }}
                onChange={onPaginationChange}
                rowKey={(record) => {
                    return record.id
                }}
            />
        </div>
    )
}

export default MchJobs
