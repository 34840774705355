/**
 * Created by devin on 2021/4/23.
 */

import React, {useEffect, useMemo, useRef, useState} from 'react'
import 'react-quill/dist/quill.snow.css';
import {Attachment} from "../../components";
import ReactQuill from "react-quill";


const Editor = (props) => {
    const {onChange , disabled, value } = props
    const [content, setContent] = useState('')
    const selectRef = useRef();
    const quillRef = useRef();

    useEffect(() => {
        setContent(value || '')
        if(quillRef.current){
            quillRef.current.editor.enable(!disabled)
        }
    }, [disabled, value]);



    /**
     * 编辑器配置
     * @type {{toolbar: {container, handlers: {image: function(): void}}, clipboard: {matchVisual: boolean}}}
     */
    const module = useMemo(() => {
        return {
            toolbar: {
                container: !disabled ? [
                    [{size: []}],
                    [{color: []}, {background: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                    ['image', 'video'],
                    ['clean'],
                ] : [
                    [{size: []}],
                    [{color: []}, {background: []}],
                    ['clean'],
                ],
                handlers: {
                    image: () => {
                        selectRef.current.onOpen(1,true)
                    },
                    video: () => {
                        selectRef.current.onOpen(2)
                    }
                }
            },
            clipboard: {
                matchVisual: false,
            }
        }
    }, [disabled]);




    /**
     * 附加图片处理
     * @param item
     * @param type
     */
    const imageInsert = (item , type) => {
        if(type === 1){
            let quill = quillRef.current.editor;//获取到编辑器本身
            const cursorPosition = quill.selection.savedRange.index;//获取当前光标位置
            //循环添加
            for (let i = 0; i < item.length; i++) {
                quill.insertEmbed(cursorPosition, "image", item[i].url, 'user');//插入图片
            }
            quill.setSelection(cursorPosition + 1); //光标位置加1
        }

        if(type === 2){
            let quill = quillRef.current.editor;//获取到编辑器本身
            const cursorPosition = quill.selection.savedRange.index//获取当前光标位置
            quill.insertEmbed(cursorPosition, "video", item[0].url, 'user');//插入视频
            quill.setSelection(cursorPosition + 1); //光标位置加1
        }
    }

    return (
        <div>
            <ReactQuill
                ref={quillRef}
                style={{height: 360, marginBottom: 50}}
                theme="snow"
                value={content}
                onChange={(e)=>{
                    setContent(e)
                    onChange(e)
                }}
                modules={module}
            />
            <Attachment  refs={selectRef} onChange={imageInsert}/>
        </div>
    )

}

export default Editor;
