import React from 'react'
import SideMenu from './SideMenu'
import TopHeader from './TopHeader'
import MainContent from './MainContent';
import RightMenu from './RightMenu';
import { Layout } from 'antd';
import {collapsedStore} from "../../store";
import {Copyright} from "../../Config";
import {useRecoilValue} from "recoil";
const { Sider, Footer, Header, Content } = Layout;

const Admin = (props) => {
    const collapsed = useRecoilValue(collapsedStore);
    const size = collapsed ? 80 : 200;
    return (
      <Layout>
          <Header
              className='position-fixed w-100 bg-white border-bottom pr-3'
              style={{paddingLeft:size, zIndex:901}}>
              <TopHeader />
          </Header>
        <Sider className='position-fixed h-100' style={{top:0,left:0, overflow: 'auto',zIndex:902}} collapsed={collapsed}><SideMenu /></Sider>
        <Layout style={{marginTop:60, marginRight:180, marginLeft:size}}>
            <Content>
                <MainContent />
                <Footer>{Copyright}-后台管理系统</Footer>
            </Content>
        </Layout>
          <Sider width={180} className='position-fixed bg-white h-100' style={{top:60,right:0, overflow: 'auto',zIndex:900}}><RightMenu /></Sider>
      </Layout>
    );
}
export default  Admin;
