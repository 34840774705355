import React, {useState} from 'react'

import {useSetRecoilState} from "recoil";
import {Button, Checkbox, Form, Image, Input, Alert, Tooltip} from 'antd';
import {CopyrightOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import {Copyright, Api, Logo} from '../../Config';
import {userState, tokenState} from '../../store';
import {useNavigate} from "react-router-dom";
import Background from './loginBg.png';

const Login = (props) => {
    const navigate = useNavigate();
    const setToken = useSetRecoilState(tokenState);
    const setUser = useSetRecoilState(userState)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState(false)

    const onFinish = values => {
        setLoading(true)
        setError(false)
        let params = {
            login: values.login,
            password: values.password,
        };
        fetch(Api + 'admin/login', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.code === 0) {
                    setLoading(false);
                    setMessage(data.message);
                    setError(true)
                } else {
                    sessionStorage.setItem("user",JSON.stringify(data.data));
                    sessionStorage.setItem("token",data.token);
                    setUser(data.data);
                    setToken(data.token);
                    navigate('/home')
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false);
                setError(true)
                setMessage('网络错误稍后重试！');
            });

    };

    return (
        <div className="d-flex justify-content-center flex-column align-items-center vh-100" style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat:'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>

            <header className='login-header'>
                <Image
                    className='mb-4'
                    preview={false}
                    width={140}
                    src={Logo.full}
                /></header>
            {error && <Alert message={message} type="error" showIcon className='mb-3'  style={{width: 320}}/>}
            <section className='bg-white p-4' style={{minWidth: 320}}>
                <h3 className='mb-3'>管理员登录</h3>
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="login"
                        validateFirst='true'
                        rules={[
                            {
                                // 必填
                                required: true,
                                // 校验错误，就不会继续往下校验
                                whitespace: true,
                                message: '请输入用户名',
                            },
                            {
                                min: 4,
                                message: '用户名最少4位',
                            },
                            {
                                max: 12,
                                message: '用户名最多12位',
                            },
                            {
                                pattern: /^[a-zA-Z0-9_]+$/,
                                message: '用户名必须是英文、数字或下划线组成',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="用户名"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: '请输入密码',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="密码"
                        />
                    </Form.Item>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>记住密码</Checkbox>
                            </Form.Item>
                        </Form.Item>
                        <Tooltip title="请联系管理员重新获取" trigger='click'>
                            <span className='mb-4 d-block cursor'>忘记密码？</span>
                        </Tooltip>
                    </div>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="w-100"
                            loading={loading}
                        >
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                <div className='text-muted'>如遇其他问题请联系管理员</div>
            </section>
            <div className='text-muted mt-4'><CopyrightOutlined/> 2021 {Copyright}出品</div>
        </div>
    )
}

export default Login
