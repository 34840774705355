/**
 * Created by devin on 2021/4/23.
 */

import React, {useRef, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {Button, Divider, Form, Input, message, Radio, Spin, Select, Breadcrumb} from "antd";
import 'react-quill/dist/quill.snow.css';
import {articleCategory} from "../../store";
import {useRecoilValueLoadable} from "recoil";
import {FileSelect , ThumbSelect} from "../../components";
import {useHttps} from "../../hooks";
import Editor from "../../components/Editor";

const Create = (props) => {
    const category = useRecoilValueLoadable(articleCategory);
    const [content, setContent] = useState('')
    const [file, setFile] = useState([]);
    const [thumb, setThumb] = useState(null)
    const [loading, setLoading] = useState(false)
    const {post} = useHttps();
    const formRef = useRef();
    const navigate = useNavigate();

    const onFinish = (params) => {
        if(file.length > 0){
            params.download = file;
        }
        setLoading(true)
        post('admin/article', params)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('文章已发布成功！');
                    navigate(-1);
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });

    }


    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-dark' >文章</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部文章</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item  className='text-primary'>添加</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <div>
                            <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                                返回列表
                            </Button>
                        </div>
                        <Divider/>
                        <div>
                            <Form
                                ref={formRef}
                                labelCol={{span: 4 }}
                                wrapperCol={{span: 16 }}
                                name="basic"
                                initialValues={{
                                    status: 0,
                                    content: content,
                                    thumb: thumb ? thumb.url : "",
                                    link: '',
                                    description: ''
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="文章标题"
                                    name="title"
                                    rules={[{required: true, message: '请输入标题！'}]}
                                >
                                    <Input  placeholder='请输入文章标题' />
                                </Form.Item>
                                <Form.Item
                                    label="链接"
                                    name="link"
                                    rules={[{type: 'url'}]}
                                    extra="如果需要链接到外部网页，请输入https://开头地址"
                                >
                                    <Input placeholder='https://'/>
                                </Form.Item>

                                <Form.Item
                                    label="封面图"
                                    name='thumb'
                                    extra="上传尺寸建议980x460"
                                    className="mt-3"
                                    rules={[{required: true, message: '请选择图片！'}]}
                                >
                                    <div className='w-100'>
                                        <ThumbSelect src={thumb}
                                                     onChange={(e) => {
                                                         setThumb(e);
                                                         formRef.current.setFieldsValue({thumb: e})
                                                     }}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="category_id"
                                    label="选择分类"
                                    rules={[{required: true, message: '请选择分类！'}]}
                                  >
                                    <Select placeholder="选择文章分类" style={{width: 160}} disabled={category.state !== 'hasValue'}>
                                        {category.state === 'hasValue' && category.contents.map((tag) => {
                                            return (
                                                <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="文章内容" name="content"
                                           rules={[{
                                               required: true,
                                               message: '请输入20字符以上内容。！',
                                               validator: async (rule, value) => {
                                                   if (content.length <= 20) {
                                                       return Promise.reject()
                                                   }
                                               }
                                           }
                                           ]}
                                >
                                    <Editor
                                        value={content}
                                        onChange={setContent}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="附件下载"
                                    name='download'
                                    extra="选择要下载的文件！"
                                    className="mt-3"
                                >
                                    <div className='w-100'>
                                        <FileSelect onChange={setFile} list={file} />
                                    </div>
                                </Form.Item>
                                <Form.Item name='description' label="内容摘要">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item
                                    name="status"
                                    label="是否显示"
                                >
                                    <Radio.Group value={0}>
                                        <Radio value={0}>隐藏</Radio>
                                        <Radio value={1}>显示</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item wrapperCol={{offset: 4}}>
                                    <Button className='me-3' type="primary" htmlType="submit">
                                        提交
                                    </Button>
                                    <Button className='me-2' onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default Create;
