/**
 * Created by devin on 2021/4/23.
 */

import React, {useEffect, useRef, useState} from 'react'

import {useNavigate, useParams} from 'react-router-dom'
import {
    Button,
    Divider,
    Form,
    Input,
    message,
    Radio,
    Spin,
    Select,
    InputNumber,
    Typography,
    DatePicker,
    Switch,
    Empty,
    Space,
    Modal, Breadcrumb, Avatar
} from "antd";
import {
    PlusOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";


import {SortableImage, Attachment, Sku, ThumbSelect} from "../../components";
import {useRecoilValueLoadable} from "recoil";
import moment from 'moment';
import {goodsCategory, goodsTags, userTags} from "../../store";
import {useHttps} from "../../hooks";
import {priceCheck} from "../../utils/commonfn";


const {Title, Text} = Typography


const Edit = (props) => {
    const {id} = useParams();
    const [content, setContent] = useState([])
    const [thumbUrl, setThumbUrl] = useState([])
    const [thumbType, setThumbType] = useState(0);
    const [goodsState, setGoodsState] = useState(0);
    const [thumb, setThumb] = useState(null)
    const [loading, setLoading] = useState(true)
    let [isVerify, setIsVerify] = useState(false)
    const [isTime, setIsTime,] = useState(false)
    const [isLevel, setIsLevel,] = useState(false)
    const [isPoints, setIsPoints,] = useState(false)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [mch, setMch] = useState(null)
    const category = useRecoilValueLoadable(goodsCategory);
    const tags = useRecoilValueLoadable(goodsTags);
    const userTag = useRecoilValueLoadable(userTags);
    const {put, get, destroy} = useHttps();
    const formRef = useRef();
    const selectRef = useRef();
    const skuRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        get('/admin/shop/goods/' + id, null, true)
            .then((res) => {
                //初始化form值
                if (res.mounted) {
                    initFormat(res.data)
                    setLoading(false);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    setErrorText(e.message);
                    setError(true);
                }
            });
    }, [get, id]);


    /**
     * 初始化From数据
     * @param params
     * @returns {*}
     */

    const initFormat = (params) => {
        setThumb(params.thumb)
        setThumbUrl(params.thumb_url)
        setContent(params.content)
        setIsLevel(params.is_level)
        setIsPoints(params.is_points)
        setIsTime(params.is_time)
        setIsVerify(params.is_verify)
        setMch(params.mch)
        setGoodsState(params.status)
        params.mch_id = params.mch.id
        params.tag_id = params.tags.map((item) => {
            return item.id
        });

        params.category_id = params.category.map((item) => {
            return item.id
        });

        params.level_id = params.level.map((item) => {
            return item.id
        });

        if (params.start_time) {
            params.time_date = [moment(params.start_time), moment(params.end_time)];
        }

        if (params.verify_start_date) {
            params.verify_date = [moment(params.verify_start_date), moment(params.verify_end_date)];
        }
        // delete params.attr_group;
        // delete params.attr_list;
        // delete params.category;
        // delete params.attr_group;
        // delete params.start_time;
        // delete params.end_time;
        // delete params.tags;
        // delete params.verify_start_date;
        // delete params.verify_end_date;
        // delete params.level;
        formRef.current.setFieldsValue(params)
        skuRef.current.setSkuValues({spec: params.attr_group ?? [], sku: params.attr_list})
    }


    /**
     * 格式化From数据
     * @param params
     * @returns {*}
     */

    const formatJson = (params) => {
        params.cost_price = params.cost_price.length === 0 ? 0 : params.cost_price;
        params.product_price = params.product_price.length === 0 ? 0 : params.product_price;

        if (params.is_time) {
            params.start_time = params.time_date ? params.time_date[0].format('YYYY-MM-DD') : null;
            params.end_time = params.time_date ? params.time_date[1].format('YYYY-MM-DD') : null;
        }

        if (params.is_verify) {
            params.verify_start_date = params.verify_date ? params.verify_date[0].format('YYYY-MM-DD') : null;
            params.verify_end_date = params.verify_date ? params.verify_date[1].format('YYYY-MM-DD') : null;
        }

        //SKU设置
        const attr = skuRef.current.getSkuValues()

        if (attr.spec.length !== 0) {
            params.is_attr = true;
            params.attr_group = attr.spec;
            //重组SKU列表 给服务端
            let attrList = []
            if (attr.sku.length !== 0) {
                attr.sku.forEach((item, si) => {
                    let obj = {}
                    obj.sku_id = item.sku_id;
                    obj.price = item.price;
                    obj.stock = item.stock;
                    obj.code = item.code;
                    obj.remark = item.remark;
                    obj.thumb = item.thumb;
                    attrList.push(obj)
                })
            }
            params.attr_list = attrList;
        } else {
            params.is_attr = false;
            params.attr_list = []
            params.attr_group = []
        }
        delete params.verify_date;
        delete params.time_date;
        return params
    }


    /**
     * 提交
     * @param params
     */
    const onFinish = (params) => {
        const data = formatJson(params)
        setLoading(true)
        put('admin/shop/goods/' + id, data).then((res) => {
            if (res.mounted) {
                setLoading(false)
                message.success('商品更改成功！');
            }
        }).catch((e) => {
            if (e.mounted) {
                setLoading(false)
                message.error(e.message);
            }
        })
    }


    /**
     * 删除商品
     */
    const onDelete = () => {
        destroy('/admin/shop/goods/' + id)
            .then((res) => {
                //初始化form值
                if (res.mounted) {
                    setLoading(false);
                    message.success('商品已删除！');
                    navigate(-1);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }


    /**
     * 附加图片处理
     * @param item
     */
    const imageInsert = (item) => {
        if (thumbType === 1) {
            const dataSource = [...thumbUrl, ...item];
            setThumbUrl(dataSource)
            formRef.current.setFieldsValue({thumb_url: dataSource})
        }
        if(thumbType === 2) {
            const dataSource = [...content, ...item];
            setContent(dataSource)
            formRef.current.setFieldsValue({content: dataSource})
        }
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除这个商品吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 错误显示
     */

    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'><Divider type='vertical' className='bg-primary'
                                               style={{width: 5, height: 14}}/> 当前位置：<span
                    className='text-primary'>编辑商户</span></div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>

                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-dark' >商品</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部商品</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item className='text-primary'>编辑</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>


                    <Form
                        labelCol={{span: 2}}

                        ref={formRef}
                        name="basic"
                        initialValues={{
                            status: 1,
                            thumb: '',
                            stock: 100,
                            max_buy: 0,
                            type: 1,
                            market_price: '',
                            product_price: '',
                            cost_price: '',
                            is_verify: false,
                            is_time: false,
                            is_level: false,
                            is_points: false,
                            verify_num: 1,
                            points: 1,
                            content: [],
                            level_id: [],
                            time_date: null,
                            verify_date: null,
                            promise: '',
                            discount: '',
                            explain: [],
                            params: [],
                            tag_id: [],
                            state: 0
                        }}
                        onFinish={onFinish}
                    >
                        <div className='bg-white py-3 mb-3'>

                            <div className='px-4'>
                                <Title className='mb-0' level={5}>基本设置</Title>
                            </div>
                            <Divider className='mt-3'/>
                            <div className='px-3'>
                                <Form.Item
                                    label="所属商户"
                                >
                                    {mch && <div className="d-flex justify-content-between align-content-center align-items-center">
                                        <div className="w-75 d-flex align-items-center">
                                            <Avatar shape="square" src={mch.logo}/>
                                            <Text className="d-block ms-2 "
                                                  ellipsis>{mch.name}</Text>
                                        </div>
                                    </div> }
                                </Form.Item>
                                <Form.Item label="商品名称" className='mb-0' required>
                                    <Form.Item
                                        className='d-inline-block w-50'
                                        name="title"
                                        rules={[{required: true, message: '请输入标题！'}, {
                                            whitespace: true,
                                            message: '请输入标题！'
                                        }]}
                                    >
                                        <Input disabled  placeholder='请输入商品标题' maxLength={50}/>
                                    </Form.Item>
                                    <Form.Item
                                        className='d-inline-block ms-3'
                                        name="unit"
                                        rules={[{required: true, message: '请输入单位！'}, {
                                            whitespace: true,
                                            message: '请输入单位！'
                                        }]}
                                    >
                                        <Input disabled maxLength={3} placeholder='单位, 如: 个/件/包'/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label="商品类型"
                                    name="type"
                                    required
                                >
                                    <Radio.Group disabled>
                                        <Radio value={1}>实体物品</Radio>
                                        <Radio value={2}>虚拟商品</Radio>
                                        <Radio value={3}>外卖订餐</Radio>
                                        <Radio value={4}>酒店客房</Radio>
                                        <Radio value={5}>售票卡券</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="商品价格" className='mb-0' required>
                                    <Form.Item
                                        className='d-inline-block  me-3'
                                        name="market_price"
                                        rules={[{
                                            required: true, validator: async (rule, value) => {
                                                const isValue = value.replace(/(^\s*)|(\s*$)/g, '');
                                                if (isValue.length === 0) {
                                                    await Promise.reject('请输入价格！')
                                                }
                                                //判断规格项是否存在
                                                if (!priceCheck(value)) {
                                                    await Promise.reject('请输入价格！')
                                                }
                                                await Promise.resolve()
                                            }
                                        }]}
                                    >
                                        <Input disabled placeholder='商品售价' prefix="￥" suffix="RMB"/>
                                    </Form.Item>
                                    <Form.Item
                                        className='d-inline-block me-3'
                                        name="product_price"
                                        rules={[{
                                            required: false, validator: async (rule, value) => {
                                                const isValue = value.replace(/(^\s*)|(\s*$)/g, '');
                                                if (isValue.length !== 0) {
                                                    if (!priceCheck(value, false)) {
                                                        await Promise.reject('请输入价格！')
                                                    }
                                                }
                                                await Promise.resolve()
                                            }
                                        }]}
                                    >
                                        <Input disabled placeholder='商品原价' prefix="￥" suffix="RMB"/>
                                    </Form.Item>

                                    <Form.Item
                                        className='d-inline-block'
                                        name="cost_price"
                                        rules={[{
                                            required: false, validator: async (rule, value, callback) => {
                                                const isValue = value.replace(/(^\s*)|(\s*$)/g, '');
                                                if (isValue.length !== 0) {
                                                    if (!priceCheck(value, false)) {
                                                        await Promise.reject('请输入价格！')
                                                    }
                                                }
                                                await Promise.resolve()
                                            }
                                        }]}
                                    >
                                        <Input disabled placeholder='商品成本价' prefix="￥" suffix="RMB"/>
                                    </Form.Item>
                                </Form.Item>


                                <Form.Item label="库存" extra='最大购买是指每位用户最多购买几次！0则表示无限制' required>
                                    <Form.Item
                                        name="stock"
                                        className='d-inline-block mb-0'
                                        rules={[{required: true, message: '请输入商品库存！'}]}
                                    >
                                        <InputNumber disabled min={1} max={10000}/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>最大购买：</span>
                                    <Form.Item
                                        className='d-inline-block mb-0'
                                        name="max_buy"
                                    >
                                        <InputNumber disabled min={0} max={10000}/>
                                    </Form.Item>
                                </Form.Item>



                                <Form.Item
                                    label="封面图"
                                    name='thumb'
                                    extra="尺寸建议980x980"
                                    rules={[{required: true, message: '请选择图片！'}]}
                                >
                                    <ThumbSelect disabled src={thumb}
                                                 onChange={(e) => {
                                                     setThumb(e);
                                                     formRef.current.setFieldsValue({thumb: e})
                                                 }}
                                    />
                                </Form.Item>



                            </div>
                        </div>


                        <div className='bg-white py-3  mb-3'>
                            <div className='px-4'>
                                <Title className='mb-0' level={5}>规则设置</Title>
                            </div>

                            <Divider className='mt-3'/>
                            <div className='px-3'>


                                <Form.Item className='mb-0' label="是否启用核销">
                                    <Form.Item
                                        className='d-inline-block  w-25'
                                        name="is_verify"
                                        valuePropName="checked"
                                    >
                                        <Switch disabled onChange={setIsVerify}/>
                                    </Form.Item>

                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>核销次数：</span>
                                    <Form.Item
                                        className='d-inline-block w-25'
                                        name="verify_num"
                                        rules={[{required: isVerify, message: '请输入核销次数！'}]}
                                    >
                                        <InputNumber disabled min={1} max={10}/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>核销有效期：</span>
                                    <Form.Item
                                        className='d-inline-block w-25 ms-3'
                                        name="verify_date"
                                        rules={[{type: 'array', required: isVerify, message: '请输入核销有效期！'}]}
                                    >
                                        <DatePicker.RangePicker
                                            disabledDate={(current) => current < moment().subtract(1, "days") || current > moment().add(3, 'months')}
                                            format={'YYYY-MM-DD'}
                                            disabled
                                        />
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item label="是否限时购买" className='mb-0'>
                                    <Form.Item
                                        className='d-inline-block w-25'
                                        name="is_time"
                                        valuePropName="checked"
                                    >
                                        <Switch disabled onChange={setIsTime}/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>销售时间：</span>
                                    <Form.Item
                                        className='d-inline-block w-25'
                                        name="time_date"
                                        rules={[{type: 'array', required: isTime, message: '请输入销售时间！'}]}
                                    >
                                        <DatePicker.RangePicker
                                            disabledDate={(current) => current < moment().subtract(1, "days") || current > moment().add(1, 'months')}
                                            format={'YYYY-MM-DD'}
                                            disabled
                                        />
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item label="是否启用权限" className='mb-0'>


                                    <Form.Item
                                        className='d-inline-block w-25'
                                        name="is_level"
                                        valuePropName="checked"
                                    >
                                        <Switch disabled onChange={setIsLevel}/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>选择权限：</span>

                                    <Form.Item
                                        className='d-inline-block w-50 mb-0'
                                        name="level_id"
                                        rules={[{required: isLevel, message: '请输入用户标签！'}]}
                                    >
                                        <Select mode='multiple' placeholder="选择用户标签" className='w-50'
                                                disabled>
                                            {userTag.state === 'hasValue' && userTag.contents.map((tag) => {
                                                return (
                                                    <Select.Option key={tag.id}
                                                                   value={tag.id}>{tag.name}</Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item label="是否启用积分" className='mb-0'>


                                    <Form.Item
                                        className='d-inline-block w-25'
                                        name="is_points"
                                        valuePropName="checked"
                                    >
                                        <Switch disabled onChange={setIsPoints}/>
                                    </Form.Item>
                                    <span className='d-inline-block text-center ms-3'
                                          style={{lineHeight: '32px'}}>设置积分：</span>

                                    <Form.Item
                                        extra='1积分等于1元钱'
                                        className='d-inline-block mb-0'
                                        name="points"
                                        rules={[{required: isPoints, message: '请输入兑换积分！'}]}
                                    >
                                        <Input placeholder='兑换积分' min={1} type="number" maxLength={5}
                                               disabled prefix="￥" suffix="积分"/>
                                    </Form.Item>


                                </Form.Item>
                            </div>
                        </div>


                        <div className='bg-white py-3 mb-3'>
                            <div className='px-4'>
                                <Title className='mb-0' level={5}>商品规格</Title>
                            </div>

                            <Divider className='mt-3'/>
                            <div className='px-3'>
                                <Sku disabled refs={skuRef} from={formRef.current}/>
                            </div>
                        </div>

                        <div className='bg-white py-3 mb-3'>
                            <div className='px-4'>
                                <Title className='mb-0' level={5}>商品详情</Title>
                            </div>

                            <Divider className='mt-3'/>
                            <div className='px-3'>
                                <Form.Item
                                    label="商品保障"
                                    name="promise"
                                >
                                    <Input disabled maxLength={100}/>
                                </Form.Item>
                                <Form.Item
                                    label="优惠说明"
                                    name="discount"
                                >
                                    <Input disabled maxLength={100}/>
                                </Form.Item>
                                <Form.Item
                                    label="购买说明"
                                >
                                    <Form.List name="explain">
                                        {(fields, {add, remove}) => (
                                            <>
                                                {fields.map(({key, name, ...restField}) => (
                                                    <div className='d-flex align-items-center align-content-center'
                                                         key={key}>
                                                        <Form.Item
                                                            className='me-3'
                                                            {...restField}
                                                            name={[name, 'key']}
                                                            rules={[{required: true, message: 'Missing first name'}]}
                                                        >
                                                            <Input disabled maxLength={8} placeholder="标题"/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            className='w-100 me-3'
                                                            {...restField}
                                                            name={[name, 'value']}
                                                            rules={[{required: true, message: '使用说明'}]}
                                                        >
                                                            <Input disabled maxLength={50} placeholder="输入使用说明"/>
                                                        </Form.Item>
                                                        {/*<div className='mb-4'>*/}
                                                        {/*    <MinusCircleOutlined onClick={() => remove(name)}/>*/}
                                                        {/*</div>*/}

                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button disabled type="primary" onClick={() => add()} icon={<PlusOutlined/>}>
                                                        添加说明
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}

                                    </Form.List>
                                </Form.Item>

                                <Form.Item
                                    label="商品参数"
                                >
                                    <Form.List name="params">
                                        {(fields, {add, remove}) => (
                                            <>
                                                {fields.map(({key, name, ...restField}) => (
                                                    <div className='d-flex align-items-center align-content-center'
                                                         key={key}>
                                                        <Form.Item
                                                            className='me-3'
                                                            {...restField}
                                                            name={[name, 'key']}
                                                            rules={[{required: true, message: 'Missing first name'}]}
                                                        >
                                                            <Input disabled maxLength={8} placeholder="参数名"/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            className='w-100 me-3'
                                                            {...restField}
                                                            name={[name, 'value']}
                                                            rules={[{required: true, message: '使用说明'}]}
                                                        >
                                                            <Input disabled maxLength={200} placeholder="输入参数内容"/>
                                                        </Form.Item>
                                                        {/*<div className='mb-4'>*/}
                                                        {/*    <MinusCircleOutlined onClick={() => remove(name)}/>*/}
                                                        {/*</div>*/}

                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button disabled type="primary" onClick={() => add()} icon={<PlusOutlined/>}>
                                                        添加参数
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>

                                <Form.Item
                                    label="商品预览图"
                                    name='thumb_url'
                                    extra="尺寸建议940x550，拖拽图片可进行排序"
                                    className="mt-3"
                                    rules={[{type: 'array', required: true, message: '请选择图片！'}]}
                                >
                                    <div className='w-100'>

                                            <SortableImage disabled  list={thumbUrl} onChange={(e) => {
                                                formRef.current.setFieldsValue({thumb_url: e})
                                                setThumbUrl(e)
                                            }}/>

                                        <div>
                                            <Button  disabled onClick={() => {
                                                setThumbType(1)
                                                selectRef.current.onOpen(1 ,true)
                                            }} type="dashed" icon={<PlusOutlined/>}>
                                                添加预览图
                                            </Button>
                                        </div>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="商品详情图"
                                    extra="尺寸建议宽度940，拖拽图片可进行排序"
                                    className="mt-3"
                                    name='content'
                                >
                                    <div className='w-100'>

                                            <SortableImage disabled  list={content} onChange={(e) => {
                                                setContent(e)
                                                formRef.current.setFieldsValue({content: e})
                                            }}/>
                                        <div>
                                            <Button disabled onClick={() => {
                                                setThumbType(2)
                                                selectRef.current.onOpen(1, true)
                                            }} type="dashed" icon={<PlusOutlined/>}>
                                                添加详情图
                                            </Button>
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='bg-white py-3 mb-3'>
                            <div className='px-4'>
                                <Title className='mb-0' level={5}>提交审核</Title>
                            </div>

                            <Divider className='mt-3'/>
                            <Form.Item
                                name="tag_id"
                                label="选择标签"
                            >
                                <Select mode='multiple' placeholder="选择商品标签" className='w-50'
                                        disabled={tags.state !== 'hasValue'}>
                                    {tags.state === 'hasValue' && tags.contents.map((tag) => {
                                        return (
                                            <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="category_id"
                                label="选择分类"
                                rules={[{required: true, message: '请选择分类！'}]}
                            >
                                <Select mode='multiple' placeholder="选择商品分类" className='w-25'>
                                    {category.state === 'hasValue' && category.contents.map((tag) => {
                                        return (
                                            <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="state"
                                label="是否上架"
                            >
                                <Radio.Group value={1}>
                                    <Radio value={1}>上架</Radio>
                                    <Radio value={0}>下架</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="审核状态"
                                name="status"
                            >
                                <Radio.Group value={0} onChange={(e) => setGoodsState(e.target.value)}>
                                    <Radio value={0}>待审核</Radio>
                                    <Radio value={1}>未通过</Radio>
                                    <Radio value={2}>已通过</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {goodsState === 1 && <Form.Item
                                name="status_info"
                                label="拒绝原因"
                                rules={[{required: goodsState === 1, message: '请输入拒绝原因！'}]}
                            >
                                <Input.TextArea placeholder='输入备注信息'/>
                            </Form.Item>
                            }

                            <Form.Item wrapperCol={{offset: 2}}>
                                <Space size='large'>
                                    <Button type="primary" htmlType="submit">
                                        提交修改
                                    </Button>
                                    <Button onClick={() =>  navigate(-1)}>
                                        返回列表
                                    </Button>
                                    <Button type="dashed" danger onClick={showConfirm}
                                            icon={<DeleteOutlined/>}> 删除 </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>

                </div>
            </Spin>
            <Attachment  refs={selectRef} onChange={(item) => imageInsert(item)}/>
        </div>
    )
}
export default Edit;
