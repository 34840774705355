/**
 * Created by devin on 2021/4/23.
 */

import React, {useRef, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {Button, Divider, Form, Input, message, Radio, Spin, Breadcrumb} from "antd";
import {ThumbSelect} from "../../components";
import {useHttps} from "../../hooks";
import {isCorrectFormatUsername, isMPStrict, isCorrectFormatPassword } from "../../utils/commonfn";


const Create = (props) => {
;
    const [avatar, setAvatar] = useState(null)
    const [loading, setLoading] = useState(false)
    const {post} = useHttps();
    const formRef = useRef();
    const navigate = useNavigate();

    const onFinish = (params) => {
        setLoading(true)
        post('admin/staff', params)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('员工已发布成功！');
                    navigate(-1);
                }

            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });

    }

    return (
        <div>
            <Spin size="large" spinning={loading}>
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-dark' >员工</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>全部员工</Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item  className='text-primary'>添加</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <div>
                            <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                                返回列表
                            </Button>
                        </div>
                        <Divider/>
                        <div>
                            <Form
                                ref={formRef}
                                labelCol={{span: 4 }}
                                wrapperCol={{span: 16 }}
                                name="basic"
                                initialValues={{
                                    status: 0,
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="登录名"
                                    name="login"
                                    extra="用户名最少4位由（字母，数字）组成"
                                    rules={[{required: true,  validator: async (rule, value) => {
                                            const isValue = isCorrectFormatUsername(value)
                                            if(!isValue){
                                                await Promise.reject('请输入正确用户名！')
                                            }
                                            await Promise.resolve()
                                        }}]}
                                >
                                    <Input style={{width:200}} placeholder='请输登陆名' />
                                </Form.Item>
                                <Form.Item
                                    label="姓名"
                                    name="name"
                                    rules={[{required: true, message: '请输入姓名！'}]}
                                >
                                    <Input style={{width:200}} placeholder='输入真实姓名'/>
                                </Form.Item>

                                <Form.Item
                                    label="手机号"
                                    name="mobile"
                                    rules={[{required: true,  validator: async (rule, value) => {
                                           const isValue = isMPStrict(value)
                                            if(!isValue){
                                                await Promise.reject('请输入正确手机号！')
                                            }
                                            await Promise.resolve()
                                        }}]}
                                >
                                    <Input style={{width:200}} placeholder='输入手机号码'/>
                                </Form.Item>

                                <Form.Item
                                    label="头像"
                                    name='avatar'
                                    extra="上传尺寸建议200x200"
                                    className="mt-3"
                                >
                                    <div className='w-100'>
                                        <ThumbSelect size={60} src={avatar}
                                                     onChange={(e) => {
                                                         setAvatar(e);
                                                         formRef.current.setFieldsValue({avatar: e})
                                                     }}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="登陆密码"
                                    name='password'
                                    extra="最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符"
                                    rules={[{required: true,  validator: async (rule, value) => {
                                            const isValue = isCorrectFormatPassword(value)
                                            if(!isValue){
                                                await Promise.reject('请输入正确密码格式！')
                                            }
                                            await Promise.resolve()
                                        }}]}
                                >
                                    <Input.Password  style={{width:300}} placeholder="输入登陆密码" />
                                </Form.Item>

                                <Form.Item
                                    name="status"
                                    label="职位状态"
                                    extra="只有在职中和已休假的员工才可以登陆！"
                                >
                                    <Radio.Group value={0}>
                                        <Radio value={0}>待入职</Radio>
                                        <Radio value={1}>已离职</Radio>
                                        <Radio value={2}>在职中</Radio>
                                        <Radio value={3}>休假中</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item wrapperCol={{offset: 4}}>
                                    <Button className='me-3' type="primary" htmlType="submit">
                                        添加
                                    </Button>
                                    <Button className='me-2' onClick={() => navigate(-1)}>
                                        返回列表
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}
export default Create;
