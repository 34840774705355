/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    PageHeader,
    Divider,
    Spin,
    Row,
    Col,
    Table,
    Descriptions,
    Space, Empty, Input, Skeleton, Typography, message, Statistic, Breadcrumb, Tag, Avatar
} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {useHttps} from "../../hooks";
import {Icon} from "../../Config";
import {Thumb} from "../../components";

const {Text} = Typography;



const OrderUserDetails = (props) => {
    const {put, get} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [goods, setGoodsData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const [remark, setRemark] = useState('');
    const printRef = useRef() ;
    const navigate = useNavigate();


    useEffect(() => {
        get('admin/shop/order/' + id, null,  true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setRemark(res.data.remark)
                    getOrderGoods(res.data.order_item)
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);


    /**
     * 获取全部订单商品
     * @param params
     */
    const getOrderGoods= (params) => {
         let goods = []
         params.forEach((item) => {
             item.order_goods.forEach((gl) => {
                 gl.mch = item.mch
                 goods.push(gl)
             })
        })
        setGoodsData(goods)
    }

    /**
     * 更新用户资料
     */
    const onUpdate = () => {
        setSpin(true)
        put('admin/shop/order/' + id, {remark})
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('备注提交成功！');
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 商品状态
     * @returns {{}}
     */
    const statusText = () => {
        let content = {};
        switch (data.status) {
            case 1:
                content.color = 'cyan';
                content.text = '待确认';
                break;
            case 2:
                content.color = 'lime';
                content.text = '已完成';
                break;
            default:
                content.color = 'gold';
                content.text = '待付款';
        }
        return content;
    };


    /**
     * 商品类型
     * @param type
     * @returns {string}
     */
    const typeText = (type) => {
        let content = '实体商品';
        switch (type) {
            case 2:
                content = '虚拟商品';
                break;
            case 3:
                content = '外卖订餐';
                break;
            case 4:
                content = '酒店客房';
                break;
            case 5:
                content = '售票卡券';
                break;
            default:
                content = '实体商品';
        }
        return content;
    };

const printOrder = (id) => {
  //  const el = document.getElementById(id);
    const iframe = document.createElement('IFRAME');
    let doc = null;

    iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
    document.body.appendChild(iframe);
    doc = iframe.contentWindow.document;
    // 引入打印的专有CSS样式，根据实际修改
    doc.write('<LINK rel="stylesheet" href="https://tbbdev.effects.cn/css/print.css">');
    //el.innerHTML
    doc.write('打印模式待确定！！');
    doc.close();
    // 获取iframe的焦点，从iframe开始打印
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    setTimeout(() => {
        document.body.removeChild(iframe);
    }, 500);
}


    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>用户订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>订单详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>用户订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>订单详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4' style={{height: 520}}>
                    <Skeleton className='mt-5'/>
                    <Skeleton className='mt-5'/>
                    <Skeleton />
                </div>
            </div>
        )
    }


    const columns = [
        {
            title: '商品',
            dataIndex: 'title',
            render: (title, item) => {
                return (
                    <Link to={{pathname: '/order/goods/' + item.id}}>
                        <Space>
                            <Thumb
                                width={60}
                                height={60}
                                src={item.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                            />
                            <div>
                                <Text>{title}</Text>
                                <div className='text-muted mt-1 fs-7'>规格：默认</div>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '支付价格',
            dataIndex: 'real_price',
        },
        {
            title: '数量',
            dataIndex: 'total',
            render: (total) => {
                return (
                   <Text> x{total}</Text>
                )
            }
        },
        {
            title: '商户',
            dataIndex: 'mch',
            render: (mch) => {
                return (
                    <Text>{mch.name}</Text>
                )
            }
        },
        {
            title: '商品属性',
            dataIndex: 'goods_type',
            render: (type, item) => {
                return (
                    <div>
                      <Tag className='mb-1' >{typeText(type)}</Tag>
                        {item.is_verify &&  <Tag className='mb-1' >线下核销</Tag>}
                        {item.is_points &&  <Tag className='mb-1' >积分兑换</Tag>}
                        {item.is_level &&  <Tag className='mb-1' >会员商品</Tag>}
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status, item) => {
                const style = statusText(status);
                return (
                    <div>
                        {item.refund === 0 && <Tag className='mb-1' color={style.color}>{style.text}</Tag>}
                        {item.refund !== 0 &&  <Tag  color='#f50' className='mb-1' >{item.refund === 4 ? '已退款' :'退款中'}</Tag>}
                    </div>
                )
            }
        },
    ];


    return (
        <div className='mx-3'>
            <Spin size="large" spinning={spin}>
                <div className='my-4' >
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>用户订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>订单详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className='bg-white' id='order' ref={printRef}>
                        <PageHeader
                            ghost={false}
                            title={"订单号: #"+ data.order_sn}
                            extra={[
                                <Button key="2" onClick={()=>printOrder('order')}>打印订单</Button>,
                                <Button key="1" type="primary"  onClick={() => navigate(-1)}>返回列表</Button>,
                            ]}
                        >
                            <div className='mt-3'>
                            <Row>
                                <Col span={18}>
                                    <Descriptions  column={2}>
                                        <Descriptions.Item label="下单用户">
                                            <Space>
                                                <Avatar src={data.user.avatar} size={20} />
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="联系方式">{data.user.mobile}</Descriptions.Item>
                                        <Descriptions.Item label="下单日期">{data.created_at}</Descriptions.Item>
                                        <Descriptions.Item label="收货地址">未录入任何地址</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col flex={1}>
                                    <Statistic title="支付状态" value={data.status === 1 ?'已付款' : '未付款'} valueStyle={{ color: data.status === 1 ?'#52c41a' : '#ffc107' }}  />
                                </Col>
                                    <Col>
                                    <Statistic
                                        title="订单金额"
                                        prefix="¥"
                                        value={data.real_price}
                                        style={{
                                            margin: '0 32px',
                                        }}
                                    />
                                    </Col>
                            </Row>
                        </div>
                        </PageHeader>

                        <Divider style={{ marginBottom: 32 }} />
                      <div className='px-4 pb-4'>
                        <Descriptions title="订单详情">
                            <Descriptions.Item label="支付方式">
                                {data.pay_type === 2 && <Space>
                                    <img
                                        style={{width: 22, height: 22}}
                                        src={Icon.wechatPay}
                                        alt={'微信支付'}/>
                                    <Text>微信支付</Text>
                                </Space>
                                }
                                {data.pay_type === 1 && <Space>
                                    <img
                                        style={{width: 22, height: 22}}
                                        src={Icon.alipay}
                                        alt={'支付宝'}/>
                                    <Text>支付宝</Text>
                                </Space>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="选择优惠" span={2}>当前未选择任何优惠</Descriptions.Item>
                            <Descriptions.Item label="实际价格">¥{data.price}</Descriptions.Item>
                            <Descriptions.Item label="支付价格">¥{data.real_price}</Descriptions.Item>
                            <Descriptions.Item label="优惠价格">¥{data.coupon_price}</Descriptions.Item>

                        </Descriptions>

                          <div className='mt-3'>
                              <Table  rowKey={(record) => {
                                  return record.id
                              }} pagination={false} dataSource={goods} columns={columns} />

                          </div>
                          <div className="d-flex justify-content-between mt-4">
                              <div>
                                  <div className="text-muted mb-2"><ExclamationCircleOutlined /> 结算方式按照商品优惠，商家优惠及平台优惠进行结算，如有任何疑问请联系负责人。</div>
                                  <Input.TextArea
                                      maxLength={200}
                                      showCount
                                      value={remark}
                                      onChange={(e)=> setRemark(e.target.value)}
                                      placeholder="输入订单备注信息"
                                      autoSize={{ minRows: 2, maxRows: 6 }}
                                  />
                                  <div className="mt-2"> <Button type="dashed"  onClick={onUpdate}>保存</Button></div>

                              </div>
                              <div className="text-end">
                                  <div className="border-bottom text-muted">
                                      <p className="mb-2">
                                      <span>商品原价 </span><span>¥{data.price}</span></p>
                                      <p className="mb-2">
                                          <span>商品数 </span><span>{goods.length} 件</span></p>
                                  </div>
                                  <h2 className='mt-3'><span className="me-1">合计: </span><span>¥{data.real_price}</span></h2>
                              </div>
                          </div>

                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default OrderUserDetails
