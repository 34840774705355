import React, {useState, useEffect, useCallback} from 'react'

import {
    Button,
    Col,
    Divider,
    List,
    Row,
    message,
    Pagination, Input,Card, Modal, Space, Breadcrumb
} from 'antd';
import {DeleteOutlined, PlusOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom'
import  moment  from 'moment';
import {useHttps} from "../../hooks";
import {Thumb} from "../../components";

const Page = (props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [total , setTotal] = useState(0)
    const [current , setCurrent] = useState(1)
    const [perPage , setPerPage] = useState(24)
    const [search, setSearch] = useState('')
    const {get, destroy} = useHttps();
    const navigate = useNavigate();

    /**
     * 数据请求
     * @param {Object} params
     */
    const getData  = useCallback((params) =>{
        get('admin/page', params , true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get])


    useEffect(() => {
        const  pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({ page:pageNumber, per_page:24});
    }, [getData]);

    /**
     * 删除列表操作
     * @param  id  页面id
     */
    const onDelete = (id) => {
        setLoading(true);
        destroy('admin/page/' + id)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    };


    /**
     * 翻页操作触发
     * @param pagination
     * @param perPage
     */

    const onPaginationChange = (pagination , perPage) =>{
        setLoading(true);
        const params = {
            page: pagination,
            per_page: perPage,
        }
        if (search.length !== 0) {
            params.search = search;
        }
        window.location.hash = `#${pagination}`;
        setPerPage(perPage)
        getData(params);
    }

    /**
     * 操作提示信息
     */
    const showConfirm = (id) => {
        Modal.confirm({
            centered:true,
            icon: <ExclamationCircleOutlined />,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(id)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }


        return (
                <div className='mx-3'>
                    <div className='my-4'>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Separator>:</Breadcrumb.Separator>
                            <Breadcrumb.Item  className='text-primary' >页面管理</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='bg-white p-4'>
                        <Row>
                            <Col span={12}>
                                <Button
                                    onClick={()=> navigate('/page/create')}
                                    type="primary"
                                    icon={<PlusOutlined/>}
                                >
                                    添加页面
                                </Button>
                            </Col>
                            <Col span={12}>
                                <div className='d-flex justify-content-end'>
                                <Space>
                                    <Input.Search
                                        style={{ width: 400 }}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                        onSearch={(e) => {
                                            if (e.length !== 0) {
                                                onPaginationChange(1,  perPage)
                                            }
                                        }}
                                        placeholder="输入关键词搜索"
                                        allowClear
                                        enterButton="搜索"
                                    />

                                    <Button onClick={() => onPaginationChange( 1, perPage)}>
                                        重置
                                    </Button>
                                </Space>
                                </div>
                            </Col>
                        </Row>

                        <Divider/>
                        <div className='mt-4'>
                            <List
                                loading={loading}
                                grid={{ gutter: 16, column: 5 }}
                                dataSource={data}
                                renderItem={item => (
                                    <List.Item >
                                        <Card
                                            actions={[
                                                <EditOutlined key="edit" onClick={()=> navigate('/page/' + item.id)} />,
                                                <DeleteOutlined key="ellipsis" onClick={()=>showConfirm(item.id)}/>,
                                            ]}
                                            cover={
                                                <Thumb onClick={()=> navigate('/page/' + item.id) }
                                                       rounded='rounded-0'
                                                       src={item.thumb + "?x-oss-process=image/resize,w_500,h_300,m_fill,limit_0"}
                                                />
                                            }
                                        >
                                            <Card.Meta title={item.title} description={'发布于' +moment(item.created_at).format('YYYY年MMMD日')} />
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </div>
                        <div className='d-flex justify-content-end mt-3'>
                            <Pagination defaultCurrent={1} total={total} current={current} pageSize={perPage}
                                        onChange={onPaginationChange}/>
                        </div>
                    </div>
                </div>
        )

}
export default Page;
