/**
 * Notes: 商户列表
 * User: 李强
 * DateTime: 2021/12/13 12:51
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useCallback} from 'react'

import {
    Button,
    Col,
    Divider,
    Input,
    Row,
    Typography,
    Breadcrumb,
    Space, Select, Table, Avatar, DatePicker, message, Badge
} from 'antd';
import {VerticalAlignBottomOutlined} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom'
import {useHttps} from '../../hooks'
import {subText} from "../../utils/commonfn";

const {Text} = Typography;
/*
  用户管理
*/
const OrderWithdrawLog = (props) => {

    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(3)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    const [selectDate, setSelectDate] = useState(null)
    const navigate = useNavigate();
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params) => {
        get('admin/shop/order/withdraw/log', params, true)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }, [get])


    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getData({page: pageNumber, per_page: 15});
    }, [getData]);



    const columns = [
        {
            title: '商户',
            dataIndex: 'mch',
            render: (mch, item) => {
                return (
                    <Link to={'/order/log/' + item.id}>
                        <Space>
                            <Avatar shape="square" size={44}  src={mch.logo} alt={mch.name} />
                            <Text>{subText(mch.name , 14)}</Text>
                        </Space>
                    </Link>
                )
            }
        },
        {
            title: '提现金额',
            dataIndex: 'withdraw_price',
            render: (price) => {
                return (
                    <div>
                        <Text>¥{price}</Text>
                    </div>

                )
            }
        },
        {
            title: '提现银行',
            dataIndex: 'bank',
            render: (bank) => {
                return (
                    <div>
                        {bank ? bank : '暂未录入'}
                    </div>
                )
            }
        },
        {
            title: '操作员',
            dataIndex: 'user',
            render: (user) => {
                return (
                        <Space>
                            <Avatar size={24}  src={user.avatar} alt={user.nickname} />
                            <Text>{user.nickname}</Text>
                        </Space>
                )
            }
        },
        {
            title: '提现日期',
            dataIndex: 'created_at',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status , item) => {
                //state
                return (
                    <div>
                        {status === 2 && <Badge count={'已到账'} style={{backgroundColor: '#52c41a'}}/> }
                        {status === 1 &&   <Badge  count={'转账中'} style={{backgroundColor: '#fa8c16'}}/> }
                        {status === 0 &&   <Badge  count={'待处理'} /> }
                    </div>
                )
            }
        },

        {
            title: '操作',
            dataIndex: 'action',
            render: (key, item) => {
                return (

                    <Button onClick={() => navigate('/order/log/' + item.id)} className='text-muted' type="dashed" size='small'> 查看详情 </Button>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }

        if (selectDate) {
            const startDate = selectDate[0].format('YYYY-MM-DD');
            const endDate = selectDate[1].format('YYYY-MM-DD');
            params['between[0]'] = startDate
            params['between[1]'] = endDate
        }
        if (status !== 3) {
            params.status = status;
        }
        if (search.length !== 0) {
            params.search = search;
        }
        window.location.hash = `#${pagination.current}`;
        getData(params);
    }

    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setStatus(2)
        setSearch('')
        window.location.hash = '#1';
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }


    return (
        <div className='mx-3'>
            <div className='my-4'>
                <Breadcrumb separator="">
                    <Breadcrumb.Item className='text-dark'> <Divider type='vertical' className='bg-primary' style={{
                        width: 5,
                        height: 14
                    }}/>当前位置</Breadcrumb.Item>
                    <Breadcrumb.Separator>:</Breadcrumb.Separator>
                    <Breadcrumb.Item className='text-dark'>订单</Breadcrumb.Item>
                    <Breadcrumb.Separator/>
                    <Breadcrumb.Item className='text-primary'>订单提现</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            <div className='bg-white p-4'>
                <Row justify="space-between">
                    <Col>
                        <Button
                            type="primary"
                            icon={<VerticalAlignBottomOutlined/>}
                        >
                            导出提现记录
                        </Button>
                    </Col>
                    <Col>
                        <Space>
                            <DatePicker.RangePicker value={selectDate} onChange={setSelectDate}/>
                            <Select defaultValue={2} value={status} onChange={setStatus}>
                                <Select.Option value={3}>提现状态</Select.Option>
                                <Select.Option value={0}>待处理</Select.Option>
                                <Select.Option value={1}>转账中</Select.Option>
                                <Select.Option value={2}>已到账</Select.Option>
                            </Select>
                            <Input.Search
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onSearch={(e) => {
                                    onPaginationChange({current: 1, pageSize: perPage})
                                }}
                                placeholder="输入关键词搜索"
                                allowClear
                                enterButton="筛选"
                            />
                            <Button onClick={onReset}>
                                重置
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 家提现`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderWithdrawLog
