/**
 * Notes: 认证编辑
 * User: 李强
 * DateTime: 2021/12/12 00:38
 * Ide: PhpStorm
 */

import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
    Button,
    Divider,
    Spin,
    Row,
    Col,
    Avatar,
    Space, Empty, Skeleton, Typography, message, Breadcrumb, Tag, Table, Modal
} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useHttps} from "../../hooks";
import {Icon} from "../../Config";
import {subText, withdrawPrice} from "../../utils/commonfn";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Thumb} from "../../components";


const {Title, Text} = Typography;



const OrderWithdrawDetails = (props) => {
    const {post, get} = useHttps();
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [spin, setSpin] = useState(false);
    const printRef = useRef() ;
    const [goods, setGoods] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [goodsLoading, setGoodsLoading] = useState(true)
    const navigate = useNavigate();
    useEffect(() => {
        get('admin/shop/order/withdraw/' + id, null,  true)
            .then((res) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setErrorText(e.message);
                    setError(true);
                    setLoading(false);
                }
            });
    }, [get, id]);


    /**
     * 数据请求
     * @param {Object} params
     */
    const getGoodsData = useCallback((params) => {
        get('admin/shop/order/withdraw/items/' + id, params , true)
            .then((res) => {
                if (res.mounted) {
                    setGoodsLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setGoods(res.data);
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    },[get, id])



    useEffect(() => {
        const pageNumber = parseInt(window.location.hash.slice(1), 0) || 1
        getGoodsData({
            page: pageNumber,
            per_page: 15,
        })
    }, [getGoodsData, id]);



    const typeText = () => {
        let content = {};
        switch (data.type) {
            case 1:
                content.color = 'magenta';
                content.text = '企业公司';
                break;
            case 2:
                content.color = 'lime';
                content.text = '政府机关';
                break;
            case 3:
                content.color = 'purple';
                content.text = '事业单位';
                break;
            default:
                content.color = 'geekblue';
                content.text = '个体商户';
        }
        return content;
    };
    /**
     * 更新用户资料
     */
    const onUpdate = () => {
        setSpin(true)
       const price = withdrawPrice(data.withdraw_price , data.transfer_rate);
        post('admin/shop/order/withdraw', {withdraw_price:price,mch_id:id})
            .then((res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('提现成功！请核实入账。');
                    navigate(-1)
                }
            })
            .catch((e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                    navigate(-1)
                }
            });
    }

    /**
     * 表格操作触发
     * @param pagination
     * @param filters
     * @param sorter
     */
    const onPaginationChange = (pagination) => {
        setGoodsLoading(true);
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            mch_id:id,
            withdraw_id:0,
            refund:0,
            status:2
        }
        window.location.hash = `#${pagination.current}`;
        getGoodsData(params);
    }



    /**
     * 商品类型
     * @param type
     * @returns {string}
     */
    const goodsTypeText = (type) => {
        let content = '实体商品';
        switch (type) {
            case 2:
                content = '虚拟商品';
                break;
            case 3:
                content = '外卖订餐';
                break;
            case 4:
                content = '酒店客房';
                break;
            case 5:
                content = '售票卡券';
                break;
            default:
                content = '实体商品';
        }
        return content;
    };

    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            title:'提现额度：¥' + withdrawPrice(data.withdraw_price , data.transfer_rate),
            content:(<>确定已认证核实过订单？提现之后无法撤回操作！</>),
            onOk() {
                onUpdate()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    const columns = [
        {
            title: '订单商品',
            dataIndex: 'title',
            render: (title, item) => {
                return (
                    <Link to={{pathname: '/order/user/' + item.id}}>
                        <Space>
                            <Thumb
                                width={52}
                                height={52}
                                src={item.thumb + "?x-oss-process=image/resize,w_80,h_80,m_fill,limit_0"}
                            />
                            <div>
                                <Text>{subText(title , 20)}</Text>
                                <div className='text-muted mt-1 fs-7'>规格：默认</div>
                            </div>
                        </Space>
                    </Link>
                )
            }
        },

        {
            title: '支付价格',
            dataIndex: 'real_price',
        },
        {
            title: '数量',
            dataIndex: 'total',
            render: (total) => {
                return (
                    <Text> x{total}</Text>
                )
            }
        },
        {
            title: '商品属性',
            dataIndex: 'goods_type',
            render: (type, item) => {
                return (
                    <div>
                        <Tag className='mb-1' >{goodsTypeText(type)}</Tag>
                        {item.is_verify &&  <Tag className='mb-1' >线下核销</Tag>}
                        {item.is_points &&  <Tag className='mb-1' >积分兑换</Tag>}
                        {item.is_level &&  <Tag className='mb-1' >会员商品</Tag>}
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (status, item) => {
                return (
                    <div>
                        {item.status === 2 && <Tag className='mb-1'  color='#20c997' >已完成</Tag>}
                        {item.status !== 2 &&  <Tag  color='#dc3545' >订单异常</Tag>}
                    </div>
                )
            }
        },
    ];
    if (error) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>商家提现</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>提现详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white p-4'>
                    <div>
                        <Button className='me-2' onClick={() => navigate(-1)} type="primary">
                            返回列表
                        </Button>
                    </div>
                    <Divider/>
                    <div className='p-5'>
                        <Empty description={errorText} imageStyle={{height: 160}}/>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 内容加载
     */
    if (loading) {
        return (
            <div className='mx-3'>
                <div className='my-4'>
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>商家提现</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>提现详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='bg-white px-4' style={{height: 520}}>
                    <Skeleton className='mt-5'/>
                    <Skeleton className='mt-5'/>
                    <Skeleton />
                </div>
            </div>
        )
    }




    return (
        <div className='mx-3'>
            <Spin size="large" spinning={spin}>
                <div className='my-4' >
                    <Breadcrumb separator="">
                        <Breadcrumb.Item className='text-dark' > <Divider type='vertical' className='bg-primary' style={{width: 5, height: 14}}/>当前位置</Breadcrumb.Item>
                        <Breadcrumb.Separator>:</Breadcrumb.Separator>
                        <Breadcrumb.Item  className='text-dark' >订单</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item onClick={() => navigate(-1)}  className='text-dark'>商家提现</Breadcrumb.Item>
                        <Breadcrumb.Separator />
                        <Breadcrumb.Item  className='text-primary'>提现详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className='bg-white py-4' id='order' ref={printRef}>
                            <div className='px-4'>
                                <Row justify="space-between">
                                    <Col>
                                        <Space>
                                            <Avatar shape="square" size={64}  src={data.logo} alt={data.name} />
                                            <div>
                                                <Space align="center" size={20}>
                                                    <Title className='mb-0' level={5}>{data.name}</Title>
                                                    <img  style={{height: 22}} src={data.status === 2 ? Icon.auth : Icon.authNot}
                                                          alt={data.name}/>
                                                    <div>
                                                        <Tag color={typeText().color}>{typeText().text}</Tag>
                                                    </div>
                                                    <div>
                                                        {data.category.map((tag) => {
                                                            return (
                                                                <Tag color="blue" key={tag.id}>{tag.name}</Tag>
                                                            );
                                                        })}
                                                    </div>
                                                    <div>
                                                        {data.tags.map((tag) => {
                                                            return (
                                                                <Tag key={tag.id}>{tag.name}</Tag>
                                                            );
                                                        })}
                                                    </div>
                                                </Space>
                                                <div className='mt-2'>
                                                    <Space align="center" size={30}>
                                                        <Text className='mb-0  text-muted'>地址:{data.address}</Text>
                                                        <Text className='mb-0  text-muted'>联系电话:{data.tel}</Text>
                                                        <Text className='mb-0  text-muted'>注册日期:{data.created_at}</Text>
                                                    </Space>
                                                </div>
                                            </div>
                                        </Space>
                                    </Col>
                                    <Col>
                                        <Space align="center">
                                            <Button className='me-2' type="primary"  onClick={() => navigate(-1)}>
                                                返回列表
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                        </div>


                    <Divider />

                    <div className='px-4'>
                        <div className='mb-4'>
                            <Row>
                                <Col span={10}>
                            <Title level={5} >可提现额度</Title>

                                <Space align="baseline" size='large'>
                                    <div>提现额度：¥{data.withdraw_price}</div>
                                <Title className='mb-0' level={3} type="success" >¥{withdrawPrice(data.withdraw_price , data.transfer_rate)}</Title>
                                    <Button size='small' disabled={parseFloat(data.withdraw_price) === 0} className='me-2'  onClick={showConfirm}>
                                        提现
                                    </Button>
                                </Space>
                                <div className='fs-7 text-muted mt-3'> 最终可提现额度 = 提现额度 - (提现额度 x 手续费) </div>
                                </Col>
                                <Col>
                                        <div className='mb-3'>开户银行：{data.bank ? data.bank : '未录入信息'}</div>
                                        <div  className='mb-3'>开户行账号：{data.bank_id ? data.bank_id : '未录入信息'}</div>
                                        <div>商家手续费：{data.transfer_rate}%</div>
                                </Col>
                            </Row>
                        </div>
                          <div>
                              <Table
                                  size="middle"
                                  loading={goodsLoading}
                                  columns={columns}
                                  dataSource={goods}
                                  pagination={{
                                      size:'default',
                                      total: total,
                                      current: current,
                                      showTotal: total => `总计 ${total} 份提现订单`,
                                      pageSize: perPage,
                                  }}
                                  onChange={onPaginationChange}
                                  rowKey={(record) => {
                                      return record.id
                                  }}
                              />
                          </div>
                    </div>



                </div>
            </Spin>
        </div>
    )
}

export default OrderWithdrawDetails
